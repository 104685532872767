import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { generateRandomNumbersForStockLineGraph } from "@/utils/generalUtils";

const ChartWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

const StockLineGraph = ({ fundName }: { fundName: string }) => {
  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: fundName,
        data: generateRandomNumbersForStockLineGraph(),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  return (
    <Grid xs={8} md={6} lg={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" mb={2}>
          Fund Performance
        </Typography>
        <ChartWrapper>
          <Line
            data={data}
            options={{
              scales: {
                y: {
                  ticks: {
                    stepSize: 2,
                    callback: (value, index, values) => value + "%",
                  },
                  beginAtZero: true,
                },
              },
            }}
          />
        </ChartWrapper>
      </div>
    </Grid>
  );
};

export default StockLineGraph;
