import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function Logo({
  disabledLink = false,
  sx,
  white = true,
}: {
  disabledLink?: boolean;
  sx?: any;
  white?: boolean;
}) {
  const logo = (
    <Box
      component="img"
      sx={{
        ml: 2,
        mt: 1,
        height: 45,
        ...sx,
      }}
      src={`/static/logo${white ? "_white" : "_blue"}.png`}
      alt="Metrics_Logo"
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  //return <RouterLink to="/portal/landing">{logo}</RouterLink>;
  return <div>{logo}</div>;
}
