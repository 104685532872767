import { Model } from "@/types/GeneralTypes";
import { Box, Modal, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ExistingFileUploadWarningModalProps {
  open: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  model: Model;
}

const ExistingFileUploadWarningModal = ({
  open,
  setModalOpen,
  model,
}: ExistingFileUploadWarningModalProps) => {
  const fileType =
    model === Model.MEMORANDUMS
      ? "memorandum"
      : model === Model.REPORTS
      ? "report"
      : "file";
  const fileTarget =
    model === Model.MEMORANDUMS
      ? "fund"
      : model === Model.REPORTS
      ? "account"
      : "model";

  return (
    <Modal open={open} onClose={() => setModalOpen(false)}>
      <Box sx={ModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 20,
            }}
          >
            <span>
              <WarningAmberIcon color={"warning"} />
            </span>
            <span>
              {`A ${fileType} has already been uploaded for this ${fileTarget}`}
            </span>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ExistingFileUploadWarningModal;
