import { styled } from "@mui/material";

type TagStyleProps = {
  newlyAdded?: boolean;
  newlyRemoved?: boolean;
};

interface TagProps {
  children: React.ReactNode;
  newlyAdded?: boolean;
  newlyRemoved?: boolean;
}

const Tag = ({ children, newlyAdded, newlyRemoved }: TagProps) => {
  const TagWrapper = styled("div")(
    ({ newlyAdded, newlyRemoved }: TagStyleProps) => ({
      display: "inline-block",
      padding: "4px 8px",
      margin: "4px",
      fontSize: 14,
      fontWeight: "bold",
      color: newlyAdded ? "#c41d7f" : "#0958d9",
      background: newlyAdded ? "#fff0f6" : "#e6f4ff",
      borderColor: newlyAdded ? "#ffadd2" : "#91caff",
      borderRadius: 4,
      borderStyle: "solid",
      borderWidth: 2,
      textDecoration: newlyRemoved ? "line-through" : "none",
      opacity: newlyRemoved ? 0.4 : 1.0,
    })
  );
  return (
    <TagWrapper newlyAdded={newlyAdded} newlyRemoved={newlyRemoved}>
      {children}
    </TagWrapper>
  );
};

export default Tag;
