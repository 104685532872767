import { apiGetRequest, apiGetRequestTest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";


export interface Memorandum {
  file_key: string;
  fund_id: string;
  name: string;
  ticker: string;
}

export interface PaginationMemorandum {
  file_name: string;
  upload_date: string;
  uploader: string;
}

interface useFundsWithMemorandumsProps {
  currentAccount: string | null;
}

export const useFundsWithMemorandumsQuery = ({
  currentAccount,
}: useFundsWithMemorandumsProps) => {
  return useQuery({
    queryKey: ["memorandums", currentAccount],
    queryFn: async () =>
      await apiGetRequest<Memorandum[]>({
        query: `newMemorandums?accountId=${currentAccount}`,
      }),
    retry: false,
  });
};

interface useFundsWithViewableMemorandumsByUserIdQueryProps {
  userId: string | null
}

interface useFundsWithMemorandumsByUserIdQueryProps {
  userId: string | null
  enabled: boolean
}

export const useFundsWithViewableMemorandumsByUserIdQuery = ({
  userId,
}: useFundsWithViewableMemorandumsByUserIdQueryProps) => {
  return useQuery({
    queryKey: ["memorandumsByUserId", userId],
    queryFn: async () =>
      await apiGetRequest<Memorandum[]>({
        query: `newMemorandums?userId=${userId}&viewableMemorandums=true`,
      }),
    retry: false,
    enabled: userId ? true : false
  });
};

export const useFundsWithMemorandumsByUserIdQuery = ({
  userId,
  enabled
}: useFundsWithMemorandumsByUserIdQueryProps) => {
  return useQuery({
    queryKey: ["memorandumsByUserId", userId],
    queryFn: async () =>
      await apiGetRequest<Memorandum[]>({
        query: `newMemorandums?userId=${userId}`,
      }),
    retry: false,
    enabled: userId && enabled ? true : false
  });
};




interface useMemorandumUrlQueryProps {
  currentAccount: string | null;
  fundId: string | null;
}

export const useMemorandumUrlQuery = ({
  currentAccount,
  fundId,
}: useMemorandumUrlQueryProps) =>
  useQuery({
    queryKey: ["memorandum", currentAccount, fundId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newMemorandums?accountId=${currentAccount}&fundId=${fundId}`,
      }),
    retry: false,
    enabled: currentAccount && fundId ? true : false,
  });

interface useInvestorMemorandumUrlQueryProps {
  fundId: string | null;
  userId: string | null
}

export const useInvestorMemorandumUrlQueryByUserId = ({
  fundId, userId
}: useInvestorMemorandumUrlQueryProps) =>
  useQuery({
    queryKey: ["memorandum", fundId, userId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newMemorandums?fundId=${fundId}&userId=${userId}`,
      }),
    retry: false,
    enabled: (fundId && userId) ? true : false,
  });

interface useUserCanViewInvestorMemorandumsQueryProps {
  userId: string | null;
}

export const useUserCanViewInvestorMemorandumsQuery = ({
  userId,
}: useUserCanViewInvestorMemorandumsQueryProps) =>
  useQuery({
    queryKey: ["canViewMemorandum", userId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newMemorandums?userId=${userId}&canViewMemorandums=true`,
      }),
    retry: false,
    enabled: userId ? true : false,
  });


interface useMemorandumUrlAdminQueryProps {
  fundId: string | null;
  enabled: boolean;
}

export const useMemorandumUrlAdminQuery = ({
  fundId,
  enabled
}: useMemorandumUrlAdminQueryProps) =>
  useQuery({
    queryKey: ["memorandum", fundId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newMemorandums?fundId=${fundId}`,
      }),
    retry: false,
    enabled: enabled && fundId ? true : false,
  });


interface useInvestorMemorandumUrlQueryProps {
  fundId: string | null;
  userId: string | null
}

interface usePaginationMemorandumsQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}


interface PaginationMemorandumsData {
  fund_id: string;
  fund_name: string;
  file_name: string;
  upload_date: string;
  uploader: string;
  memorandums: PaginationMemorandum[]
}

interface PaginationMemorandumsReturn {
  count: number;
  data: PaginationMemorandumsData[]
}

export const usePaginationMemorandumsQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationMemorandumsQueryProps) =>
  useQuery({
    queryKey: ["memorandumsByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationMemorandumsReturn>({
        query: `memorandums?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });

interface useUploadInvestorMemorandumProps {
  fileKey: string;
  fileData: FormData;
  fundId: string;
}

export const useUploadInvestorMemorandum = () => {
  return useMutation({
    mutationFn: async ({ fileKey, fileData, fundId }: useUploadInvestorMemorandumProps) => {
      try {
        const uploadUrl = await apiGetRequest<string>({
          query: `newMemorandums?pdfToUpload=${fileKey}`,
        });
        if (uploadUrl) {
          try {
            console.log('fileData', fileData, fileData.get("file"), uploadUrl)
            const response = await fetch(uploadUrl, {
              method: "PUT",
              body: fileData.get("file"),
            });
            if (!response.ok) {
              throw new Error("File upload failed");
            } else {
              try {
                await apiPostRequest<string>({
                  query: `newMemorandums?fileKey=${fileKey}&fundId=${fundId}`,
                });
              } catch (error) {
                console.log(error)
                throw new Error("Error adding memo to db");
              }
            }
          } catch (error) {
            console.log(error)
            throw new Error("File upload failed");
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        } else {
          console.log(error)
          throw new Error("Error fetching upload url")
        }
      }
    },
    onSuccess: () => {
      toast.success("Memorandum uploaded")
    }
  });
};