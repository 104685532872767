import { apiGetRequest, apiGetRequestTest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export interface Fund {
  id: string;
  name: string;
  ticker: string;
}

interface useFundsQuery {
  enabled?: boolean
}

export const useFundsQuery = ({ enabled = true }: useFundsQuery) =>
  useQuery({
    queryKey: ["funds"],
    queryFn: async () =>
      await apiGetRequest<Fund[]>({
        query: `newFunds?getAll=true`,
      }),
    enabled,
    retry: false,
  });

interface usePaginationFundsQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}

interface PaginationFundsReturn {
  count: number;
  data: Fund[]
}

export const usePaginationFundsQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationFundsQueryProps) =>
  useQuery({
    queryKey: ["fundsByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationFundsReturn>({
        query: `funds?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });

//POST

interface useAddNewFundProps {
  newFundName: string;
  newFundTicker: string;
}

export const useAddNewFund = () =>
  useMutation({
    mutationFn: ({ newFundName, newFundTicker }: useAddNewFundProps) => {
      return apiPostRequest<string>({
        query: `newFunds?newFundName=${newFundName}&newFundTicker=${newFundTicker}`,
      });
    },
    onSuccess: () => {
      toast.success("New fund added");
    },
  });
