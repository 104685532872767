import Router from "./routes/routes";
import ThemeProvider from "./theme";
import { Amplify } from "aws-amplify";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "IPAPI",
        endpoint:
          "https://ytjzgo6tel.execute-api.ap-southeast-2.amazonaws.com/beta/InvestorPortal/Users",
      },
    ],
  },
};
Amplify.configure(config);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.log("query error");
      toast.error(`Something went wrong: ${error.message}`);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log("mutation error", error);
      toast.error(`Something went wrong: ${error.message}`);
    },
  }),
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
        <ThemeProvider>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="colored"
          />
          <Router />
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
