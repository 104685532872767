import { Typography, useTheme } from "@mui/material";
import Page from "../../../components/Page";
import FilterWholesaleReports from "../components/FilterWholesaleReports/FilterWholesaleReports";
import {
  useViewableFundReportsByUserAccountId,
  useViewableReportsByUserAndAccount,
} from "@/hooks/useReports";
import Spinner from "@/components/Spinner";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useParams } from "react-router";
import { useFundsQuery } from "@/hooks/useFunds";
import FilterFundReports from "../components/FilterFundReports/FilterFundReports";

const FundReports = () => {
  const user = useCurrentUser();
  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || "";

  const userAccountId =
    user?.roles.find((role) => role.account_id === currentAccount)
      ?.user_account_id || null;
  const { isFetching: fetchingFunds, data: funds } =
    useViewableFundReportsByUserAccountId({
      userAccountId,
    });

  return (
    <Page title="Reports">
      <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
        Fund Reports
      </Typography>
      {fetchingFunds ? <Spinner loading={fetchingFunds} /> : null}
      {funds ? (
        <FilterFundReports
          key={currentAccount}
          currentAccount={currentAccount}
          funds={funds}
        />
      ) : null}
    </Page>
  );
};

export default FundReports;
