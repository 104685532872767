import * as Yup from "yup";
import { Field, FieldArray, Formik, FormikProps } from "formik";
// Local imports
import { formatDate } from "../../../utils/DateUtils";
// Components
import FormInput from "../../../components/FormInput";
// Store
import { formatListAsString } from "../../../utils/generalUtils";
import Spinner from "../../../components/Spinner";
import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { InputTypes } from "../../../types/InputTypes";
import { DeleteOutline } from "@mui/icons-material";
import { RoleCounts } from "../../user_account/types";
import { Account } from "@/hooks/useAccounts";
import { useQueryClient } from "@tanstack/react-query";
import { NewUserAccounts } from "@/hooks/useUsers";
import { useEffect } from "react";
import { useSignupCognitoAndAddToDb } from "@/hooks/useAuth";
import { Role } from "@/hooks/useRoles";
import { Fund } from "@/hooks/useFunds";
import { useParams } from "react-router";
import { toast } from "react-toastify";

// Validation Schema
const UserSchema = Yup.object().shape({
  givenName: Yup.string().required("Given Name is required"),
  familyName: Yup.string().required("Family Name is required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  birthdate: Yup.date()
    .typeError("Valid date required.")
    .required("Birth date is required"),
  role: Yup.string().required("User Role is required."),
  // user_accounts: Yup.array().of(
  //   Yup.object().shape({
  //     account: Yup.string().required("Required"), // these constraints take precedence
  //   })
  // ),
  //  .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
  //  .min(3, 'Minimum of 3 friends'),
});

interface SignupFormProps {
  accounts: Account[];
  roles: Role[];
  initialSelected?: string | null;
  handleClose?: any;
  roleCounts?: RoleCounts;
  funds?: Fund[];
  fromAdmin?: boolean;
}

// Creates User Form
const SignupForm = ({
  accounts,
  roles,
  initialSelected,
  handleClose,
  funds,
  fromAdmin,
}: SignupFormProps) => {
  const queryClient = useQueryClient();

  const addUser = useSignupCognitoAndAddToDb();
  const { isPending: addingUser, isSuccess: userAdded } = addUser;

  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || null;

  const refetchUsers = () => {
    queryClient.invalidateQueries({
      queryKey: ["numberUsers"],
    });
    queryClient.invalidateQueries({
      queryKey: ["usersByPage"],
    });
  };

  useEffect(() => {
    if (userAdded) {
      handleClose();
      refetchUsers();

      toast.success("User added");
    }
  }, [userAdded]);

  const formattedAccounts =
    accounts &&
    accounts.map((account) => ({
      label: `${account.name}`,
      value: account.id,
      disabled: false,
    }));
  const formattedRoles =
    roles &&
    roles.map((role) => ({
      label: `${role.name}`,
      id: role.id,
      checked: false,
      reports: role.viewable_reports,
    }));
  const initialValues = {
    givenName: "",
    familyName: "",
    email: "",
    birthdate: new Date(),
    role: "user",
    user_accounts: initialSelected
      ? [
          {
            account: initialSelected,
            roles: "",
          },
        ]
      : [],
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={UserSchema}
        onSubmit={async (values: any) => {
          const newUserAccounts: NewUserAccounts[] = values.user_accounts;
          const formattedNewUserAccounts = newUserAccounts.map((account) => {
            let roles = Object.keys(account.roles).filter(
              (key) => account.roles[key as any]
            );
            return { account: account.account, roles };
          });
          const isProspectiveClient = values.userMemorandums?.enabled;
          const newUserMemorandums: Record<string, boolean> =
            fromAdmin && isProspectiveClient
              ? values.userMemorandums.funds
              : {};
          const formattedNewUserMemorandums: string[] = Object.keys(
            newUserMemorandums
          ).filter((key) => newUserMemorandums[key]);
          const { givenName, familyName, email, birthdate, role } = values;
          addUser.mutate({
            birthdate: formatDate(birthdate),
            email,
            family_name: familyName,
            given_name: givenName,
            role,
            newUserAccounts: formattedNewUserAccounts,
            newUserMemorandums: formattedNewUserMemorandums,
            currentAccount,
          });
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit, values, errors } = props;
          const selectedAccounts = values.user_accounts.map(
            (user_account: any) => user_account.account
          );
          const isProspectiveClient = values.userMemorandums?.enabled;
          const formErrors: any = { ...errors };
          return (
            <>
              {addingUser ? (
                <Spinner loading={addingUser} />
              ) : (
                <form onSubmit={handleSubmit} id="login">
                  <Grid container>
                    <FormInput
                      id="givenName"
                      label="Given Name"
                      fieldType={InputTypes.STRING}
                      layout={{ xs: 6, md: 6, lg: 6 }}
                    />
                    <FormInput
                      id="familyName"
                      label="Family Name"
                      fieldType={InputTypes.STRING}
                      layout={{ xs: 6, md: 6, lg: 6 }}
                    />
                    <FormInput
                      id="email"
                      label="Email"
                      fieldType={InputTypes.STRING}
                      layout={{ xs: 12, md: 12, lg: 12 }}
                    />
                    <FormInput
                      id="birthdate"
                      label="Date of Birth"
                      fieldType={InputTypes.DATE}
                      layout={{ xs: 12, md: 12, lg: 12 }}
                      inputFormat="yyyy"
                    />
                    {/* <FormInput
                                        id='account'
                                        label='Select Account'
                                        fieldType={InputTypes.SELECTION}
                                        layout={{ xs: 6, md: 6, lg: 6 }}
                                        values={formattedAccounts}
                                    /> */}
                    {fromAdmin ? (
                      <Tooltip
                        title={`Select funds from which prospective investor can view memorandums`}
                        placement="bottom"
                      >
                        <div style={{ width: 400 }}>
                          <FormInput
                            id={`userMemorandums.enabled`}
                            label={"Create prospective investor user"}
                            fieldType={InputTypes.CHECKBOX}
                            layout={{ xs: 4, md: 4, lg: 4 }}
                            size="small"
                          />
                        </div>
                      </Tooltip>
                    ) : null}
                    {isProspectiveClient && funds && fromAdmin ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {funds.map((fund) => (
                          <div style={{ width: 200 }}>
                            <FormInput
                              id={`userMemorandums.funds[${fund.id}]`}
                              label={`${fund.name} (${fund.ticker})`}
                              fieldType={InputTypes.CHECKBOX}
                              layout={{ xs: 4, md: 4, lg: 4 }}
                              size="small"
                            />
                          </div>
                        ))}
                      </Box>
                    ) : null}
                    <FieldArray name="user_accounts">
                      {({ remove, push }) => (
                        <Box ml={1} mt={2}>
                          {values.user_accounts.length > 0 &&
                            values.user_accounts.map(
                              (_: any, index: number) => (
                                <Stack
                                  key={index}
                                  style={{
                                    border: "1px solid black",
                                    padding: "10px 20px",
                                  }}
                                  mb={4}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ width: "60%" }}>
                                      <FormInput
                                        id={`user_accounts.${index}.account`}
                                        label="Select Account"
                                        fieldType={InputTypes.SELECTION}
                                        disabled={Number.isInteger(
                                          initialSelected
                                        )}
                                        values={formattedAccounts.map(
                                          (account) =>
                                            selectedAccounts.includes(
                                              account.value
                                            )
                                              ? { ...account, disabled: true }
                                              : account
                                        )}
                                      />
                                    </div>
                                    <Stack mt={-4}>
                                      <Button
                                        variant="outlined"
                                        type="button"
                                        className="secondary"
                                        onClick={() => remove(index)}
                                        style={{ width: 30 }}
                                        // disabled={selectedAccounts.length <= 1}
                                        disabled={
                                          initialSelected ? true : false
                                        }
                                      >
                                        <DeleteOutline />
                                      </Button>
                                    </Stack>
                                  </Box>

                                  <Stack direction="row">
                                    {formattedRoles.map((role) => (
                                      <Tooltip
                                        key={role.id}
                                        title={`Viewable reports: ${formatListAsString(
                                          role.reports
                                        )}`}
                                        placement="bottom"
                                      >
                                        <div style={{ width: 100 }}>
                                          <FormInput
                                            id={`user_accounts.${index}.roles[${role.id}]`}
                                            label={role.label}
                                            fieldType={InputTypes.CHECKBOX}
                                            layout={{ xs: 4, md: 4, lg: 4 }}
                                            size="small"
                                          />
                                        </div>
                                      </Tooltip>
                                    ))}
                                  </Stack>
                                  {formErrors?.user_accounts?.[index]?.roles ? (
                                    <span
                                      style={{ color: "red", marginLeft: 8 }}
                                    >
                                      At least one permission must be assigned
                                    </span>
                                  ) : null}
                                </Stack>
                              )
                            )}
                          {initialSelected ? null : (
                            <Button
                              variant="contained"
                              onClick={() => push({ account: "", roles: "" })}
                              disabled={
                                selectedAccounts.length >=
                                formattedAccounts.length
                              }
                            >
                              Add account
                            </Button>
                          )}

                          <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ my: 4 }}
                          >
                            Create User
                          </Button>
                        </Box>
                      )}
                    </FieldArray>

                    {/* <Grid container justifyContent='flex-start' direction='row'>
                                        {!!user &&
                                            <Typography>{user.email} Account Created.</Typography>
                                        }
                                    </Grid> */}
                  </Grid>
                </form>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default SignupForm;
