import { forwardRef, ReactElement } from "react";
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
// Local Imports
import { InputTypes, PercentageFieldProps } from "../../../types/InputTypes";
import { handleFormValueChange } from "../inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import { 
    Grid, 
    IconButton, 
    InputAdornment, 
    TextField, 
    TextFieldProps,
    Theme, 
    Tooltip 
} from "@mui/material";
// component
import Iconify from "../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

// Currency masking
interface NumberFormatCustomProps extends NumberFormatProps {
    ref: (instance: NumberFormat<any> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

// Number Masking
const NumberFormatCustom = forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref
): ReactElement {
    const { onChange, ...other } = props;

    return (
        <NumberFormat 
            {...other} 
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    )
})

// Default masking props
NumberFormatCustom.defaultProps = {
    thousandSeparator: true,
    fixedDecimalScale: false,
    decimalScale: 2,
    suffix: '%',
    allowNegative: false,
    isAllowed: (values: NumberFormatValues): boolean => ((0 <= parseFloat(values.value)) && (parseFloat(values.value) <= 100))
}

/**
 * Percentage Input Field
 * Masks number input to include decimals and '%' suffix, and limits amount to be with 0 and 100
 */
export default function PercentageField(props: PercentageFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        layout = { xs: 12, md: 12, lg: 12 },
        value,
        size,
        variant,
        noUnderline,
        errorInIcon
    } = props;

    const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        onBlur: props.handleBlur,
        size,
        variant
    }

    if (!errorInIcon) {
        defaultProps.helperText = helperText;
    }

    // Info and Tooltips
    if (info) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={info} placement='right'>
                        <IconButton edge="end" size={size}>
                            <Iconify icon={'eva:question-mark-circle-outline'} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            ),
        }
    }

    // If error display in Icon
    if (errorInIcon && error) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={helperText} placement='right'>
                        <IconButton edge="end" sx={{pl: 0}} size={size}>
                            <Iconify icon={'eva:alert-circle-outline'} sx={{color: 'red'}} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            )
        }
    }
    
    if (variant === 'standard' && noUnderline) {
        defaultProps.InputProps = {
            ...defaultProps.InputProps,
            disableUnderline: true
        }
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <TextField
                {...defaultProps}
                sx={{ width: '100%' }}
                onChange={handleFormValueChange(id, InputTypes.CURRENCY, handleChange)}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    ...defaultProps.InputProps
                }}
            />
        </GridStyle>
    )
}