import { Typography } from "@mui/material";
import Page from "../../../components/Page";
import FilterWholesaleReports from "../components/FilterWholesaleReports/FilterWholesaleReports";
import { useViewableReportsByUserAndAccount } from "@/hooks/useReports";
import Spinner from "@/components/Spinner";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useParams } from "react-router";

const WholesaleReports = () => {
  const user = useCurrentUser();
  let { accountId: currentAccountParam } = useParams();
  const userId = user?.id || "";
  const currentAccount = currentAccountParam || "";

  const { isFetching: fetchingViewableReports, data: viewableReportTypes } =
    useViewableReportsByUserAndAccount({
      userId: userId,
      accountId: currentAccount,
    });

  return (
    <Page title="Reports">
      <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
        Wholesale Reports
      </Typography>
      {fetchingViewableReports ? (
        <Spinner loading={fetchingViewableReports} />
      ) : null}
      {viewableReportTypes ? (
        <FilterWholesaleReports
          key={currentAccount}
          currentAccount={currentAccount}
          viewableReportTypes={viewableReportTypes}
        />
      ) : null}
    </Page>
  );
};

export default WholesaleReports;
