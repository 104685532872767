import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Container, Grid, Typography } from "@mui/material";

// hooks
import useResponsive from "../../../hooks/useResponsive";
import { useForgotPassword, useVerifyUser } from "@/hooks/useAuth";

// components
import Page from "../../../components/Page";
import Logo from "../../../components/Logo";
import Spinner from "../../../components/Spinner";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ConfirmNewUser() {
  const [searchParams, setSearchParams] = useSearchParams();

  const verifyUser = useVerifyUser();
  const initiateForgotPassword = useForgotPassword();

  const { isPending: verifyingUser, isSuccess: userVerified } = verifyUser;

  const code = searchParams.get("code");
  const email = searchParams.get("email");

  useEffect(() => {
    if (userVerified) {
      setSearchParams("");
    }
  }, [userVerified]);

  useEffect(() => {
    const handleVerification = async () => {
      if (!verifyingUser && !userVerified && code && email) {
        //console.log('reached', code, email)
        const userVerified = await verifyUser.mutateAsync({
          code: code,
          email: email,
        });
        if (userVerified) {
          toast.success("User verified");
          const resetPasswordEmailSent =
            await initiateForgotPassword.mutateAsync({ email: email });
          if (resetPasswordEmailSent) {
            toast.success("Please check your email for reset code");
          }
        }
      }
    };
    handleVerification();
  }, [code, email, userVerified, verifyingUser]);

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Confirm Account">
      <RootStyle>
        <HeaderStyle>
          <Logo white={false} />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {!userVerified && (!code || !email) && (
              <Grid container justifyContent="center" direction="row">
                <Typography
                  variant="h5"
                  sx={{ color: "text.secondary", my: 2 }}
                >
                  Could not verify User. Please contact help.
                </Typography>
              </Grid>
            )}
            {verifyingUser && <Spinner loading={verifyingUser} />}
            {!verifyingUser && userVerified && (
              <Grid container justifyContent="center" direction="row">
                <Typography
                  variant="h3"
                  sx={{ color: "text.secondary", my: 2 }}
                >
                  User verified.
                </Typography>

                <Typography sx={{ color: "text.secondary", my: 2 }}>
                  Reset password instructions have been sent to your email.
                  Please follow the directions as necessary.
                </Typography>
                <Typography sx={{ color: "text.secondary", my: 2 }}>
                  Please note that the reset password link expires in 60
                  minutes.
                </Typography>
              </Grid>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
