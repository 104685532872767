import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import Login from "../features/auth/pages/Login";
import ConfirmNewUser from "../features/auth/pages/ConfirmNewUser";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";
import ResetPassword from "../features/auth/pages/ResetPassword";
import DashboardApp from "../features/dashboard/pages/Dashboard";
import MyInvestments from "../features/investments/pages/Investments";
import WholesaleReports from "../features/reports/pages/WholesaleReports";
import Memorandums from "../features/investor_memorandums/pages/Memorandums";
// import Profile from "../features/user_account/pages/Profile";
import Admin from "../features/admin/pages/Admin";
import Logout from "../features/auth/pages/Logout";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import NotFound from "./components/NotFound";
import { PERMISSIONS } from "../types/PermissionTypes";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import AccountManagement from "../features/user_account/pages/AccountManagement";
import LandingPage from "../features/landing_page/pages/LandingPage";
import {
  useCheckUserExistsInDb,
  useLogoutCognito,
  useReauthUserQuery,
} from "@/hooks/useAuth";
import {
  User,
  UserRoleType,
  useCurrentUserActions,
} from "@/hooks/useCurrentUser";
import AccountReports from "@/features/reports/pages/AccountReports";
import FundReports from "@/features/reports/pages/FundReports";

const {
  ALL_ADMIN_ROLES,
  ALL_USER_ROLES,
  ACCOUNT_ADMIN_ROLES,
  ALL_USER_ACCOUNT_ROLES,
  INTERNAL_ADMIN_ROLES,
  USER_ROLE_ONLY,
} = PERMISSIONS;

const AnyLoggedInUserRoute = ({ redirectPath, children }: any) => {
  return (
    <ProtectedRoute
      redirectPath={redirectPath}
      requiredUserRoles={ALL_USER_ROLES}
      requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
    >
      {children}
    </ProtectedRoute>
  );
};

const Router = () => {
  const [reauthed, setReauthed] = useState(false);
  const { reauth } = useCurrentUserActions();
  const { pathname } = useLocation();

  const isLogin = pathname === "/login";
  const isRoot = pathname === "/";
  //console.log("here", pathname, isLogin);

  const {
    data: userTemp,
    isSuccess: cognitoUserFound,
    isError: reauthError,
  } = useReauthUserQuery({
    enabled: !reauthed && !isLogin && !isRoot ? true : false,
  });

  const navigate = useNavigate();

  //console.log("reached", reauthError);

  const sub = userTemp?.attributes?.sub;

  const { data: userExists, isError: userNotInDb } = useCheckUserExistsInDb({
    sub,
    enabled: cognitoUserFound && sub ? true : false,
  });

  const signOut = useLogoutCognito();

  const { isSuccess: signedOut } = signOut;

  useEffect(() => {
    if (userNotInDb) {
      signOut.mutate();
    }
  }, [userNotInDb]);

  useEffect(() => {
    //console.log("signedout", signedOut, "reauthedError", reauthError, pathname);
    if (signedOut || reauthError) {
      navigate("/login");
    }
  }, [signedOut, reauthError]);

  useEffect(() => {
    if (userExists && userExists.id) {
      const newUser = {
        id: userExists.id,
        username: userTemp.username,
        ...userTemp.attributes,
        roles: userExists.roles,
      };
      // Extract Role attribute
      if (newUser["custom:role"]) {
        newUser.role = newUser["custom:role"];
      } else {
        newUser.role = UserRoleType.USER;
      }

      const { id, email, given_name, family_name, birthdate, role, roles } =
        newUser;

      const formattedUser: User = {
        id,
        email,
        given_name,
        family_name,
        birthdate,
        role,
        roles,
      };
      reauth(formattedUser);
      if (isLogin) {
        const navigateTo =
          role === UserRoleType.ADMIN ? "/portal/admin" : "/portal/landing";
        navigate(navigateTo);
      }
      setReauthed(true);
    }
  }, [userExists]);

  return (
    <>
      {/* <Navigation userFullName={userFullName} /> */}
      <Routes>
        {/* UNPROTECTED ROUTES */}
        <Route path="/" element={<LogoOnlyLayout />}>
          {/* <Route index element={<Login />} /> */}
          <Route index path="/" element={<Navigate to="/login" />} />
          <Route path="login" element={<Login />} />
          <Route path="confirmEmail" element={<ConfirmNewUser />} />
          <Route path="resetPassword" element={<ResetPassword />} />
          <Route path="logout" element={<Logout />} />
          <Route
            path="*"
            element={
              !reauthed ? (
                <div
                  style={{
                    height: "100vh",
                    display: "grid",
                    placeContent: "center",
                  }}
                >
                  <CircularProgress size={200} />
                </div>
              ) : (
                <NotFound />
              )
            }
          />
        </Route>
        {/* PROTECTED ROUTES */}
        {reauthed ? (
          <>
            <Route path="/portal" element={<DashboardLayout />}>
              <Route
                path="/portal/dashboard"
                element={
                  <AnyLoggedInUserRoute redirectPath="/portal/landing">
                    <DashboardApp />
                  </AnyLoggedInUserRoute>
                }
              >
                <Route
                  path=":accountId"
                  element={
                    <AnyLoggedInUserRoute redirectPath="/login">
                      <DashboardApp />
                    </AnyLoggedInUserRoute>
                  }
                />
              </Route>
              <Route
                path="/portal/admin"
                element={
                  <ProtectedRoute
                    redirectPath="/portal/dashboard"
                    requiredUserRoles={INTERNAL_ADMIN_ROLES}
                    requiredUserAccountRoles={INTERNAL_ADMIN_ROLES}
                  >
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route path="/portal/investments">
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={USER_ROLE_ONLY}
                      requiredUserAccountRoles={ACCOUNT_ADMIN_ROLES}
                    >
                      <MyInvestments />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/portal/wholesaleReports">
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={ALL_USER_ROLES}
                      requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
                    >
                      <WholesaleReports />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/portal/fundReports">
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={ALL_USER_ROLES}
                      requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
                    >
                      <FundReports />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/portal/accountReports">
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={ALL_USER_ROLES}
                      requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
                    >
                      <AccountReports />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/portal/memorandums"
                element={
                  <ProtectedRoute
                    redirectPath="/login"
                    requiredUserRoles={ALL_USER_ROLES}
                    requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
                    onlyCheckUserRole={true}
                  >
                    <Memorandums />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={ALL_USER_ROLES}
                      requiredUserAccountRoles={ALL_ADMIN_ROLES}
                    >
                      <Memorandums />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="/portal/accounts">
                <Route
                  path=":accountId"
                  element={
                    <ProtectedRoute
                      redirectPath="/portal/dashboard"
                      requiredUserRoles={ALL_USER_ROLES}
                      requiredUserAccountRoles={ALL_ADMIN_ROLES}
                    >
                      <AccountManagement />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/portal/landing"
                element={
                  <ProtectedRoute
                    redirectPath="/login"
                    requiredUserRoles={ALL_USER_ROLES}
                    requiredUserAccountRoles={ALL_USER_ACCOUNT_ROLES}
                    onlyCheckUserRole={true}
                  >
                    <LandingPage />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="/portal/profile">
                <Route
                  path=":accountId"
                  element={
                    <AnyLoggedInUserRoute>
                      <Profile />
                    </AnyLoggedInUserRoute>
                  }
                />
              </Route> */}
              <Route path="404" element={<NotFound />} />
            </Route>
          </>
        ) : null}
      </Routes>
    </>
  );
};

export default Router;
