import Spinner from "@/components/Spinner";
import { USERS_PER_PAGE, User } from "@/hooks/useUsers";
import { Filter, Model, ModelFilter } from "@/types/GeneralTypes";
import { Box, Select, TextField } from "@mui/material";
import PaginationTable from "../PaginationTable";
import {
  determineModelFilters,
  determineModelHeaders,
} from "@/utils/modelUtils";
import { HeaderSort } from "@/components/PaginationTable/PaginationTable";
import { Fund } from "@/hooks/useFunds";
import { Filters } from "@/components/PaginationTableWithAddNewWrapper/PaginationTableWithAddNewWrapper";
import PaginationFilters from "@/components/PaginationWrapper/PaginationFilters";
import { Account } from "@/hooks/useAccounts";
interface PaginationWrapperProps {
  fetchingPageData: boolean;
  fetchingTotalData: boolean;
  pageData: any;
  totalNumberData: any;
  model: Model;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  setSort?: (newSort: string) => void;
  handleRequestSort?: (newSort: HeaderSort) => void;
  sortedHeaders?: HeaderSort[];
  filter?: string;
  setFilter?: (newFilter: string) => void;
  filters?: Filters;
  userModalProps?: { funds: Fund[]; accounts: Account[] };
  fromAdmin: boolean;
}

interface determineFilterOptionsProps {
  filters: Filters;
  filter: ModelFilter | Filter;
}

const determineFilterOptions = ({
  filters,
  filter,
}: determineFilterOptionsProps) => {
  if (filter === ModelFilter.FUND) {
    const funds = filters[filter];
    return funds.map((fund) => ({
      value: fund.id,
      label: `${fund.name} (${fund.ticker})`,
    }));
  } else if (filter === ModelFilter.REPORT_TYPE) {
    const reportTypes = filters[filter];
    return reportTypes.map((reportType) => ({
      value: reportType.id,
      label: reportType.name,
    }));
  } else if (filter === ModelFilter.ACCOUNT) {
    const accounts = filters[filter];
    return accounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));
  } else {
    return [];
  }
};

const PaginationWrapper = ({
  fetchingPageData,
  fetchingTotalData,
  pageData,
  totalNumberData,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  model,
  handleRequestSort,
  sortedHeaders,
  filter,
  setFilter,
  filters,
  userModalProps,
  fromAdmin,
}: PaginationWrapperProps) => {
  const fetchingData = fetchingPageData || fetchingTotalData;
  const dataExists = (totalNumberData === 0 || totalNumberData) && pageData;
  const loading = !(!fetchingData && dataExists);
  const filtersToDisplayForModel = determineModelFilters(model);
  const filtersToDisplay =
    filters &&
    filtersToDisplayForModel.map((filter) => ({
      filterKey: filter,
      options: determineFilterOptions({ filters, filter }),
    }));
  const hasFilters = filtersToDisplay && filtersToDisplay.length > 0;
  //console.log(filters, filtersToDisplay, hasFilters, setFilter, model);

  return (
    <div>
      <Box sx={{ minHeight: 500 }}>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </Box>
        {hasFilters && setFilter ? (
          <PaginationFilters
            model={model}
            setFilter={setFilter}
            filtersToDisplay={filtersToDisplay}
          />
        ) : null}
        <PaginationTable
          fromAdmin={fromAdmin}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalNumberEntries={totalNumberData}
          numberEntriesPerPage={USERS_PER_PAGE}
          tableData={{
            headers: determineModelHeaders(model),
            body: pageData,
          }}
          model={model}
          handleRequestSort={handleRequestSort}
          sortedHeaders={sortedHeaders}
          userModalProps={userModalProps}
        />
      </Box>
    </div>
  );
};

export default PaginationWrapper;
