import { apiGetRequest, apiGetRequestTest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Fund } from "./useFunds";

export enum ReportCategories {
  EXPOSURE = "Exposure",
  PORTFOLIO = "Portfolio",
  FINANCIAL_YEAR = "FY",
  TAX = "Tax",
  MEMORANDUM = "Memorandum",
  TRANSACTION = "Transaction",
  PERIODIC = "Periodic",
}

export interface ReportType {
  id: string;
  name: ReportCategories;
}

export interface ViewableReport {
  id: string;
  name: string;
  s3_key: string;
  period: number;
}

//Get all report types
export const useReportTypesQuery = () =>
  useQuery({
    queryKey: ["reportTypes"],
    queryFn: async () =>
      await apiGetRequest<ReportType[]>({
        query: `newReports?getReportTypes=true`,
      }),
    retry: false,
  });

interface useViewableReportsByUserAndAccountProps {
  accountId: string;
  userId: string;
}

//Get viewable report types for a userAccount
export const useViewableReportsByUserAndAccount = ({
  accountId,
  userId,
}: useViewableReportsByUserAndAccountProps) =>
  useQuery({
    queryKey: ["viewableReportTypes", accountId, userId],
    queryFn: async () =>
      await apiGetRequest<ReportType[]>({
        query: `newUserAccounts?userId=${userId}&accountId=${accountId}&viewableReports=true`,
      }),
    retry: false,
  });

interface useViewableWholesaleReportsByReportTypeAndAccountIdProps {
  reportType: string | null;
  userAccountId: string | null;
}

//Get viewable report types for a userAccount
export const useViewableWholesaleReportsByReportTypeAndAccountId = ({
  reportType,
  userAccountId,
}: useViewableWholesaleReportsByReportTypeAndAccountIdProps) =>
  useQuery({
    queryKey: ["viewableReports", reportType, userAccountId],
    queryFn: async () =>
      await apiGetRequest<ViewableReport[]>({
        query: `newReports?reportTypeId=${reportType}&userAccountId=${userAccountId}`,
      }),
    retry: false,
    enabled: reportType && userAccountId ? true : false,
  });

interface useViewableFundReportsByReportTypeAndAccountIdProps {
  fundId: string | null;
  userAccountId: string | null;
}

//Get viewable report types for a userAccount
export const useViewableFundReportsByReportTypeAndAccountId = ({
  fundId,
  userAccountId,
}: useViewableFundReportsByReportTypeAndAccountIdProps) =>
  useQuery({
    queryKey: ["viewableFundReports", fundId, userAccountId],
    queryFn: async () =>
      await apiGetRequest<ViewableReport[]>({
        query: `newReports?fundId=${fundId}&userAccountId=${userAccountId}`,
      }),
    retry: false,
    enabled: fundId ? true : false,
  });

interface useViewableFundReportsByUserAccountIdProps {
  userAccountId: string | null;
}

//Get viewable report types for a userAccount
export const useViewableFundReportsByUserAccountId = ({
  userAccountId,
}: useViewableFundReportsByUserAccountIdProps) =>
  useQuery({
    queryKey: ["fundsWithFundReport", userAccountId],
    queryFn: async () =>
      await apiGetRequest<Fund[]>({
        query: `newReports?fundReports=true&userAccountId=${userAccountId}`,
      }),
    retry: false,
    enabled: userAccountId ? true : false,
  });

interface useWholesaleReportUrlQueryProps {
  fileKey: string | null;
  userId: string | null;
}

export const useWholesaleReportUrlQuery = ({
  fileKey,
  userId,
}: useWholesaleReportUrlQueryProps) =>
  useQuery({
    queryKey: ["viewableReport", fileKey, userId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newReports?fileKey=${fileKey}&userId=${userId}`,
      }),
    retry: false,
    enabled: fileKey && userId ? true : false,
  });

interface useFundReportUrlQueryProps {
  fileKey: string | null;
  userId: string | null;
}

export const useFundReportUrlQuery = ({
  fileKey,
  userId,
}: useFundReportUrlQueryProps) =>
  useQuery({
    queryKey: ["viewableReport", fileKey, userId],
    queryFn: async () =>
      await apiGetRequest<string>({
        query: `newReports?fileKey=${fileKey}&userId=${userId}&fundReports=true`,
      }),
    retry: false,
    enabled: fileKey && userId ? true : false,
  });

interface usePaginationFundReportsQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}

interface PaginationFundReportsReturn {
  count: number;
  data: ViewableReport[]
}

export const usePaginationFundReportsQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationFundReportsQueryProps) =>
  useQuery({
    queryKey: ["fundReportsByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationFundReportsReturn>({
        query: `fund-reports?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });





interface usePaginationWholesaleReportsQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}

interface PaginationWholesaleReportsReturn {
  count: number;
  data: ViewableReport[]
}

export const usePaginationWholesaleReportsQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationWholesaleReportsQueryProps) =>
  useQuery({
    queryKey: ["wholesaleReportsByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationWholesaleReportsReturn>({
        query: `wholesale-reports?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });


interface useUploadWholesaleReportProps {
  fileKey: string;
  fileData: FormData;
  reportId: string;
  accountId: string;
  period: string;
  name: string;
  userId: string | null;
}

export const useUploadWholesaleReport = () => {
  return useMutation({
    mutationFn: async ({
      fileKey,
      fileData,
      reportId,
      accountId,
      period,
      name,
      userId,
    }: useUploadWholesaleReportProps) => {
      try {
        const uploadUrl = await apiGetRequest<string>({
          query: `newReports?pdfToUpload=${fileKey}`,
        });

        if (uploadUrl) {
          try {
            console.log("fileData", fileData);

            const response = await fetch(uploadUrl, {
              method: "PUT",
              body: fileData.get("file"),
            });

            if (!response.ok) {
              throw new Error("File upload failed");
            } else {
              try {
                await apiPostRequest<string>({
                  query: `newReports?fileKey=${fileKey}&name=${name}&reportTypeId=${reportId}&accountId=${accountId}&period=${period}&userId=${userId}&wholesaleReports=true`,
                });
              } catch (error) {
                throw new Error("Error adding memo to db");
              }
            }
          } catch (error) {
            throw new Error("File upload failed");
          }
        }
      } catch (error) {
        throw new Error("Error getting upload url");
      }
    },
    onSuccess: () => {
      toast.success("Report uploaded");
    },
  });
};

interface useUploadFundReportProps {
  fileKey: string;
  fileData: FormData;
  fundId: string;
  period: string;
  userId: string | null;
}

export const useUploadFundReport = () => {
  return useMutation({
    mutationFn: async ({
      fileKey,
      fileData,
      fundId,
      period,
      userId,
    }: useUploadFundReportProps) => {
      try {
        const uploadUrl = await apiGetRequest<string>({
          query: `newReports?pdfToUpload=${fileKey}`,
        });

        if (uploadUrl) {
          try {
            console.log("fileData", fileData);

            const response = await fetch(uploadUrl, {
              method: "PUT",
              body: fileData.get("file"),
            });

            if (!response.ok) {
              throw new Error("File upload failed");
            } else {
              try {
                await apiPostRequest<string>({
                  query: `newReports?fileKey=${fileKey}&fundId=${fundId}&period=${period}&userId=${userId}&fundReports=true`,
                });
              } catch (error) {
                throw new Error("Error adding memo to db");
              }
            }
          } catch (error) {
            throw new Error("File upload failed");
          }
        }
      } catch (error) {
        console.log("test", error);
        throw new Error("Error getting upload url");
      }
    },
    onSuccess: () => {
      toast.success("Report uploaded");
    },
  });
};
