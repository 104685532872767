import { pdfjs, Document, Page } from "react-pdf";
import { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { styled } from "@mui/material/styles";
import Spinner from "../Spinner";
import OpenInNewTabButton from "../OpenInNewTabButton";
import InvestModal from "../InvestModal";
import { Link } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFWrapper = styled("div")(({ theme }) => ({
  marginTop: 25,
  marginLeft: 6,
  maxWidth: "fit-content",

  "@media print": {
    // prevent file showing up under print -> save as pdf.
    ".pdf-container": {
      display: "none",
    },
  },
  ".pdf-container": {
    userSelect: "none", // prevent left clicking -> highlight text. Context menu also disabled in Document component to prevent right clicking.
  },
  ".textLayer": {
    "& span, & br": {
      cursor: "not-allowed",
    },
  },
  ".react-pdf__Page.loadingPage": {
    display: "none",
  },
  ".react-pdf__Page.prevPage": {
    position: "absolute !important",
    zIndex: 1,
  },
  ".react-pdf__Document": {
    height: 1200,
  },
  backgroundColor: theme.palette.grey[200],
}));

// const PDFWrapper = styled("div")`
//   margin-top: 25px;
//   margin-left: 6px;
//   max-width: fit-content;
//   @media print {
//     //prevent file showing up under print -> save as pdf.
//     .pdf-container {
//       display: none;
//     }
//   }
//   .pdf-container {
//     user-select: none; //prevent left clicking -> highlight text. Context menu also disabled in Document component to prevent right clicking.
//   }
//   .textLayer :is(span, br) {
//     cursor: not-allowed;
//   }
//   .react-pdf__Page.loadingPage {
//     display: none;
//   }
//   .react-pdf__Page.prevPage {
//     position: absolute !important;
//     z-index: 1;
//   }
//   backgroundColor: theme.palette.grey[200],
// `;

interface PdfViewerProps {
  file: string;
  isVisible?: boolean;
  investButton?: boolean;
}
const PDFViewer = ({
  file,
  isVisible,
  investButton = false,
}: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number | null>(
    null
  );
  const isLoading = renderedPageNumber !== pageNumber;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageNumber(1);
    setNumPages(numPages);
  }

  return file ? (
    <div>
      {numPages ? (
        <Stack direction="column" spacing={2} my={4} ml={1} sx={{ width: 825 }}>
          <p style={{ marginLeft: 3 }}>
            Page {pageNumber} of {numPages}
          </p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                href="#target"
                disabled={pageNumber === 1}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();

                  setPageNumber(pageNumber - 1 >= 1 ? pageNumber - 1 : 1);
                }}
              >
                Previous page
              </Button>
              <Button
                href="#target"
                disabled={pageNumber === numPages}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  setPageNumber(
                    pageNumber + 1 <= numPages ? pageNumber + 1 : pageNumber
                  );
                }}
              >
                Next page
              </Button>
            </Stack>
            {investButton ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <OpenInNewTabButton
                  url="https://apply.automic.com.au/MDIF"
                  title="Invest now"
                />
                <Link
                  to={
                    "https://www.blackrock.com/au/individual/literature/forms/blackrock-application-form-retail-funds-en-au.pdf"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download application form
                </Link>
              </Box>
            ) : null}
          </Box>
        </Stack>
      ) : null}
      <PDFWrapper>
        <Document
          className="pdf-container"
          file={`${file}`}
          onLoadSuccess={onDocumentLoadSuccess}
          onContextMenu={(e) => e.preventDefault()}
          loading={<Spinner loading={true} />}
        >
          <>
            {isLoading && renderedPageNumber ? (
              <Page
                scale={1.4}
                key={renderedPageNumber}
                className="prevPage"
                pageNumber={renderedPageNumber}
              />
            ) : null}
            <Page
              className={`${isLoading ? "loadingPage" : ""}`}
              scale={1.4}
              key={pageNumber}
              pageNumber={pageNumber}
              onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
            />
          </>
        </Document>
      </PDFWrapper>
      {numPages ? (
        <Stack direction="column" spacing={2} my={4} ml={1} sx={{ width: 825 }}>
          <p style={{ marginLeft: 3 }}>
            Page {pageNumber} of {numPages}
          </p>
          <Stack direction="row" spacing={2}>
            <Button
              href="#target"
              disabled={pageNumber === 1}
              variant="outlined"
              onClick={(e) => {
                setPageNumber(pageNumber - 1 >= 1 ? pageNumber - 1 : 1);
                // e.preventDefault();
              }}
            >
              Previous page
            </Button>
            <Button
              href="#target"
              disabled={pageNumber === numPages}
              variant="outlined"
              onClick={(e) => {
                setPageNumber(
                  pageNumber + 1 <= numPages ? pageNumber + 1 : pageNumber
                );
                //e.preventDefault();
              }}
            >
              Next page
            </Button>
          </Stack>
        </Stack>
      ) : null}
    </div>
  ) : null;
};

export default PDFViewer;
