import AddAccount from "@/features/admin/components/AddAccount";
import AddFund from "@/features/admin/components/AddFund";
import UploadFundReport from "@/features/admin/components/UploadFundReport";
import UploadMemo from "@/features/admin/components/UploadMemo";
import UploadWholesaleReport from "@/features/admin/components/UploadWholesaleReport";
import { Account } from "@/hooks/useAccounts";
import { Fund } from "@/hooks/useFunds";
import { ReportType } from "@/hooks/useReports";
import { Model } from "@/types/GeneralTypes";
import { determinePrintableModelName } from "@/utils/modelUtils";
import { Box, Modal, Typography } from "@mui/material";
import React from "react";

interface AddNewModelModalProps {
  model: Model;
  addModelModalOpen: boolean;
  setAddModelModalOpen: (newModalState: boolean) => void;
  funds?: Fund[];
  accounts?: Account[];
  reportTypes?: ReportType[];
}

const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddNewModelModal = ({
  model,
  addModelModalOpen,
  setAddModelModalOpen,
  funds,
  accounts,
  reportTypes,
}: AddNewModelModalProps) => {
  const renderAddModelForm = ({ model }: { model: Model }) => {
    if (model === Model.ACCOUNTS) {
      return (
        <>
          {accounts ? (
            <AddAccount
              accounts={accounts}
              setAddAccountModalOpen={setAddModelModalOpen}
            />
          ) : null}
        </>
      );
    } else if (model === Model.MEMORANDUMS) {
      return (
        <>
          {funds ? (
            <UploadMemo
              funds={funds}
              model={model}
              setAddMemoModalOpen={setAddModelModalOpen}
            />
          ) : null}
        </>
      );
    } else if (model === Model.FUNDS) {
      return (
        <>
          {funds ? (
            <AddFund funds={funds} setAddFundModalOpen={setAddModelModalOpen} />
          ) : null}
        </>
      );
    } else if (model === Model.FUND_REPORTS) {
      return (
        <>
          {funds ? (
            <UploadFundReport
              funds={funds}
              model={model}
              setAddReportModalOpen={setAddModelModalOpen}
            />
          ) : null}
        </>
      );
    } else if (model === Model.REPORTS) {
      return (
        <>
          {accounts && reportTypes ? (
            <UploadWholesaleReport
              accounts={accounts}
              reportTypes={reportTypes}
              setAddReportModalOpen={setAddModelModalOpen}
              model={model}
            />
          ) : null}
        </>
      );
    }
  };
  return (
    <Modal open={addModelModalOpen} onClose={() => setAddModelModalOpen(false)}>
      <Box sx={ModalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Upload {determinePrintableModelName(model)}
        </Typography>
        {renderAddModelForm({ model })}
      </Box>
    </Modal>
  );
};

export default AddNewModelModal;
