import Tag from "@/components/Tag";
import { LoggedInUserAccount } from "@/hooks/useCurrentUser";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";

interface UserAccountCardProps {
  userAccount: LoggedInUserAccount;
}

const UserAccountCard = ({ userAccount }: UserAccountCardProps) => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`/portal/dashboard/${userAccount.account_id}`)}
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          border: "1px solid black",
          width: "fit-content",
          padding: "20px",
          minWidth: 250,
          backgroundColor: "white",
          cursor: "pointer",
          flexWrap: "wrap",
        },
        {
          "&:hover": {
            backgroundColor: "lightgray",
          },
        },
      ]}
    >
      <h2>{userAccount.account}</h2>
      {userAccount.roles.map((role) => (
        <div style={{ margin: 0 }}>
          <Tag>{role}</Tag>
        </div>
      ))}
    </Box>
  );
};

export default UserAccountCard;
