// Local Imports
import {
  CheckboxFieldProps,
  CurrencyFieldProps,
  DateFieldProps,
  FileFieldProps,
  FormattedFieldProps,
  InputFieldProps,
  InputTypes,
  PasswordFieldProps,
  PercentageFieldProps,
  RadioFieldProps,
  SelectionFieldProps,
} from "../../types/InputTypes";
import { handleFormValueChange } from "./inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
} from "@mui/material";
// Components
import {
  CheckboxField,
  CurrencyField,
  DateField,
  FileInputField,
  FormattedTextField,
  PasswordField,
  PercentageField,
  RadioButtonField,
  SelectionField,
} from "./CustomFields";
import Iconify from "../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
  width: "100%",
}));

// Standardised Input Field for all types of input
export default function InputField(props: InputFieldProps) {
  switch (props.fieldType) {
    // CHECKBOX TYPE INPUT
    case InputTypes.CHECKBOX:
      return <CheckboxField {...(props as CheckboxFieldProps)} />;

    // CURRENCY TYPE INPUT
    case InputTypes.CURRENCY:
      return <CurrencyField {...(props as CurrencyFieldProps)} />;

    // DATE TYPE INPUT
    case InputTypes.DATE:
      return <DateField {...(props as DateFieldProps)} />;

    // FILE INPUTS
    case InputTypes.FILE:
      return <FileInputField {...(props as FileFieldProps)} />;

    case InputTypes.FORMATTED:
      return <FormattedTextField {...(props as FormattedFieldProps)} />;

    // PERCENTAGE TYPE INPUT
    case InputTypes.PERCENTAGE:
      return <PercentageField {...(props as PercentageFieldProps)} />;

    // PASSWORD TYPE STRING INPUT
    case InputTypes.PASSWORD:
      return <PasswordField {...(props as PasswordFieldProps)} />;

    // RADIO STRING INPUT
    case InputTypes.RADIO:
      return <RadioButtonField {...(props as RadioFieldProps)} />;

    // SELECTIOn TYPE INPUT
    case InputTypes.SELECTION:
      return <SelectionField {...(props as SelectionFieldProps)} />;

    // DEFAULT STRING/NUMBER/TEXTBOX FIELD
    default:
      const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        fieldType,
        layout = { xs: 12, md: 12, lg: 12 },
        size,
        variant,
        noUnderline,
        errorInIcon,
        value,
      } = props;

      const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        variant,
        onBlur: props.handleBlur,
        size,
      };
      if (!errorInIcon) {
        defaultProps.helperText = helperText;
      }

      // Establishes default input type and other properties
      if (fieldType === InputTypes.NUMBER) {
        defaultProps.type = "number";
        defaultProps.InputProps = {
          ...defaultProps.InputProps,
          style: { textAlign: "right" },
        };
      } else if (fieldType === InputTypes.TEXT_BOX) {
        defaultProps.type = "string";
        defaultProps.rows = 4;
        defaultProps.multiline = true;
      } else {
        defaultProps.type = "string";
      }

      // Insert Info Tooltip if necessary
      if (info) {
        defaultProps.InputProps = {
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={info} placement="right">
                <IconButton edge="end" size={size}>
                  <Iconify icon={"eva:question-mark-circle-outline"} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        };
      }

      // If error display in Icon
      if (errorInIcon && error) {
        defaultProps.InputProps = {
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={helperText} placement="right">
                <IconButton edge="end" sx={{ pl: 0 }} size={size}>
                  <Iconify
                    icon={"eva:alert-circle-outline"}
                    sx={{ color: "red" }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        };
      }

      // Removes underline of TextField
      if (variant === "standard" && noUnderline) {
        defaultProps.InputProps = {
          ...defaultProps.InputProps,
          disableUnderline: true,
        };
      }

      return (
        <GridStyle sx={{ p: 1 }} item {...layout}>
          <TextField
            {...defaultProps}
            sx={{ width: "100%" }}
            onChange={handleFormValueChange(id, fieldType, handleChange)}
          />
        </GridStyle>
      );
  }
}
