import { styled, useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";

// components
import Page from "../../../components/Page";

import { Pie, Doughnut, Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { TableExample } from "../../../components/Table";
import { generateRandomNumbers } from "@/utils/generalUtils";
import _ from "lodash";

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

// ----------------------------------------------------------------------

const ChartWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

// ----------------------------------------------------------------------

export default function MyInvestments() {
  const theme = useTheme();

  //const xlUp = useResponsive('up', 'xl');

  const INVESTMENT_ALLOCATION = {
    labels: ["MXT", "MOT", "MDI"],
    datasets: [
      {
        data: [25000, 15000, 30000],
        backgroundColor: [
          theme.palette.primary.light,
          theme.palette.secondary.light,
          theme.palette.primary.main,
        ],
      },
    ],
  };

  const total = 100;
  const count = 6;
  const minValue = 7;

  const sectorData = generateRandomNumbers(count, total, minValue);

  console.log("sector", sectorData);

  const SECTOR_ALLOCATION = {
    labels: [
      "Consumer Discretionary",
      "IT",
      "Telecommunications",
      "Industries",
      "Healthcare",
      "Energy",
    ],
    datasets: [
      {
        data: _.shuffle(sectorData),
        backgroundColor: [
          // @ts-ignore
          theme.palette.chart.blue[1],
          // @ts-ignore
          theme.palette.chart.blue[0],
          theme.palette.primary.light,
          theme.palette.secondary.light,
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ],
      },
    ],
  };

  const years = [2018, 2019, 2020, 2021, 2022, 2023];

  const SHARPE_DATA = {
    labels: years,
    datasets: [
      {
        label: "Sharpe ratio",
        borderColor: theme.palette.primary.light,
        data: [1, 1.3, 1.7, 0.5, 0.8, 1.2],
      },
    ],
  };

  return (
    <Page title="My Investments">
      <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
        My Investments
      </Typography>
      <Grid container spacing={6}>
        <Grid xs={8} md={6} lg={4}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" mb={2}>
              Investment Allocation
            </Typography>
            <ChartWrapper>
              <Pie data={INVESTMENT_ALLOCATION} />
            </ChartWrapper>
          </div>
        </Grid>
        <Grid xs={8} md={6} lg={4}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" mb={2}>
              Sector Allocation
            </Typography>
            <ChartWrapper>
              <Doughnut data={SECTOR_ALLOCATION} />
            </ChartWrapper>
          </div>
        </Grid>
        <Grid xs={8} md={6} lg={4}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" mb={2}>
              Rolling Sharpe Ratio
            </Typography>
            <Box mt={"60px"}>
              <ChartWrapper>
                <Line
                  data={SHARPE_DATA}
                  options={{
                    scales: {
                      y: {
                        ticks: {
                          stepSize: 1,
                          callback: (value, index, values) => value + "%",
                        },
                        beginAtZero: true,
                        min: -3,
                        max: 3,
                      },
                    },
                  }}
                />
              </ChartWrapper>
            </Box>
          </div>
        </Grid>
        <Grid xs={12} xl={6}>
          <TableExample tableData="pricings" />
        </Grid>
        <Grid xs={12} xl={6}>
          <TableExample tableData="transactions" />
        </Grid>
      </Grid>

      {/*<div style={{display: "flex", flexDirection: xlUp ? "row" : "column", alignItems: "center", gap: "40px"}}>*/}
      {/*    <TableExample tableData="pricings" width={700}/>*/}
      {/*    <TableExample tableData="transactions" width={700}/>*/}
      {/*</div>*/}
      {/*<TableExample/>*/}
    </Page>
  );
}
