import { styled } from "@mui/material";

type TagStyleProps = {
  selected: boolean;
};

interface ClickableTagProps {
  children: React.ReactNode;
  selected: boolean;
  onClick: () => void;
}

const ClickableTag = ({ onClick, children, selected }: ClickableTagProps) => {
  const TagWrapper = styled("button")(({ selected }: TagStyleProps) => ({
    display: "inline-block",
    padding: "4px 8px",
    margin: "4px",
    fontSize: 14,
    fontWeight: "bold",
    color: selected ? "#0958d9" : "#000000E0",
    background: selected ? "#e6f4ff" : "#fafafa",
    borderColor: selected ? "#91caff" : "#d9d9d9",
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  }));
  return (
    <TagWrapper
      onClick={() => {
        onClick();
      }}
      selected={selected}
    >
      {children}
    </TagWrapper>
  );
};

export default ClickableTag;
