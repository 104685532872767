import BulkUserCSVReader from "@/features/admin/components/BulkAddUserModal/BulkUserCSVReader";
import Spinner from "@/components/Spinner";
import { Account } from "@/hooks/useAccounts";
import { Role } from "@/hooks/useRoles";
import { Box, Modal, Typography } from "@mui/material";
import { Fund } from "@/hooks/useFunds";

interface BulkAddUserModalProps {
  open: boolean;
  handleClose: any;
  funds: Fund[] | undefined;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflowY: "scroll",
};

const BulkAddUserModal = ({
  open,
  handleClose,
  funds,
}: BulkAddUserModalProps) => {
  const loading = !funds;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        //style={{ overflow: "scroll", display: "block" }}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Create multiple users
          </Typography>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <BulkUserCSVReader funds={funds} />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default BulkAddUserModal;
