import * as Yup from "yup";
import YupPassword from "yup-password";

import { Formik, FormikProps } from "formik";
// material
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
// component

import FormInput from "../../../components/FormInput";
import { InputTypes } from "../../../types/InputTypes";
// ----------------------------------------------------------------------
// STATE MANAGEMENT
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUpdatePassword } from "@/hooks/useAuth";
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

YupPassword(Yup);

export default function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  console.log("reset pw form");

  const updatePassword = useUpdatePassword();

  const { isPending: updatingPassword } = updatePassword;

  const ResetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    code: Yup.number().required("Reset Code is required"),
    password: Yup.string().password().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });

  const code = searchParams.get("code");
  const email = searchParams.get("email");

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Forgot Password
      </Typography>

      <Typography sx={{ color: "text.secondary", mb: 5 }}>
        Please enter your email address associated with your account. A password
        reset code will be sent to email.
      </Typography>
      {/* <Formik
        initialValues={{
          email: email,
          code: code,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={ResetSchema}
        onSubmit={async (values: any) => {
          if (code && email) {
            const passwordUpdated = await updatePassword.mutateAsync({
              code: code,
              email: email,
              password: values.password,
            });
            console.log("reached", passwordUpdated);
            if (passwordUpdated) {
              navigate("/login");
              toast.success("Password changed");
            }
          }
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit } = props;

          return (
            <>
              <form onSubmit={handleSubmit} id="login">
                <Grid container>
                  <FormInput
                    id="email"
                    label="Email"
                    fieldType={InputTypes.STRING}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                    disabled={true}
                  />
                  <FormInput
                    id="code"
                    label="Reset Code"
                    fieldType={InputTypes.NUMBER}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                    disabled={true}
                  />
                  <Typography sx={{ color: "text.secondary", my: 2 }}>
                    Please enter and confirm your new password.
                  </Typography>
                  <FormInput
                    id="password"
                    label="Password"
                    fieldType={InputTypes.PASSWORD}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                  />
                  <FormInput
                    id="confirmPassword"
                    label="Confirm Password"
                    fieldType={InputTypes.PASSWORD}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                  />
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {updatingPassword ? (
                    <Grid container justifyContent="center" direction="row">
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ mx: 1, my: 2 }}
                    >
                      Reset Password
                    </Button>
                  )}
                  <Grid container justifyContent="flex-start" direction="row">
                    <Link to="/login">
                      <Typography
                        sx={{ py: 2, mx: 1, color: "primary.main" }}
                        align="right"
                      >
                        &lt;- Return to Login.
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik> */}
    </>
  );
}
