import { Order } from "../types/GeneralTypes";


// Removes all special characters in search String
export function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// Sort Comparator
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

// Determine sort function by direction
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function formatListAsString(inputList: string[], seperator = ', ') {
    return inputList.join(seperator)
}

export const ensureArray = <T>(input: T | T[] | undefined): T[] => {
    if (input === undefined) {
        return [];
    }

    return Array.isArray(input) ? input.filter(item => item !== undefined) : [input];
};

export const formatHeaderName = ({ headerName }: { headerName: string }) => {
    if (headerName === "memorandums" || headerName === "fund_reports" || headerName === "wholesale_reports") {
        return "File History"
    } else {
        const words = headerName
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
        const formattedHeader = words.join(" ");
        return formattedHeader;
    }

};

export const generateRandomNumbers = (count: number, total: number, minValue: number): number[] => {
    // Generate an array of random numbers between minValue and total/count
    const randomNumbers: number[] = Array.from({ length: count }, () => {
        return Math.floor(Math.random() * (total / count - minValue)) + minValue;
    });

    // Calculate the sum of the random numbers
    let sum = randomNumbers.reduce((acc, curr) => acc + curr, 0);

    // Calculate the difference between the total and the sum of the random numbers
    let difference = total - sum;

    // Determine the number of iterations for adjustment
    const iterations = Math.min(count, difference);

    // Adjust random numbers
    for (let i = 0; i < iterations; i++) {
        // Select a random index
        const index = Math.floor(Math.random() * count);

        // Adjust the random number at the selected index
        randomNumbers[index]++;

        // Update the sum and difference
        sum++;
        difference--;
    }

    return randomNumbers;
}

export const generateRandomNumbersForStockLineGraph = () => {
    // Generate random start value between 1 and 4
    const initialValue = Math.floor(Math.random() * 4) + 1;

    // Generate random end value between 10 and 15
    const endValue = Math.floor(Math.random() * 6) + 10;

    const result = [];
    let currentValue = initialValue;
    let count = 0;

    // Loop until we reach the end value or generate 6 numbers
    while (currentValue <= endValue && count < 6) {
        // Generate a random step between -3 and 3
        let step = Math.floor(Math.random() * 7) - 3;

        // Ensure that the next value stays within bounds
        let nextValue = currentValue + step;
        if (nextValue < currentValue && nextValue >= initialValue) {
            // If step is negative and nextValue is greater than or equal to initialValue
            currentValue = nextValue;
            result.push(currentValue);
            count++;
        } else if (nextValue >= currentValue && nextValue <= endValue) {
            // If step is positive and nextValue is less than or equal to endValue
            currentValue = nextValue;
            result.push(currentValue);
            count++;
        }
    }

    return result;
}


