import Spinner from "@/components/Spinner";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
} from "@mui/material";
import { CreatedAccountStatus } from "../BulkUserCSVReader";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";

interface BulkAddUserModalProps {
  open: boolean;
  handleClose: any;
  completedCount: number;
  totalAccountsToCreate: number;
  createdAccountsStatus: CreatedAccountStatus[];
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const stableSort = (
  array: CreatedAccountStatus[],
  comparator: (a: CreatedAccountStatus, b: CreatedAccountStatus) => number
) => {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.el);
};

const StyledTableCell = styled(TableCell)(({ error }: { error: boolean }) => ({
  fontWeight: error ? "bold" : "normal",
  color: error ? "red" : "inherit",
}));

const AddUserStatusModal = ({
  open,
  handleClose,
  completedCount,
  totalAccountsToCreate,
  createdAccountsStatus,
}: BulkAddUserModalProps) => {
  const [orderBy, setOrderBy] = useState<string>(""); // Field to be sorted
  const [order, setOrder] = useState<"asc" | "desc">("asc"); // Sorting order ('asc' or 'desc'

  const statusReady = createdAccountsStatus.length > 0;
  const progress = (100 * completedCount) / totalAccountsToCreate + 1;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function getComparator(order: "asc" | "desc", orderBy: string) {
    return order === "desc"
      ? (a: CreatedAccountStatus, b: CreatedAccountStatus) =>
          descendingComparator(a, b, orderBy)
      : (a: CreatedAccountStatus, b: CreatedAccountStatus) =>
          -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(
    a: CreatedAccountStatus,
    b: CreatedAccountStatus,
    orderBy: string
  ) {
    if (
      b[orderBy as keyof CreatedAccountStatus]! <
      a[orderBy as keyof CreatedAccountStatus]!
    ) {
      return -1;
    }
    if (
      b[orderBy as keyof CreatedAccountStatus]! >
      a[orderBy as keyof CreatedAccountStatus]!
    ) {
      return 1;
    }
    return 0;
  }

  const sortedAccountsStatus = stableSort(
    createdAccountsStatus,
    getComparator(order, orderBy)
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        {!statusReady ? (
          <Spinner size={70} loading={!statusReady} value={progress} />
        ) : null}

        {statusReady ? (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ minWidth: 650, maxHeight: 600 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "error"}
                        direction={orderBy === "error" ? order : "desc"}
                        onClick={() => handleRequestSort("error")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Status message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAccountsStatus.map((status, index) => (
                    <TableRow key={index}>
                      <StyledTableCell error={false}>
                        {status.user}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ display: "grid", placeContent: "center" }}
                        error={false}
                      >
                        {status.error ? (
                          <CancelIcon color="error" />
                        ) : (
                          <CheckCircleIcon color="success" />
                        )}
                      </StyledTableCell>
                      <StyledTableCell error={!!status.message}>
                        {status.message || ""}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : null}
        {statusReady ? (
          <Button
            variant="contained"
            onClick={() => handleClose()}
            style={{ marginTop: "20px" }}
          >
            Close
          </Button>
        ) : null}
      </Box>
    </Modal>
  );
};

export default AddUserStatusModal;
