import { UserRoleType } from "@/hooks/useCurrentUser";
import { UserAccountRoleType } from "@/hooks/useRoles";

export const PERMISSIONS = {
  ALL_USER_ROLES: [UserRoleType.ADMIN, UserRoleType.USER],

  USER_ROLE_ONLY: [UserRoleType.USER],

  INTERNAL_ADMIN_ROLES: [UserRoleType.ADMIN],

  ALL_USER_ACCOUNT_ROLES: [
    UserAccountRoleType.ADVISOR,
    UserAccountRoleType.MANAGER,
    UserAccountRoleType.OWNER,
    UserAccountRoleType.TAX,
    UserRoleType.ADMIN,
  ],

  ACCOUNT_ADMIN_ROLES: [
    UserAccountRoleType.MANAGER,
    UserAccountRoleType.OWNER,
  ],

  ALL_ADMIN_ROLES: [
    UserAccountRoleType.MANAGER,
    UserAccountRoleType.OWNER,
    UserRoleType.ADMIN,
  ],
};
