import { MouseEventHandler } from "react";
// MUI
import { AppBar, Box, Stack, Toolbar, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
// components
import AccountPopover from "./AccountPopover";
import Logo from "../../components/Logo";
import AccountDropdown from "./AccountDropdown";

// ----------------------------------------------------------------------

export default function DashboardNavbar({
  open,
  onOpenSidebar,
}: {
  open: boolean;
  onOpenSidebar: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, pl: 0 }}
      elevation={0}
    >
      <Toolbar
        disableGutters
        sx={{
          pl: "16px",
          pr: "16px",
        }}
      >
        <IconButton onClick={onOpenSidebar} sx={{ mr: 0, color: "white" }}>
          <MenuIcon />
        </IconButton>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <AccountDropdown />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
