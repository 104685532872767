import { forwardRef, ReactElement } from "react";
import NumberFormat, { NumberFormatProps } from 'react-number-format';
// Local Imports
import { CurrencyFieldProps, InputTypes } from "../../../types/InputTypes";
import { handleFormValueChange } from "../inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import { 
    Grid, 
    IconButton, 
    InputAdornment, 
    TextField, 
    TextFieldProps, 
    Theme, 
    Tooltip 
} from "@mui/material";
// component
import Iconify from "../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

// Currency Masking
interface NumberFormatCustomProps extends NumberFormatProps {
    ref: (instance: NumberFormat<any> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

// Number Formatter
const NumberFormatCustom = forwardRef(function NumberFormatCustom(
    props: NumberFormatCustomProps,
    ref
): ReactElement {
    const { onChange, ...other } = props;

    return (
        <NumberFormat 
            {...other} 
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />)
})

// Number Format Props
NumberFormatCustom.defaultProps = {
    thousandSeparator: true,
    fixedDecimalScale: false,
    decimalScale: 2,
    prefix: '$'
}

/**
 * Currency Input Field
 * Masks number input to include decimals, commas and '$' prefix
 */
export default function CurrencyField(props: CurrencyFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        layout = { xs: 12, md: 12, lg: 12 },
        size,
        variant,
        noUnderline,
        errorInIcon,
        value,
        numFormatProps
    } = props;

    const defaultProps: TextFieldProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        onBlur: props.handleBlur,
        size,
        variant
    }

    if (!errorInIcon) {
        defaultProps.helperText = helperText;
    }

    // Info and Tooltips
    if (info) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={info} placement='right'>
                        <IconButton edge="end" size={size}>
                            <Iconify icon={'eva:question-mark-circle-outline'} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            ),
        }
    }
    
    // If error display in Icon
    if (errorInIcon && error) {
        defaultProps.InputProps = {
            endAdornment: (
                <InputAdornment position="end">
                    <Tooltip title={helperText} placement='right'>
                        <IconButton edge="end" sx={{pl: 0}} size={size}>
                            <Iconify icon={'eva:alert-circle-outline'} sx={{color: 'red'}} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            )
        }
    }

    if (variant === 'standard' && noUnderline) {
        defaultProps.InputProps = {
            ...defaultProps.InputProps,
            disableUnderline: true
        }
    }

    // @ts-ignore
    NumberFormatCustom.defaultProps = {
        ...numFormatProps,
        ...NumberFormatCustom.defaultProps,
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <TextField
                {...defaultProps}
                sx={{ width: '100%'}}
                onChange={handleFormValueChange(id, InputTypes.CURRENCY, handleChange)}
                inputProps={{style: {textAlign: 'right'}}}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    ...defaultProps.InputProps
                }}
            />
        </GridStyle>
    )
}