import { useFundsQuery } from "@/hooks/useFunds";
import { InputTypes } from "@/types/InputTypes";
import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import FormInput from "../FormInput";
import Spinner from "../Spinner";

interface InvestModalProps {
  open: boolean;
  handleClose: any;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InvestModal = ({ handleClose, open }: InvestModalProps) => {
  const initialValues = { fund: "" };
  const { isFetching: fetchingFunds, data: funds } = useFundsQuery({});
  const formattedFunds =
    funds &&
    funds.map((fund) => ({
      label: `${fund.name} (${fund.ticker})`,
      value: fund.id,
    }));
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Invest Online
        </Typography>
        {fetchingFunds ? (
          <Spinner loading={fetchingFunds} />
        ) : formattedFunds ? (
          <Formik initialValues={initialValues} onSubmit={(values: any) => {}}>
            {(props: FormikProps<any>) => {
              const { handleSubmit, values } = props;

              return (
                <>
                  <Grid container xs={8}>
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={3} style={{ width: 700, marginLeft: -8 }}>
                        <FormInput
                          id="fund"
                          label="Select fund"
                          fieldType={InputTypes.SELECTION}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          values={formattedFunds}
                        />
                      </Stack>
                      <Button
                        size="large"
                        disabled={!values.fund}
                        onClick={() => {
                          window.open("https://apply.automic.com.au/MDIF");
                          handleClose();
                        }} //update to dynamic url when wholesale fund Automic links available.
                        style={{ maxWidth: 296, minHeight: 30, marginTop: 20 }}
                        type="submit"
                        variant="contained"
                      >
                        Invest now
                      </Button>
                    </form>
                  </Grid>
                </>
              );
            }}
          </Formik>
        ) : null}
      </Box>
    </Modal>
  );
};

export default InvestModal;
