import { Button, Paper } from "@mui/material";
import React from "react";
import ReactCarousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";

interface ItemProps {
  item: {
    title: string;
    description: string;
    thumbnail: string;
    url: string;
  };
}

const Item: React.FC<ItemProps> = ({ item }) => {
  return (
    <Paper
      sx={{
        padding: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>{item.title}</h1>
      <p style={{ minHeight: 80, fontSize: 18 }}>{item.description}</p>
      <div style={{ width: 400, height: 250, marginBottom: 30 }}>
        <img
          style={{ height: "100%", width: "100%" }}
          src={`/static/newCarousel/${item.thumbnail}`}
          alt={item.thumbnail}
        />
      </div>
      <Button variant="contained" disableRipple={true}>
        <Link
          style={{ color: "white", textDecoration: "none" }}
          to={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </Link>
      </Button>

      {/* <Button className="CheckButton">Check it out!</Button> */}
    </Paper>
  );
};

const Carousel = () => {
  var items = [
    {
      title: "Four reasons private debt may benefit income investors",
      description:
        "Australian private debt is among the few asset classes that offer both capital preservation and attractive risk-adjusted returns.",
      thumbnail: "FourReasons.webp",
      url: "https://metrics.com.au/news/four-reasons-private-debt-can-benefit-income-investors/",
    },
    {
      title:
        "Why private markets investing will continue to gain traction in 2024",
      description:
        "For many years, the private debt market was inaccessible to retail investors, but this is no longer the case.",

      thumbnail: "PrivateMarketsTraction.webp",
      url: "https://metrics.com.au/news/why-private-markets-investing-will-continue-to-gain-traction-in-2024/",
    },
    {
      title: "Why private debt should be on investors’ radars in 2024",
      description:
        "An attractive yield and low capital volatility mean this fast-growing asset class is worth a closer look",
      thumbnail: "UltimateInvestingGuide.webp",
      url: "https://metrics.com.au/news/why-private-debt-should-be-on-investors-radars-in-2024/",
    },
    {
      title: "Relative Return Podcast: The key to successful lending",
      description:
        "In this episode of Relative Return, host Phil Tarrant speaks with Metrics Managing Partner, Andrew Lockhart, about his view on the current state of the lending market and the lessons he’s learnt from his banking past..",
      thumbnail: "RelativeReturnPodcast.webp",
      url: "https://metrics.com.au/news/relative-return-podcast-the-key-to-successful-lending/",
    },
    {
      title: "The opportunity and outlook for Australian private debt",
      description:
        "Andrew Lockhart, Managing Partner at Metrics Credit Partners, provided insights into the opportunity and outlook for Australian private debt",
      thumbnail: "OutlookAustralianPrivateDebt.webp",
      url: "https://metrics.com.au/news/the-opportunity-and-outlook-for-australian-private-debt/",
    },
    {
      title: "How Metrics’ loan assets are valued",
      description:
        "The price you pay is almost as important as the asset you’re buying. Here’s how Metrics determines asset value.",
      thumbnail: "MetricsLoanAssetValuation.webp",
      url: "https://metrics.com.au/news/how-metrics-loan-assets-are-valued/",
    },
  ];

  return (
    <ReactCarousel animation="fade" navButtonsAlwaysVisible={false}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </ReactCarousel>
  );
};

export default Carousel;
