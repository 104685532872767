import { Memorandum, PaginationMemorandum } from "@/hooks/useMemorandums";
import {
  Box,
  Collapse,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";

interface FileVersionHistoryProps {
  open: boolean;
  memorandums: PaginationMemorandum[];
  handleOpenFile: () => void;
}

const fullDate = (upload_date: string) => {
  const fullDate = format(new Date(upload_date), "dd-MM-yyyy HH:mm");
  return (
    <Tooltip style={{ cursor: "pointer" }} title={fullDate}>
      <span>{upload_date && format(new Date(upload_date), "dd-MM-yyyy")}</span>
    </Tooltip>
  );
};

const FileVersionHistory = ({
  open,
  memorandums,
  handleOpenFile,
}: FileVersionHistoryProps) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 10, paddingTop: 10 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              FIle History
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>No.</TableCell>
                  <TableCell style={{ width: "20%" }}>Filename</TableCell>
                  <TableCell style={{ width: "20%" }}>Upload Date</TableCell>
                  <TableCell style={{ width: "20%" }}>Uploader</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {memorandums?.map((memorandum, index) => (
                  <TableRow
                    key={memorandum.file_name.toString() + index.toString()}
                  >
                    <TableCell component="th" scope="row">
                      {memorandums.length - index}
                    </TableCell>
                    <TableCell>
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenFile()}
                      >
                        {memorandum.file_name}
                      </Link>
                    </TableCell>
                    <TableCell>{fullDate(memorandum.upload_date)}</TableCell>
                    <TableCell>{memorandum.uploader}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default FileVersionHistory;
