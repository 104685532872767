// ----------------------------------------------------------------------
import { Theme } from "@mui/system";

export default function Paper(theme: Theme) {
    return {
      MuiPaper: {
        defaultProps: {
          elevation: 2
        },
  
        styleOverrides: {
          root: {
          }
        }
      }
    };
  }
  