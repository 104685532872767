import { usePaginationAccountsQuery } from "@/hooks/useAccounts";
import { usePaginationFundsQuery } from "@/hooks/useFunds";
import { usePaginationMemorandumsQuery } from "@/hooks/useMemorandums";
import { usePaginationFundReportsQuery, usePaginationWholesaleReportsQuery } from "@/hooks/useReports";
import { usePaginationUsersQuery } from "@/hooks/useUsers";
import { Filter, Model, ModelFilter } from "@/types/GeneralTypes";


export const determinePrintableModelName = (model: Model) => {
    if (model === Model.MEMORANDUMS) {
        return "Investor Memorandum";
    } else if (model === Model.REPORTS) {
        return "Wholesale Report";
    } else if (model === Model.FUND_REPORTS) {
        return "Fund Report";
    } else if (model === Model.ACCOUNTS) {
        return "Account";
    } else if (model === Model.FUNDS) {
        return "Fund";
    } else {
        return "";
    }
};

export const determinePrintableFilterName = (filter: string) => {
    if (filter === Filter.START_PERIOD) {
        return "start period";
    } else if (filter === Filter.END_PERIOD) {
        return "end period";
    } else if (filter === Filter.START_UPLOAD_DATE) {
        return "start upload date";
    } else if (filter === Filter.END_UPLOAD_DATE) {
        return "end upload date";
    } else {
        return "";
    }
};

export const determineModelHeaders = (model: Model) => {
    if (model === Model.MEMORANDUMS) {
        return [
            "fund_name",
            "file_name",
            "upload_date",
            "uploader",
            "fund_id",
            "memorandums"
        ];
    } else if (model === Model.REPORTS) {
        return ["account_name", "report_type", "file_name", "period", "upload_date", "uploader", "wholesale_reports"];
    } else if (model === Model.FUND_REPORTS) {
        return ["fund_name", "file_name", "period", "upload_date", "uploader", "fund_reports"];
    } else if (model === Model.ACCOUNTS) {
        return ["name"];
    } else if (model === Model.FUNDS) {
        return ["fund_name", "ticker", "fund_id"];
    } else if (model === Model.USERS) {
        return ["name", "email", "user_id", "action"];
    } else {
        return [];
    }
};

export const determineModelModalData = (model: Model) => {
    if (model === Model.MEMORANDUMS) {
    } else if (model === Model.REPORTS) {
    } else if (model === Model.FUND_REPORTS) {
        return {};
    } else if (model === Model.ACCOUNTS) {
    } else if (model === Model.FUNDS) {
    } else {
    }
};

export const determineModelFilters = (model: Model) => {
    if (model === Model.MEMORANDUMS) {
        return [ModelFilter.FUND, Filter.START_UPLOAD_DATE, Filter.END_UPLOAD_DATE]
    } else if (model === Model.REPORTS) {
        return [ModelFilter.ACCOUNT, ModelFilter.REPORT_TYPE, Filter.START_PERIOD, Filter.END_PERIOD, Filter.START_UPLOAD_DATE, Filter.END_UPLOAD_DATE]
    } else if (model === Model.FUND_REPORTS) {
        return [ModelFilter.FUND, Filter.START_PERIOD, Filter.END_PERIOD, Filter.START_UPLOAD_DATE, Filter.END_UPLOAD_DATE];
    } else if (model === Model.ACCOUNTS) {
        return [ModelFilter.ACCOUNT]
    } else if (model === Model.FUNDS) {
        return [ModelFilter.FUND]
    } else {
        return []
    }
};

export const modelToQueryHookMap: Record<Model, any> = {
    [Model.ACCOUNTS]: usePaginationAccountsQuery,
    [Model.FUNDS]: usePaginationFundsQuery,
    [Model.FUND_REPORTS]: usePaginationFundReportsQuery,
    [Model.MEMORANDUMS]: usePaginationMemorandumsQuery,
    [Model.REPORTS]: usePaginationWholesaleReportsQuery,
    [Model.ROLES]: null,

    [Model.USERS]: usePaginationUsersQuery,

    [Model.USER_ACCOUNTS]: null,
    // Add mappings for other models as needed
};

