import TableRowsLoader from "@/components/PaginationTable/PaginationBody/RowsLoader/RowsLoader";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Model, RecordType } from "@/types/GeneralTypes";
import { apiGetRequest } from "@/utils/apiUtils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button,
  Link,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { toast } from "react-toastify";
import FileVersionHistory from "@/components/PaginationTable/PaginationBody/FileVersionHistory";
import { PaginationMemorandum } from "@/hooks/useMemorandums";
import { USERS_PER_PAGE } from "@/hooks/useUsers";
import React, { useState } from "react";

interface PaginationBodyProps<T extends RecordType> {
  tableBodyData: T[];
  headers: string[];
  model: Model;
  loading: boolean;
  numberSkeletonFields: number;
  handleOpenEditUserModal?: ({ userId }: { userId: string }) => void;
}

interface getMemorandumUrlProps {
  model: Model.MEMORANDUMS;
  fundId: string;
}

interface getReportUrlProps {
  model: Model.REPORTS | Model.FUND_REPORTS;
  fileKey: string;
  userId: string;
}

type getUrlProps = getMemorandumUrlProps | getReportUrlProps;

const handleDownloadClick = async (props: getUrlProps) => {
  // Trigger download when the URL is available
  let query = "";
  if (props.model === Model.MEMORANDUMS) {
    query = `newMemorandums?fundId=${props.fundId}`;
  } else if (props.model === Model.REPORTS) {
    query = `newReports?fileKey=${props.fileKey}&userId=${props.userId}`;
  } else if (props.model === Model.FUND_REPORTS) {
    query = `newReports?fileKey=${props.fileKey}&userId=${props.userId}&fundReports=true`;
  }
  try {
    if (query) {
      const url = await apiGetRequest<string>({
        query,
      });
      if (url) {
        window.open(url, "_blank");
      }
    }
  } catch (error) {
    toast.error(`Error fetching ${props.model} `);
  }
};

const handleClick = async ({ tableBodyData, model, userId }: any) => {
  const isMemo = model === Model.MEMORANDUMS;
  const isWholesaleReport = model === Model.REPORTS;
  const isFundReport = model === Model.FUND_REPORTS;
  if (isMemo) {
    await handleDownloadClick({
      model: Model.MEMORANDUMS,
      fundId: tableBodyData.fund_id,
    });
  } else if (isWholesaleReport) {
    await handleDownloadClick({
      model: Model.REPORTS,
      fileKey: tableBodyData.file_name,
      userId: userId,
    });
  } else if (isFundReport) {
    await handleDownloadClick({
      model: Model.FUND_REPORTS,
      fileKey: tableBodyData.file_name,
      userId: userId,
    });
  }
};

const PaginationBody = <T extends RecordType>({
  tableBodyData,
  headers,
  model,
  loading,
  numberSkeletonFields,
  handleOpenEditUserModal,
}: PaginationBodyProps<T>) => {
  //const useMemorandumUrlAdminQuery
  const user = useCurrentUser();
  const userId = user?.id || "";

  const noResults = tableBodyData && tableBodyData.length === 0;

  const fileHistoryOpen = Array(USERS_PER_PAGE)
    .fill(5)
    .map((_) => ({ open: false }));
  const [fileHistoryCollapsibleExpanded, setfileHistoryCollapsibleExpanded] =
    useState(fileHistoryOpen);

  const toggleOpenStateAtIndex = (index: number) => {
    setfileHistoryCollapsibleExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], open: !newState[index].open };
      return newState;
    });
  };

  const formattedTableData =
    tableBodyData && model === Model.USERS
      ? tableBodyData.map((data) => ({ ...data, action: true }))
      : tableBodyData;

  const determineValue = <T extends RecordType>({
    header,
    tableRow,
    rowIndex,
  }: {
    header: string;
    tableRow: T;
    rowIndex: number;
  }) => {
    if (
      header === "action" &&
      model === Model.USERS &&
      handleOpenEditUserModal
    ) {
      return (
        <Button
          variant="outlined"
          onClick={() =>
            handleOpenEditUserModal({ userId: tableRow["user_id"] })
          }
        >
          <BorderColorIcon /> <span>Edit User</span>
        </Button>
      );
    } else if (
      header &&
      (header.includes("date") || header.includes("period"))
    ) {
      const fullDate =
        tableRow[header] &&
        format(new Date(tableRow[header]), "dd-MM-yyyy HH:mm");
      return (
        <Tooltip style={{ cursor: "pointer" }} title={fullDate}>
          <span>
            {tableRow[header] &&
              format(new Date(tableRow[header]), "dd-MM-yyyy")}
          </span>
        </Tooltip>
      );
    } else if (header && header.includes("file_name")) {
      return (
        <Link
          style={{ cursor: "pointer" }}
          onClick={async () =>
            await handleClick({
              model,
              tableBodyData: tableRow,
              header,
              userId,
            })
          }
        >
          {tableRow[header]}
        </Link>
      );
    } else if (header.includes("most_recent")) {
      return tableRow[header] ? <CheckCircleIcon /> : null;
    } else if (
      header.includes("memorandums") ||
      header.includes("fund_reports") ||
      header.includes("wholesale_reports")
    ) {
      const showFileHistory = fileHistoryCollapsibleExpanded[rowIndex].open;

      return (
        <Button onClick={() => toggleOpenStateAtIndex(rowIndex)}>
          {showFileHistory ? (
            <KeyboardArrowUpIcon sx={{ color: "gray" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "gray" }} />
          )}
        </Button>
      );
    } else {
      return tableRow[header];
    }
  };

  const headersToShow = headers.filter(
    (headerName) => !headerName.includes("id")
  );
  const memorandums =
    tableBodyData &&
    tableBodyData
      .map(
        (tableRow) =>
          tableRow?.memorandums ||
          tableRow?.fund_reports ||
          tableRow?.wholesale_reports
      )
      .filter((memorandum) => memorandum !== undefined);

  // If all values are undefined, return null instead of an array of undefined values
  const formattedMemorandums: PaginationMemorandum[][] | null =
    memorandums && memorandums.length > 0 ? memorandums : null;

  return (
    <TableBody>
      {loading ? (
        <TableRowsLoader numberHeaders={numberSkeletonFields} numberRows={5} />
      ) : noResults ? (
        <span style={{ padding: 20, marginTop: 20, fontSize: 20 }}>
          No results found
        </span>
      ) : (
        formattedTableData.map((tableRow, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <TableRow>
              {headersToShow.map((header, colIndex) => (
                <TableCell key={colIndex}>
                  {determineValue({ header, tableRow, rowIndex })}
                </TableCell>
              ))}
            </TableRow>
            {formattedMemorandums ? (
              <FileVersionHistory
                memorandums={formattedMemorandums[rowIndex]}
                open={fileHistoryCollapsibleExpanded[rowIndex].open}
                handleOpenFile={async () =>
                  await handleClick({
                    model,
                    tableBodyData: tableRow,
                    header: "file_name",
                    userId,
                  })
                }
              />
            ) : null}
          </React.Fragment>
        ))
      )}
    </TableBody>
  );
};

export default PaginationBody;
