import { Button, Grid, Stack } from "@mui/material";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { Formik, FormikProps } from "formik";
import {
  useMemorandumUrlAdminQuery,
  useUploadInvestorMemorandum,
} from "@/hooks/useMemorandums";
import { LoadingButton } from "@mui/lab";
import { Fund } from "@/hooks/useFunds";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import ExistingFileUploadWarningModal from "../ExistingFileUploadWarningModal";
import { Model } from "@/types/GeneralTypes";

interface UploadMemoProps {
  funds: Fund[];
  setAddMemoModalOpen: (modalOpen: boolean) => void;
  model: Model;
}

const UploadMemo = ({ funds, setAddMemoModalOpen, model }: UploadMemoProps) => {
  const initialValues = { fund: "", file: [] };
  const formattedFunds =
    funds &&
    funds.map((fund) => ({
      label: `${fund.name} (${fund.ticker})`,
      value: fund.id,
    }));

  const [uploadFundId, setUploadFundId] = useState("");
  const [existingFileWarningModalOpen, setExistingFileWarningModalOpen] =
    useState(false);

  const uploadInvestorMemorandum = useUploadInvestorMemorandum();

  const queryClient = useQueryClient();
  const {
    isPending: uploadingInvestorMemorandum,
    isSuccess: memorandumUploaded,
  } = uploadInvestorMemorandum;

  useEffect(() => {
    if (memorandumUploaded) {
      queryClient.invalidateQueries({ queryKey: ["memorandumsByPage"] });
      setAddMemoModalOpen(false);
    }
  }, [memorandumUploaded]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: any, { resetForm }) => {
        const file = values.file[0];
        const fundId = values.fund;
        //queryClient.invalidateQueries({ queryKey: ["memorandumUploadUrl"] });
        const fileData = new FormData();
        fileData.append("file", file);

        uploadInvestorMemorandum.mutate({
          fileData,
          fundId,
          fileKey: file.name,
        });
        resetForm();
      }}
    >
      {(props: FormikProps<any>) => {
        const { handleSubmit, values } = props;

        const file = values.file;
        const fundId = values.fund;
        const fundTicker =
          fundId && funds?.find((fund) => fund.id === fundId)?.ticker;

        const disabledButton =
          !file || file.length !== 1 || !fundId || uploadingInvestorMemorandum;
        return (
          <>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="fund"
                    label="Select fund"
                    fieldType={InputTypes.SELECTION}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    values={formattedFunds}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="file"
                    label="Upload investor memorandum"
                    fieldType={InputTypes.FILE}
                    multiple={false}
                    info={`Upload memorandum to selected fund: ${fundTicker}`}
                    fileType={[".pdf"]}
                  />
                </Stack>
                <LoadingButton
                  loading={uploadingInvestorMemorandum}
                  size="large"
                  style={{
                    maxWidth: 296,
                    marginLeft: 6,
                    minHeight: 30,
                    marginTop: 30,
                  }}
                  type="submit"
                  disabled={disabledButton}
                  variant="contained"
                >
                  Upload file
                </LoadingButton>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    setUploadFundId(values.fund);
                    setExistingFileWarningModalOpen(true);
                  }}
                >
                  Hello
                </Button> */}
                <ExistingFileUploadWarningModal
                  model={model}
                  open={existingFileWarningModalOpen}
                  setModalOpen={setExistingFileWarningModalOpen}
                />
              </form>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default UploadMemo;
