import { Button, Grid, Stack } from "@mui/material";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { Formik, FormikProps } from "formik";
import Spinner from "../../../../components/Spinner";
import { formatDate } from "../../../../utils/DateUtils";
import { Account } from "@/hooks/useAccounts";
import {
  ReportCategories,
  ReportType,
  useUploadFundReport,
  useUploadWholesaleReport,
} from "@/hooks/useReports";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Model } from "@/types/GeneralTypes";
import ExistingFileUploadWarningModal from "../ExistingFileUploadWarningModal";
import { LoadingButton } from "@mui/lab";
import { Fund } from "@/hooks/useFunds";

interface UploadFundReportProps {
  funds: Fund[];
  setAddReportModalOpen: (modalOpen: boolean) => void;
  model: Model;
}

const UploadFundReport = ({
  funds,
  model,
  setAddReportModalOpen,
}: UploadFundReportProps) => {
  const initialValues = { file: [], report: "", account: "", period: null };
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  const uploadReport = useUploadFundReport();

  const { isPending: uploadingReport, isSuccess: reportUploaded } =
    uploadReport;

  const [existingFileWarningModalOpen, setExistingFileWarningModalOpen] =
    useState(false);

  useEffect(() => {
    if (reportUploaded) {
      queryClient.invalidateQueries({ queryKey: ["fundReportsByPage"] });
      setAddReportModalOpen(false);
    }
  }, [reportUploaded]);

  const formattedFunds = funds.map((fund) => ({
    value: fund.id,
    label: `${fund.name} (${fund.ticker})`,
  }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: any, { resetForm }) => {
        const file = values.file[0];
        const fund = values.fund;
        const period = values.period;
        const firstMinuteOfReportMonth = period
          ? new Date(period.getFullYear(), period.getMonth(), 1, 0, 0, 0, 0)
          : null;
        console.log("period", period, firstMinuteOfReportMonth);
        // const showYearly =
        //   reportTypeName === ReportCategories.FINANCIAL_YEAR ||
        //   reportTypeName === ReportCategories.TAX;
        const fileData = new FormData();
        fileData.append("file", file);

        //queryClient.invalidateQueries({ queryKey: ["reportUploadUrl"] });
        if (firstMinuteOfReportMonth) {
          uploadReport.mutate({
            fileData,
            fundId: fund,
            fileKey: file.name,
            period: firstMinuteOfReportMonth.toString(),
            userId: user?.id || null,
          });
          resetForm();
        }
      }}
    >
      {(props: FormikProps<any>) => {
        const { handleSubmit, values } = props;
        const file = values.file;
        // const showYearly =
        //   reportTypeName === ReportCategories.FINANCIAL_YEAR ||
        //   reportTypeName === ReportCategories.TAX;

        const showMonthly = true;
        const period = values.period;
        const fundId = values.fund;
        const disabledButton =
          !file || file.length !== 1 || !fundId || !period || uploadingReport;
        return (
          <>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="fund"
                    label="Select fund"
                    fieldType={InputTypes.SELECTION}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    values={formattedFunds}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="period"
                    label="Period"
                    fieldType={InputTypes.DATE}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    views={showMonthly ? ["month", "year"] : ["year"]}
                    inputFormat={showMonthly ? "MM/yyyy" : "yyyy"}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="file"
                    label="Upload fund report"
                    fieldType={InputTypes.FILE}
                    multiple={false}
                    info={`Upload fund report`}
                    fileType={[".pdf"]}
                  />
                </Stack>
                <LoadingButton
                  size="large"
                  style={{
                    maxWidth: 296,
                    marginLeft: 6,
                    minHeight: 30,
                    marginTop: 30,
                  }}
                  loading={uploadingReport}
                  type="submit"
                  disabled={disabledButton}
                  variant="contained"
                >
                  Upload file
                </LoadingButton>
                {/* <Button
                  variant="contained"
                  onClick={() => setExistingFileWarningModalOpen(true)}
                >
                  Hello
                </Button>
                <ExistingFileUploadWarningModal
                  model={model}
                  open={existingFileWarningModalOpen}
                  setModalOpen={setExistingFileWarningModalOpen}
                /> */}
              </form>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default UploadFundReport;
