import { Field, FormikProps, getIn } from "formik";
// Local Imports
import { FormInputProps } from "../../types/InputTypes";
// Components
import InputField from "../InputField";

/**
 * 
 * Component used for fast building of Forms, used to insert quickly different types of form fields
 * The Form Input component wraps the Input field to integrate Formik properties to each field
 * Handling value changes, errors and touch/blur
 * 
 */
export default function FormInput(props: FormInputProps) {
    const { id, label, placeholder, info, disabled, layout, size = 'medium', variant = 'outlined', noUnderline = false, noLabel = false, errorInIcon = false, fieldType, minDate, maxDate, mask, values, inputFormat, multiple, numFormatProps, blurFunction, views, fileType, onChange } = props;

    return (
        <Field name={id}>
            {({ field, form }: { field: any, form: FormikProps<any> }) => {
                const { handleBlur, setFieldError, setFieldTouched, setFieldValue } = form;

                // Retrieve and populates helperText if a field has been touched and if a validation error applies
                const touched = getIn(form.touched, field.name)
                const helperText = touched ? getIn(form.errors, field.name) : '';
                const error = (!(!helperText) && helperText !== '');
                //console.log('err', error)
                const onBlur = (e: React.FocusEvent<any>): void => {
                    if (blurFunction) blurFunction();
                    handleBlur(e);
                }
                return (
                    <InputField
                        // Default values
                        id={field.name}
                        label={label}
                        placeholder={placeholder}
                        info={info}
                        disabled={disabled}
                        layout={layout}
                        size={size}
                        variant={variant}
                        noUnderline={noUnderline}
                        noLabel={noLabel}
                        errorInIcon={errorInIcon}

                        value={getIn(form.values, field.name)}

                        // Error information values
                        helperText={helperText}
                        touched={touched}
                        error={error}

                        // Field Properties
                        fieldType={fieldType}
                        views={views}
                        inputFormat={inputFormat}
                        minDate={minDate}
                        maxDate={maxDate}
                        mask={mask}
                        values={values}
                        multiple={multiple}
                        numFormatProps={numFormatProps}

                        // Field Functions
                        handleChange={(value: any, field: string) => setFieldValue(field, value)}
                        handleBlur={onBlur}
                        setTouched={setFieldTouched}
                        setError={setFieldError}
                        onChange={onChange}

                        //File upload value
                        fileType={fileType}
                    />
                )
            }}
        </Field>
    )
}