import { Button, Grid, Stack, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";

import Page from "../../../components/Page";
import { useState } from "react";
import { InputTypes } from "../../../types/InputTypes";
import FormInput from "../../../components/FormInput";
import Spinner from "../../../components/Spinner";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";
import MemorandumViewer from "./components/MemorandumViewer";
import { UserRoleType, useCurrentUser } from "@/hooks/useCurrentUser";
import {
  useFundsWithViewableMemorandumsByUserIdQuery,
  useFundsWithMemorandumsQuery,
  useInvestorMemorandumUrlQueryByUserId,
  useMemorandumUrlQuery,
} from "@/hooks/useMemorandums";
import PdfViewer from "@/components/PdfViewer";

const Memorandums = () => {
  const initialValues = { fund: "" };
  const [fund, setFund] = useState(null);

  const queryClient = useQueryClient();

  const user = useCurrentUser();

  const userId = user?.id || null;

  let { accountId } = useParams();
  const currentAccount = accountId || null;
  const isAdmin = user?.role === UserRoleType.ADMIN;

  const fetchMemorandumQuery = currentAccount
    ? useFundsWithMemorandumsQuery({ currentAccount })
    : useFundsWithViewableMemorandumsByUserIdQuery({
        userId: (user && user.id) || null,
      });

  const {
    isFetching: fetchingFundsWithMemorandums,
    data: investorMemorandums,
  } = fetchMemorandumQuery;

  const getMemorandumUrlQuery = currentAccount
    ? useMemorandumUrlQuery({ currentAccount, fundId: fund })
    : useInvestorMemorandumUrlQueryByUserId({ fundId: fund, userId });

  const { isFetching: fetchingMemorandum, data: memorandum } =
    getMemorandumUrlQuery;

  const formattedFunds =
    investorMemorandums &&
    investorMemorandums.map((fund) => ({
      label: `${fund.name} (${fund.ticker})`,
      value: fund.fund_id,
    }));
  //console.log("memo url", memorandum);
  return (
    <Page title="Investor Memorandums">
      <Typography
        id="target"
        variant="h4"
        sx={{ mb: 5, color: "primary.main" }}
      >
        Investor Memorandums
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: any) => {
          queryClient.invalidateQueries({ queryKey: ["memorandum"] });
          setFund(values.fund);
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit, values } = props;
          return (
            <>
              <Grid item xs={8}>
                {fetchingFundsWithMemorandums ? (
                  <Spinner
                    align="left"
                    loading={fetchingFundsWithMemorandums}
                    size={40}
                  />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Stack spacing={3} style={{ maxWidth: 400 }}>
                      <FormInput
                        id="fund"
                        label="Select fund"
                        fieldType={InputTypes.SELECTION}
                        layout={{ xs: 6, md: 6, lg: 6 }}
                        values={formattedFunds}
                      />
                    </Stack>
                    <Button
                      size="large"
                      style={{
                        maxWidth: 296,
                        marginLeft: 6,
                        minHeight: 30,
                        marginTop: 30,
                      }}
                      type="submit"
                      disabled={!values.fund}
                      variant="contained"
                    >
                      View investor memorandum
                    </Button>
                  </form>
                )}
                {memorandum ? (
                  <PdfViewer investButton={true} file={memorandum} />
                ) : null}
              </Grid>
            </>
          );
        }}
      </Formik>
    </Page>
  );
};

export default Memorandums;
