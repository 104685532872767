import { CheckboxFieldProps } from "../../../types/InputTypes";
// MUI
import { styled } from "@mui/material/styles";
import {
  Grid,
  Theme,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
// components
import Iconify from "../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

/**
 * Checkbox Input Field
 */
export default function CheckboxField(props: CheckboxFieldProps) {
  const {
    id,
    label,
    info = null,
    handleChange,
    setTouched,
    layout = { xs: 12, md: 12, lg: 12 },
    size,
    value,
    onChange,
  } = props;
  return (
    <GridStyle item {...layout}>
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            checked={value === undefined ? false : value}
            onChange={(e) => {
              onChange && onChange(e);
              handleChange(!value, id);
              setTouched(id);
            }}
            color="primary"
            size={size}
          />
        }
        label={
          <div style={{ width: 150 }}>
            {label}
            {/* Info and Tooltip */}
            {info !== null && (
              <Tooltip title={info} placement="right">
                <IconButton edge="end">
                  <Iconify icon={"eva:question-mark-circle-outline"} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
        labelPlacement="end"
      />
    </GridStyle>
  );
}
