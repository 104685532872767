import { Auth } from "aws-amplify";
import { NavigateFunction } from "react-router";

export const getApiParams = async () => {
  try {
    const session = await Auth.currentSession();
    //console.log('test', `Bearer ${session.getIdToken().getJwtToken()}`)
    return {
      headers: {
        Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
      },
    };
  } catch (error) {
    throw new Error(`${error as Error}`);
  }
};

interface apiRequestProps {
  query: string;
  body?: any;
}

export const apiGetRequest = async <T>({
  query,
}: apiRequestProps): Promise<T | undefined> => {
  try {
    console.log("query is", query);
    const apiParams = await getApiParams();
    const response = await fetch(
      `${process.env.REACT_APP_AWS_INVESTOR_PORTAL_API}/${query}`,
      { ...apiParams, method: "GET" }
    );

    if (!response.ok) {
      let message;
      const error = await response.json();
      //console.log("res", response.status, error);
      switch (response.status) {
        case 401:
          message = "Unauthenticated Access.";
          break;

        case 403:
          message = "Unauthorized Access.";
          break;

        case 404:
          break;

        case 500:
          message = "Database error";
          break;

        default:
          break;
      }

      throw new Error(`Request failed: ${message || ""}: ${error}`);
    }

    const data: T = await response.json();
    return data;
  } catch (initialError) {
    console.error("Initial fetch error:", initialError, query);
    throw initialError;
  }
};

export const apiPostRequest = async <T>({
  query,
  body
}: apiRequestProps): Promise<T | undefined> => {
  try {
    const apiParams = await getApiParams();
    const response = await fetch(
      `${process.env.REACT_APP_AWS_INVESTOR_PORTAL_API}/${query}`,
      { ...apiParams, method: "POST", body }
    );

    if (!response.ok) {
      let message;
      const error = await response.json();
      console.log("res", response.status, error);
      switch (response.status) {
        case 401:
          message = "Unauthenticated Access.";
          break;

        case 403:
          message = "Unauthorized Access.";
          break;

        case 404:
          break;

        case 500:
          message = "Database error";
          break;

        default:
          break;
      }

      throw new Error(`Request failed: ${message || ""}: ${error}`);
    }

    const data: T = await response.json();
    return data;
  } catch (initialError) {
    console.error("Initial post error:", initialError);
    throw initialError;
  }
};

export const apiGetRequestTest = async <T>({
  query,
}: apiRequestProps): Promise<T | undefined> => {
  try {
    //console.log("query is", query);
    const apiParams = await getApiParams();
    const response = await fetch(
      `${process.env.REACT_APP_AWS_INVESTOR_PORTAL_V1_API}/${query}`,
      { ...apiParams, method: "GET" }
    );

    if (!response.ok) {
      let message;
      const error = await response.json();
      //console.log("res", response.status, error);
      switch (response.status) {
        case 401:
          message = "Unauthenticated Access.";
          break;

        case 403:
          message = "Unauthorized Access.";
          break;

        case 404:
          break;

        case 500:
          message = "Database error";
          break;

        default:
          break;
      }

      throw new Error(`Request failed: ${message || ""}: ${error}`);
    }

    const data: T = await response.json();
    return data;
  } catch (initialError) {
    console.error("Initial fetch error:", initialError, query);
    throw initialError;
  }
};

export const apiPatchRequest = async <T>({
  query,
  body
}: apiRequestProps): Promise<T | undefined> => {
  try {
    const apiParams = await getApiParams();
    //console.log("huh", apiParams);
    const response = await fetch(
      `${process.env.REACT_APP_AWS_INVESTOR_PORTAL_API}/${query}`,
      { ...apiParams, method: "PATCH", body }
    );

    if (!response.ok) {
      let message;
      const error = await response.json();
      console.log("res", response.status, error);
      switch (response.status) {
        case 401:
          message = "Unauthenticated Access.";
          break;

        case 403:
          message = "Unauthorized Access.";
          break;

        case 404:
          break;

        case 500:
          message = "Database error";
          break;

        default:
          break;
      }

      throw new Error(`Request failed: ${message || ""}: ${error}`);
    }

    const data: T = await response.json();
    return data;
  } catch (initialError) {
    console.error("Initial patch error:", initialError);
    throw initialError;
  }
};

// export const apiGetRequest = async <T>({
//   query,
//   dispatch,
//   callback,
//   navigate,
// }: apiRequestProps): Promise<T | undefined> => {
//   let redirectHome = false;
//   let redirectLogin = false;
//   try {
//     const apiParams = await getApiParams();
//     console.log("huh", apiParams);
//     const response = await fetch(
//       `${process.env.REACT_APP_AWS_INVESTOR_PORTAL_API}/${query}`,
//       { ...apiParams, method: "GET" }
//     );
//     if (!response) {
//       throw new Error("API is down or unreachable.");
//     }
//     if (response.ok) {
//       const data: T = await response.json();
//       return data;
//     } else {
//       let message;
//       const error = await response.json();
//       console.log("res", response.status, error);
//       switch (response.status) {
//         case 401:
//           message = "Unauthenticated Access.";
//           redirectLogin = true;
//           break;

//         case 403:
//           message = "Unauthorized Access.";
//           redirectHome = true;
//           break;

//         case 404:
//           message = "404";
//           break;

//         default:
//           message = "Unknown error";
//           break;
//       }
//       throw new Error(`Request failed : ${message}: ${error}`);
//     }
//   } catch (err) {
//     if (err instanceof Error) {
//       console.log("err", err.message, err.name);
//       if (err.message === "No current user") {
//         redirectLogin = true;
//       }
//       callback && callback();
//       dispatch &&
//         dispatch(
//           addNotification(
//             `${
//               err.message === FAILED_TO_FETCH
//                 ? "Network unavailable or api down"
//                 : err
//             }`,
//             "error"
//           )
//         );
//       console.log("red", navigate);

//       if (navigate) {
//         if (redirectHome) {
//           navigate("/portal/dashboard");
//         } else if (redirectLogin) {
//           navigate("/login");
//         }
//       }
//     }

//     return undefined;
//   }
// };
