import { useState } from "react";
// Local Imports
import { InputTypes, PasswordFieldProps } from "../../../types/InputTypes";
import { handleFormValueChange } from "./../inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  TextFieldProps,
  Theme,
  IconButton,
  InputAdornment,
} from "@mui/material";
// component
import Iconify from "./../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

/**
 * Password field, allows for hidden or shown text field option
 */
export default function PasswordField(props: PasswordFieldProps) {
  const {
    id,
    label,
    placeholder,
    helperText,
    error,
    disabled = false,
    handleChange,
    layout = { xs: 12, md: 12, lg: 12 },
    size,
    value,
    variant,
    noUnderline,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  //check
  const defaultProps: TextFieldProps = {
    id,
    label,
    placeholder,
    helperText,
    error,
    disabled,
    value,
    size,
    onBlur: props.handleBlur,
    type: "password",
    variant,
  };

  return (
    <GridStyle item {...layout}>
      <TextField
        {...defaultProps}
        sx={{ width: "100%" }}
        onChange={handleFormValueChange(id, InputTypes.STRING, handleChange)}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end" size={size}>
                <Iconify
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              </IconButton>
            </InputAdornment>
          ),
          ...(variant === "standard" && noUnderline
            ? { disableUnderline: true }
            : {}),
        }}
      />
    </GridStyle>
  );
}
