import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
// MUI
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { Logout as LogoutIcon } from "@mui/icons-material";
// Components
import Iconify from "../../components/Iconify";
import { userAccountRoleTypes, userRoleTypes } from "../../features/auth/types";
import { UserRoleType, useCurrentUser } from "@/hooks/useCurrentUser";
import { UserAccountRoleType } from "@/hooks/useRoles";
// Store

/**s
 * Formats SideBar navigation
 */
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.common.white,
  padding: theme.spacing(1),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

export interface Item {
  title: string;
  path: string;
  icon: string | JSX.Element;
  children?: Item[];
  requiredUserRoles?: UserRoleType[];
  requiredUserAccountRoles?: (UserAccountRoleType | UserRoleType)[];
}

// Handles each navigational menu item in the sidebar, handling collapsible menu items
function NavItem({ item, active }: { item: Item; active: Function }) {
  const isActiveRoot = active(item.path);
  const user = useCurrentUser();

  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || "";

  const accounts = user?.roles;
  const userRole = user?.role;

  const currentAccountRoles =
    userRole === UserRoleType.ADMIN
      ? [userRoleTypes.ADMIN]
      : accounts
          ?.find((account) => account.account_id === currentAccount)
          ?.roles?.map((role) => role.toLowerCase());

  //console.log('reached', requiredUserAccountRoles, currentAccountRoles, currentAccount)
  //const isAllowed = userPermissions && userPermissions.some(userPermission => requiredPermissions.includes(userPermission))
  const isAllowedUserRole =
    userRole && item?.requiredUserRoles?.includes(userRole);
  const isAllowedAccountRole = item?.requiredUserAccountRoles?.some(
    (userAccountRole) => currentAccountRoles?.includes(userAccountRole)
  );
  //console.log(isAllowedUserRole, isAllowedAccountRole)
  const isAllowed = isAllowedUserRole && isAllowedAccountRole;

  const { title, path, icon, children } = item;

  const activeParentStyle = {
    fontWeight: "fontWeightMedium",
    bgcolor: "secondary.main",
  };

  const activeChildStyle = {
    color: "common.white",
    fontWeight: "fontWeightMedium",
    bgcolor: "secondary.light",
  };

  if (children) {
    return (
      <>
        {/* @ts-ignore TODO: CHECK */}
        <ListItemStyle
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeParentStyle),
          }}
        >
          <Tooltip title={title} placement="right">
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          </Tooltip>
          <ListItemText
            disableTypography
            primary={title}
            sx={{ fontSize: "14px" }}
          />
          <Iconify
            icon={
              isActiveRoot
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16 }}
          />
        </ListItemStyle>

        <Collapse in={isActiveRoot} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: Item) => {
              // if (item.restrict && user) {
              //     console.log(item.restrict.find(role => role === user.role))
              // }
              // if (!item.restrict || (user && item.restrict.find(role => role === user.role))) {

              // }
              const isActiveSub = active(item.path);

              return (
                // @ts-ignore TODO: CHECK
                <ListItemStyle
                  key={item.title}
                  component={RouterLink}
                  to={item.path}
                  sx={{
                    bgcolor: "common.white",
                    color: "primary.main",
                    "&:hover": activeChildStyle,
                    ...(isActiveSub && activeChildStyle),
                  }}
                >
                  <Tooltip title={`${title} - ${item.title}`} placement="right">
                    <ListItemIconStyle>
                      {item.icon && item.icon}
                    </ListItemIconStyle>
                  </Tooltip>
                  <ListItemText
                    disableTypography
                    primary={item.title}
                    sx={{ fontSize: "14px" }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  if (!isAllowed) {
    return <></>;
  }

  return (
    // @ts-ignore TODO: CHECK
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeParentStyle),
      }}
    >
      <Tooltip title={title} placement="right">
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      </Tooltip>
      <ListItemText
        disableTypography
        primary={title}
        sx={{ fontSize: "14px" }}
      />
    </ListItemStyle>
  );
}

// Dashboard left sidebar menu, displays navigations

const determineLinkPath = (
  path: string,
  currentAccount: string | null | undefined
) => {
  return path.includes("admin")
    ? path
    : `${path}/${currentAccount ? currentAccount : ""}`;
};

export default function NavSection({
  navConfig,
  ...other
}: {
  navConfig: Item[];
  [x: string]: any;
}) {
  const { pathname } = useLocation();

  let { accountId: currentAccountParam } = useParams();

  const currentAccount = currentAccountParam || "";

  // Function to checks path match
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  const formattedNavConfig = navConfig.map((config) => ({
    ...config,
    path: determineLinkPath(config.path, currentAccount),
  }));
  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: 1,
      }}
    >
      {/* <div sx={{ flex: 1 }}> */}
      <List disablePadding>
        {formattedNavConfig.map((item: Item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
      {/* </Box> */}
      {/* <Box> */}
      <List disablePadding>
        <NavItem
          item={{
            title: "Logout",
            path: "/logout",
            icon: <LogoutIcon />,
          }}
          active={match}
        />
        {/* </Box> */}
      </List>
    </Box>
  );
}
