// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

/*
  Reference for finding Icons:
   - https://akveo.github.io/eva-icons/#/
*/

export default function Iconify({ icon, sx, ...other }: { icon: string, sx?: any, [x: string]: any }) {
    return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
