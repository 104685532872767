import { NumberFormatProps } from "react-number-format"
import {CalendarPickerView} from "@mui/lab";

export enum InputTypes {
    STRING = 'STRING',
    PASSWORD = 'PASSWORD',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    SELECTION = 'SELECTION',
    CHECKBOX = 'CHECKBOX',
    FORMATTED = 'FORMATTED',
    TEXT_BOX = 'TEXT_BOX',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE',
    RADIO = 'RADIO',
    // SEARCH = 'SEARCH'
    FILE = 'FILE'
}

export type GridSize = 1 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 10 | 12

// Formik Field Wrapper Props
export type FormInputProps = {
    id: string,
    label: string,
    placeholder?: string,
    info?: string,
    disabled?: boolean,

    layout?: { xs?: GridSize, md?: GridSize, lg: GridSize },
    size?: 'small' | 'medium',
    variant?: 'outlined' | 'standard',
    noUnderline?: boolean,
    noLabel?: boolean,
    errorInIcon?: boolean,

    fieldType: InputTypes,

    // Date Specific 
    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,
    views?: CalendarPickerView[],
    inputFormat?: string

    // Selection Specific
    values?: Array<{ value: string | number | Object, label: string, info?: string }>

    // Mask/Formatted specific
    mask?: string,

    // File Specific
    multiple?: boolean,
    fileType?: Array<string>

    // Currency and Percentage Props
    numFormatProps?: NumberFormatProps,

    // additional function performed onBlur
    blurFunction?: () => void;
    onChange?: (event: any) => any;
}

// Input Field Props
export interface InputFieldProps {
    // General attributes
    id: string,
    label: string,
    placeholder?: string,
    info?: string,
    helperText: string,
    error: boolean,
    touched: boolean,
    disabled?: boolean,

    // Input Functions
    handleChange: (value: any, field: string) => void,
    onChange?: (e: any) => any,
    handleBlur: (e: any) => void,
    setTouched: (field: string) => void,
    setError: (field: string, message: string) => void,

    // Field Input Size
    layout?: { xs?: GridSize, md?: GridSize, lg: GridSize },
    size: 'small' | 'medium',
    variant: 'outlined' | 'standard',
    noUnderline: boolean,
    noLabel: boolean,
    errorInIcon: boolean,

    fieldType: InputTypes,

    value: string | number | Date | boolean | Array<File> | Object | null // | {id: number, [x: string]: any}

    // Date Specific 
    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,
    views?: string[],
    inputFormat?: string

    // Radio Specific
    radio?: string,

    // Selection Specific
    values?: Array<{ value: string | number | Object, label: string, info?: string }>

    // Mask/Formatted specific
    mask?: string,

    // File Specific
    multiple?: boolean,
    fileType?: Array<string>

    // Currency and Percentage Props
    numFormatProps?: NumberFormatProps
}

// Checkbox Input Field Props
export interface CheckboxFieldProps extends InputFieldProps {
    value: boolean,

    fieldType: InputTypes.CHECKBOX
}

// Currency Input Field Props
export interface CurrencyFieldProps extends InputFieldProps {
    value: number,

    fieldType: InputTypes.CURRENCY,
    numFormatProps?: NumberFormatProps
}

// Date Input Field Props
export interface DateFieldProps extends Omit<InputFieldProps, "handleBlur" | "placeholder"> {
    value: Date | null,

    fieldType: InputTypes.DATE,

    minDate?: Date,
    maxDate?: Date,
    nullable?: boolean,
    views: CalendarPickerView[],
    inputFormat: string
}

// File Input Field Props
export interface FileFieldProps extends Omit<InputFieldProps, "layout"> {
    value: Array<File>
    fileType?: string[]
    fieldType: InputTypes.FILE
}

// Formatted Input Field Props
export interface FormattedFieldProps extends InputFieldProps {
    value: string,

    fieldType: InputTypes.FORMATTED
}

// Password Input Field Props
export interface PasswordFieldProps extends InputFieldProps {
    value: string,

    fieldType: InputTypes.PASSWORD
}

// Percentage Input Field Props
export interface PercentageFieldProps extends InputFieldProps {
    value: number,

    fieldType: InputTypes.PERCENTAGE,
    numFormatProps?: NumberFormatProps
}

// Radio Input Field Props
export interface RadioFieldProps extends InputFieldProps {
    value: string,

    defaultValue: string | null,

    values: Array<{ value: string | number, label: string, info?: string }>
}

// Selection Input Field Props
export interface SelectionFieldProps extends InputFieldProps {
    value: string | number,
    fieldType: InputTypes.SELECTION,

    values: Array<{ value: string | number, label: string,  disabled?: boolean, }>
}


export const defaultFileTypes = [
    'application/json',
    'application/msword',
    'application/ogg',
    'application/pdf',
    'application/rtf',
    'application/typescript',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-fontobject',
    'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.visio',
    'application/x-7z-compressed',
    'application/x-abiword',
    'application/x-bzip',
    'application/x-bzip2',
    'application/x-csh',
    'application/x-rar-compressed',
    'application/x-tar',
    'application/xhtml+xml',
    'application/xml',
    'application/zip',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/tiff',
    'image/webp',
    'image/x-icon',
    'text/calendar',
    'text/css',
    'text/csv',
    'text/html',
    'text/html',
    'text/plain'
];

