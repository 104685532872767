// @mui
// import { useTheme } from '@mui/material/styles';
import {
  Container,
  Typography,
  Stack,
  Box,
  Button,
  Modal,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

// components
import Page from "../../../components/Page";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  AccountBalanceRounded,
  EnergySavingsLeafRounded,
  TrendingUpRounded,
} from "@mui/icons-material";
import { Formik, FormikProps } from "formik";
import FormInput from "../../../components/FormInput";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
} from "chart.js";
import { InputTypes } from "../../../types/InputTypes";
import { MCP_FUNDS } from "../../../constants";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import Carousel from "@/components/Carousel";
import InvestModal from "@/components/InvestModal";

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler
);
// sections

// ----------------------------------------------------------------------

const InfoCard = styled("div")`
  min-width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: white;
  box-shadow: rgba(254, 205, 211, 0.1) 0px 4px 16px,
    rgba(254, 205, 211, 0.1) 0px 8px 24px,
    rgba(254, 205, 211, 0.1) 0px 16px 56px;
`;

const Header = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const CardBody = styled("span")`
  font-size: 28px;
`;

const InfoCards = [
  {
    title: "Assets under management (AUM)",
    body: "$17 billion",
    color: "#f59c26",
    icon: "balance",
  },
  {
    title: "Environmental, social and governance (ESG) score",
    body: "85",
    color: "#70AF1A",
    icon: "esg",
  },
  {
    title: "Annual rate of return",
    body: "9.16%",
    color: "#02ede6",
    icon: "trendingUp",
  },
];

const determineIcon = (icon: string) => {
  if (icon === "balance") {
    return <AccountBalanceRounded sx={{ color: "#f59c26" }} />;
  } else if (icon === "esg") {
    return <EnergySavingsLeafRounded sx={{ color: "#70AF1A" }} />;
  } else if (icon === "trendingUp") {
    return <TrendingUpRounded sx={{ color: "#02ede6" }} />;
  }
};

const DashboardApp = () => {
  // const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = useCurrentUser();
  const username = user?.given_name || "";
  //console.log('dashboard render')
  const theme = useTheme();
  //console.log("current", useCurrentUser());

  return (
    <Page title="Dashboard">
      <Container maxWidth={false}>
        <Header>
          <Typography variant="h4" sx={{ color: "primary.main" }}>
            {`Hi, Welcome ${username}`}
          </Typography>
          <Button
            size="large"
            onClick={() => {
              handleOpen();
            }}
            style={{ maxWidth: 160, maxHeight: 60 }}
            variant="contained"
          >
            Invest today
          </Button>
        </Header>

        <Grid container spacing={5} style={{ justifyContent: "center" }}>
          {InfoCards.map((infoCard) => {
            return (
              <Grid key={infoCard.title} xs={12} md={6} lg={4}>
                <InfoCard
                  style={{ borderBottom: `3px solid ${infoCard.color}` }}
                >
                  {determineIcon(infoCard.icon)}
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {infoCard.title}
                  </h2>
                  <CardBody>{infoCard.body}</CardBody>
                </InfoCard>
              </Grid>
            );
          })}
          <div style={{ marginTop: 20, marginBottom: 40, width: 1200 }}>
            <Carousel />
          </div>
        </Grid>

        <InvestModal handleClose={handleClose} open={open} />

        <Grid container spacing={6}></Grid>
      </Container>
    </Page>
  );
};

export default DashboardApp;
