import Page from "@/components/Page";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Box, Stack, Typography } from "@mui/material";
import UserAccountCard from "@/features/landing_page/components/UserAccountCard";
import { useNavigate } from "react-router";
import { useUserCanViewInvestorMemorandumsQuery } from "@/hooks/useMemorandums";
import Spinner from "@/components/Spinner";
import { useUserAccountByUserQuery } from "@/hooks/useUserAccounts";

const LandingPage = () => {
  const user = useCurrentUser();

  const navigate = useNavigate();
  const userAccounts = user?.roles;
  // const { isFetching: fetchingUserAccounts, data: userAccounts } =
  //   useUserAccountByUserQuery({ userId: (user && user.id) || null });
  const userHasAccounts = userAccounts && userAccounts.length > 0;
  const { isFetching: fetchingCanViewMemo, data: canViewMemo } =
    useUserCanViewInvestorMemorandumsQuery({
      userId: (user && user.id) || null,
    });
  //const userCanViewMemorandums = true;

  const formattedUserAccounts =
    (userAccounts &&
      userAccounts.filter(
        (userAccount) => !userAccount.archived && userAccount.roles.length > 0
      )) ||
    [];

  // const userHasAccounts = userAccounts && userAccounts.length > 0;
  // const { isFetching: fetchingCanViewMemo, data: canViewMemo } =
  //   useUserCanViewInvestorMemorandumsQuery({
  //     userId: (user && user.id) || null,
  //   });

  return (
    <div>
      <Page title="Landing page">
        <Stack spacing={4}>
          <Typography variant="h4" sx={{ color: "primary.main" }}>
            Welcome {user?.given_name}
          </Typography>
          <Typography variant="h6" sx={{ color: "primary.main" }}>
            Accounts
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            {formattedUserAccounts.length > 0 ? (
              formattedUserAccounts.map((userAccount) => {
                return <UserAccountCard userAccount={userAccount} />;
              })
            ) : (
              <span>You currently do not have access to any accounts</span>
            )}
          </Box>

          {fetchingCanViewMemo ? (
            <Spinner loading={fetchingCanViewMemo} />
          ) : canViewMemo ? (
            <>
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Investor memorandums
              </Typography>

              <Box
                onClick={() => navigate(`/portal/memorandums`)}
                sx={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid black",
                    width: "fit-content",
                    padding: "20px",
                    minWidth: 160,
                    backgroundColor: "white",
                    cursor: "pointer",
                  },
                  {
                    "&:hover": {
                      backgroundColor: "lightgray",
                    },
                  },
                ]}
              >
                <h4>View Memorandums</h4>
              </Box>
            </>
          ) : null}
        </Stack>
      </Page>
    </div>
  );
};

export default LandingPage;
