import { apiGetRequest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import { User, UserRoleType } from "./useCurrentUser";
import { toast } from "react-toastify";
import { NewUserAccounts } from "./useUsers";
import Login from "@/features/auth/pages/Login";
interface useLoginCognitoProps {
  email: string;
  password: string;
}

export const useLoginCognito = () =>
  useMutation({
    mutationFn: ({ email, password }: useLoginCognitoProps) => {
      return Auth.signIn(email, password);
    },
  });

interface useCheckUserExistsInDbProps {
  sub: string | null;
  enabled: boolean;
}

export const useCheckUserExistsInDb = ({
  sub,
  enabled,
}: useCheckUserExistsInDbProps) =>
  useQuery({
    queryKey: ["userExists", sub],
    queryFn: async () =>
      await apiGetRequest<User>({
        query: `newUsers?sub=${sub}&login=true`,
      }),
    retry: false,
    enabled: enabled,
  });

interface useReauthUserQueryProps {
  enabled: boolean;
}

export const useReauthUserQuery = ({ enabled }: useReauthUserQueryProps) =>
  useQuery({
    queryKey: ["reauthUser"],
    queryFn: async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        return currentUser;
      } catch (error) {
        if (error) {
          console.log('error', error)
          throw new Error(error.toString());
        }
      }
    },
    retry: false,
    enabled,
  });

export const useLogoutCognito = () =>
  useMutation({
    mutationFn: () => {
      return Auth.signOut({ global: true });
    },
  });

interface useForgotPasswordProps {
  email: string;
}

export const useForgotPassword = () =>
  useMutation({
    mutationFn: ({ email }: useForgotPasswordProps) => {
      return Auth.forgotPassword(email);
    },
  });
interface useVerifyUserProps {
  email: string;
  code: string;
}

export const useVerifyUser = () =>
  useMutation({
    mutationFn: ({ email, code }: useVerifyUserProps) => {
      return Auth.confirmSignUp(email, code);
    },
  });

interface useUpdatePasswordProps {
  email: string;
  code: string;
  password: string;
}

export const useUpdatePassword = () =>
  useMutation({
    mutationFn: ({ email, code, password }: useUpdatePasswordProps) => {
      return Auth.forgotPasswordSubmit(email, code, password);
    },
  });

const generatePassword = () => {
  const length = 9,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    symbolSet = '!@#$%^&*()',
    numset = "0123456789";
  let retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  for (var j = 0, m = symbolSet.length; j < length; ++j) {
    retVal += symbolSet.charAt(Math.floor(Math.random() * m));
  }
  for (var k = 0, o = symbolSet.length; k < length; ++k) {
    retVal += numset.charAt(Math.floor(Math.random() * o));
  }
  return retVal;
  //return "#Test1234";
};

export interface useSignupCognitoAndAddToDbProps {
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  role: UserRoleType;
  newUserAccounts: NewUserAccounts[];
  newUserMemorandums: string[];
  currentAccount: string | null;
}

export const useSignupCognitoAndAddToDb = () => {
  return useMutation({
    mutationFn: async ({
      email,
      given_name,
      family_name,
      birthdate,
      role,
      newUserAccounts,
      newUserMemorandums,
      currentAccount,
    }: useSignupCognitoAndAddToDbProps) => {
      try {
        const password = generatePassword();
        //'const session = await Auth.currentSession();
        // const isAdmin = session.getIdToken()?.payload["custom:role"] === UserRoleTypes.ADMIN
        // if (!isAdmin) {
        //   throw new Error("Unauthorised")
        // }

        const newCognitoUser = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
            given_name,
            family_name,
            name: given_name + " " + family_name,
            birthdate,
            "custom:role": role,
          },
        });
        if (newCognitoUser && newCognitoUser.userSub) {
          const sub = newCognitoUser.userSub;
          // const queryParams = new URLSearchParams({
          //   newUser: JSON.stringify({ sub, given_name, family_name, email, newUserAccounts })
          // });
          const newDatabaseUser = await apiPostRequest<string>({
            query: currentAccount
              ? `newUsers?newUser=true&accountId=${currentAccount}`
              : `newUsers?newUser=true`,
            body: JSON.stringify({
              sub,
              given_name,
              family_name,
              email,
              newUserAccounts,
              newUserMemorandums,
            }),
          });
          return newDatabaseUser;
        }
      } catch (error) {
        if (error) {
          throw new Error(error.toString());
        }
      }
    },
  });
};

export interface useBulkCreateAccountsProps {
  newUsers: {
    email: string;
    given_name: string;
    family_name: string;
    birthdate: string;
    role: UserRoleType;
    newUserAccounts: NewUserAccounts[];
    newUserMemorandums: string[];
  }[];
  setCompletedCount: any
}

export const useBulkCreateAccounts = () => {
  return useMutation({
    mutationFn: async ({ newUsers, setCompletedCount }: useBulkCreateAccountsProps) => {
      const result = await Promise.allSettled(
        newUsers.map(async (newUser) => {
          try {
            const {
              email,
              given_name,
              family_name,
              birthdate,
              role,
              newUserAccounts,
              newUserMemorandums,
            } = newUser;
            const password = generatePassword();
            const newCognitoUser = await Auth.signUp({
              username: email,
              password: password,
              attributes: {
                email,
                given_name,
                family_name,
                name: given_name + " " + family_name,
                birthdate,
                "custom:role": role,
              },
            });
            if (newCognitoUser && newCognitoUser.userSub) {
              const sub = newCognitoUser.userSub;
              // const queryParams = new URLSearchParams({
              //   newUser: JSON.stringify({ sub, given_name, family_name, email, newUserAccounts })
              // });
              const newDatabaseUser = await apiPostRequest<string>({
                query: `newUsers?newUser=true`,
                body: JSON.stringify({
                  sub,
                  given_name,
                  family_name,
                  email,
                  newUserAccounts,
                  newUserMemorandums,
                }),
              })
              return newDatabaseUser;
            }
          } catch (error) {
            if (error) {
              throw new Error(error.toString());
            }
          } finally {
            console.log('reached here')
            setCompletedCount((prevCount: number) => prevCount + 1); // Using a callback function to ensure correct state update
          }
        })
      );
      return result;
    },
  });
};
