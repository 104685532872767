import { CircularProgress, Grid } from "@mui/material";

interface SpinnerProps {
  loading: boolean;
  align?: "left" | "center" | "right";
  size?: number;
  value?: number;
}

const Spinner = ({
  loading,
  size = 40,
  align = "center",
  value,
}: SpinnerProps) => {
  const hasValue = (value || value === 0) && typeof value === "number";
  return (
    <>
      {loading ? (
        <Grid container justifyContent={align} direction="row">
          <CircularProgress
            size={size}
            variant={hasValue ? "determinate" : "indeterminate"}
            value={value}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default Spinner;
