import { Navigate, useParams } from "react-router";
import React from "react";

import { UserRoleType, useCurrentUser } from "@/hooks/useCurrentUser";
import { toast } from "react-toastify";

interface ProtectedRouteProps {
  requiredUserRoles?: string[];
  requiredUserAccountRoles?: string[];
  redirectPath?: string;
  children: any;
  onlyCheckUserRole?: boolean;
}

const ProtectedRoute = ({
  requiredUserRoles,
  requiredUserAccountRoles,
  redirectPath = "/login",
  children,
  onlyCheckUserRole,
}: ProtectedRouteProps) => {
  const user = useCurrentUser();
  const debug = true;
  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || "";
  const accounts = user?.roles;
  const userRole = user?.role;
  //console.log("current", user, currentAccount);
  const currentAccountArchived = accounts?.find(
    (account) => account.account_id === currentAccount
  )?.archived;
  const currentAccountRoles =
    userRole === UserRoleType.ADMIN
      ? [UserRoleType.ADMIN]
      : accounts
          ?.find((account) => account.account_id === currentAccount)
          ?.roles?.map((role) => role.toLowerCase()) || [];
  debug &&
    console.log(
      "reached",
      requiredUserAccountRoles,
      currentAccountRoles,
      currentAccount,
      userRole
    );
  //const isAllowed = userPermissions && userPermissions.some(userPermission => requiredPermissions.includes(userPermission))
  //console.log(userRole, requiredUserRoles);
  const isAllowedUserRole = userRole && requiredUserRoles?.includes(userRole);
  debug && console.log("archived", currentAccountArchived);
  const isAllowedAccountRole =
    requiredUserAccountRoles?.some((userAccountRole) =>
      currentAccountRoles?.includes(userAccountRole)
    ) && !currentAccountArchived;
  debug && console.log(isAllowedUserRole, isAllowedAccountRole);
  const isAllowed = onlyCheckUserRole
    ? isAllowedUserRole
    : isAllowedUserRole && isAllowedAccountRole;
  if (!isAllowed) {
    console.log(redirectPath);
    toast.error("Unauthorised to access this page, redirecting..");
    return (
      <Navigate
        to={
          redirectPath.includes("login") || redirectPath.includes("landing")
            ? redirectPath
            : `${redirectPath}/${currentAccount}`
        }
        replace
      />
    );
  } else {
    return children;
  }
};

export default React.memo(ProtectedRoute);
