import { apiGetRequest, apiGetRequestTest, apiPatchRequest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  last_login: string;
  sub: string;
  archived: boolean;
}

export const USERS_PER_PAGE = 5;
export const SEARCH_USER_DEBOUNCE_TIME = 400

export const useUsersQuery = () =>
  useQuery({
    queryKey: ["users"],
    queryFn: async () =>
      await apiGetRequest<User[]>({
        query: `users`,
      }),
    retry: false,
  });

interface useUsersByAccountQueryProps {
  account: string;
}

export const useUsersByAccountQuery = ({
  account,
}: useUsersByAccountQueryProps) =>
  useQuery({
    queryKey: ["usersByAccount", account],
    queryFn: async () =>
      await apiGetRequest<User[]>({
        query: `newUsers?accountId=${account}`,
      }),
    retry: false,
  });


export interface NewUserAccounts {
  account: string;
  roles: string[]
}

interface useUpdateUserInvestorMemorandumsProps {
  userId: string;
  newInvestorMemorandums: string[]
}

export const useUpdatedUserInvestorMemorandums = () =>
  useMutation({
    mutationFn: ({ userId, newInvestorMemorandums }: useUpdateUserInvestorMemorandumsProps) => {
      return apiPatchRequest<string>({
        query: `newUsers?userId=${userId}&newInvestorMemorandums=${JSON.stringify(newInvestorMemorandums)}`,
      });
    },
    onSuccess: () => {
      toast.success("User memorandums updated");
    },
  });

interface usePaginationUsersQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}

interface PaginationUsersReturn {
  count: number;
  data: User[]
}

export const usePaginationUsersQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationUsersQueryProps) =>
  useQuery({
    queryKey: ["usersByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationUsersReturn>({
        query: `users?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });


interface usePaginationAccountUsersQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
  accountId?: string;
}


export const usePaginationAccountUsersQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort,
  accountId
}: usePaginationAccountUsersQueryProps) =>
  useQuery({
    queryKey: ["usersByPage", pageNumber, itemsPerPage, searchString, filter, sort, accountId],
    queryFn: async () =>
      await apiGetRequestTest<PaginationUsersReturn>({
        query: `accounts/${accountId}/users?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });

