import { HeaderSort } from "@/components/PaginationTable/PaginationTable";
import PaginationTableWithAddNew from "@/components/PaginationTableWithAddNew/PaginationTableWithAddNew";
import AddNewModelModal from "@/components/PaginationTableWithAddNewWrapper/AddNewModelModal";
import BulkAddUserModal from "@/features/admin/components/BulkAddUserModal";
import UploadFundReport from "@/features/admin/components/UploadFundReport";
import UploadWholesaleReport from "@/features/admin/components/UploadWholesaleReport";
import NewAccountModal from "@/features/user_account/components/NewAccountModal";
import { Account } from "@/hooks/useAccounts";
import { User, usePaginationAccountUsersQuery } from "@/hooks/useUsers";
import { useDebounce } from "@/hooks/useDebounce";
import { Fund } from "@/hooks/useFunds";
import { ReportCategories, ReportType } from "@/hooks/useReports";
import { SEARCH_USER_DEBOUNCE_TIME, USERS_PER_PAGE } from "@/hooks/useUsers";
import { Filter, Model, ModelFilter } from "@/types/GeneralTypes";
import { determineModelHeaders, modelToQueryHookMap } from "@/utils/modelUtils";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

interface PaginationTableWithAddNewGenericProps {
  model: Exclude<Model, Model.USERS>;
  paginationTableTitle: string;
  addModelModalButtonTitle: string;
  funds?: Fund[];
  accounts?: Account[];
  reportTypes?: ReportType[];
}

interface PaginationUsersTableWithAddNewProps {
  model: Model.USERS;
  accounts: Account[];
  funds: Fund[];
  createAccountModalOpen: boolean;
  setCreateAccountModalOpen: (open: boolean) => void;
  createBulkAccountModalOpen?: boolean;
  setCreateBulkAccountModalOpen?: (open: boolean) => void;
  paginationTableTitle: string;
  addModelModalButtonTitle: string;
  accountId?: string;
}

type PaginationTableWithAddNewWrapperProps =
  | PaginationTableWithAddNewGenericProps
  | PaginationUsersTableWithAddNewProps;

interface fetchDataByModelProps {
  model: Model;
  itemsPerPage: number;
  pageNumber: number;
  searchString: string;
  filter: string;
  sort: string;
}

export interface Filters {
  [ModelFilter.FUND]: Fund[]; // Define the property using ModelFilters.FUND
  [ModelFilter.REPORT_TYPE]: ReportType[];
  [ModelFilter.ACCOUNT]: Account[];
  [Filter.START_PERIOD]: string;
  [Filter.END_PERIOD]: string;
  [Filter.START_UPLOAD_DATE]: string;
  [Filter.END_UPLOAD_DATE]: string;
}
const fetchDataByModel = ({
  model,
  itemsPerPage,
  pageNumber,
  searchString,
  filter,
  sort,
}: fetchDataByModelProps) => {
  const queryHook = modelToQueryHookMap[model]; // Get the appropriate query hook based on the model
  if (!queryHook) {
    throw new Error(`No query hook found for model: ${model}`);
  }
  return queryHook({
    itemsPerPage,
    pageNumber,
    searchString,
    filter,
    sort,
  });
};

const PaginationTableWithAddNewWrapper = (
  props: PaginationTableWithAddNewWrapperProps & { fromAdmin?: boolean }
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { model, paginationTableTitle, addModelModalButtonTitle } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const debouncedSearchTerm = useDebounce<string>(
    searchTerm,
    SEARCH_USER_DEBOUNCE_TIME
  );

  const { fromAdmin = false } = props;

  const { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || null;

  const [addModelModalOpen, setAddModelModalOpen] = useState(false);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchTerm]);

  const headers = determineModelHeaders(model);

  const [sortedHeaders, setSortedHeaders] = useState<HeaderSort[]>(
    headers.map((header) => ({
      headerName: header === "report_type" ? "report_type_name" : header,
      sortEnabled: false,
      sortDirection: "asc",
    }))
  );

  const isUser = model === Model.USERS;

  const handleRequestSort = (clickedHeader: HeaderSort) => {
    const newHeaders: HeaderSort[] = sortedHeaders.map((header) => {
      if (header.headerName === clickedHeader.headerName) {
        if (!header.sortEnabled) {
          return {
            ...header,
            sortEnabled: true,
            sortDirection: "asc",
          };
        } else if (header.sortDirection === "asc") {
          return {
            ...header,
            sortDirection: "desc",
          };
        } else {
          return {
            ...header,
            sortEnabled: false,
            sortDirection: "asc",
          };
        }
      }
      return header;
    });
    setCurrentPage(1);
    setSortedHeaders(newHeaders);
  };

  const sort = sortedHeaders
    .filter((header) => header.sortEnabled)
    .map(
      (enabledHeader) =>
        `${enabledHeader.headerName}:${enabledHeader.sortDirection}`
    )
    .join(",");

  const isPaginatedAccountUsers = model === Model.USERS && currentAccount;
  //console.log(isPaginatedAccountUsers);

  const { isFetching: fetchingPageData, data: pageData } =
    isPaginatedAccountUsers
      ? usePaginationAccountUsersQuery({
          itemsPerPage: USERS_PER_PAGE,
          pageNumber: currentPage,
          searchString: debouncedSearchTerm,
          filter,
          sort,
          accountId: currentAccount,
        })
      : fetchDataByModel({
          model,
          itemsPerPage: USERS_PER_PAGE,
          pageNumber: currentPage,
          searchString: debouncedSearchTerm,
          filter,
          sort,
        });

  //console.log("data model", pageData, model);

  const handleModalOpen = () => {
    setAddModelModalOpen(true);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchTerm, filter]);

  const filters: Filters = isUser
    ? {
        [ModelFilter.FUND]: [],
        [ModelFilter.REPORT_TYPE]: [],
        [ModelFilter.ACCOUNT]: [],
        [Filter.START_PERIOD]: "",
        [Filter.END_PERIOD]: "",
        [Filter.START_UPLOAD_DATE]: "",
        [Filter.END_UPLOAD_DATE]: "",
      }
    : {
        [ModelFilter.FUND]: props.funds || [],
        [ModelFilter.REPORT_TYPE]: props.reportTypes || [],
        [ModelFilter.ACCOUNT]: props.accounts || [],
        [Filter.START_PERIOD]: "",
        [Filter.END_PERIOD]: "",
        [Filter.START_UPLOAD_DATE]: "",
        [Filter.END_UPLOAD_DATE]: "",
      };

  return (
    <div>
      {isUser ? (
        <>
          <NewAccountModal
            fromAdmin={fromAdmin}
            open={props.createAccountModalOpen}
            handleClose={() => props.setCreateAccountModalOpen(false)}
          />
          <BulkAddUserModal
            open={
              !!(
                props.createBulkAccountModalOpen &&
                props.createBulkAccountModalOpen
              )
            }
            handleClose={() =>
              props.setCreateBulkAccountModalOpen &&
              props.setCreateBulkAccountModalOpen(false)
            }
            funds={props.funds}
          />
        </>
      ) : (
        <AddNewModelModal
          model={model}
          addModelModalOpen={addModelModalOpen}
          setAddModelModalOpen={setAddModelModalOpen}
          funds={props.funds}
          accounts={props.accounts}
          reportTypes={props.reportTypes}
        />
      )}

      {isUser ? (
        <PaginationTableWithAddNew
          fetchingPageData={fetchingPageData}
          fetchingTotalData={fetchingPageData}
          pageData={pageData?.data}
          totalNumberData={pageData?.count}
          model={model}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleModalOpen={handleModalOpen}
          tableTitle={paginationTableTitle}
          addButtonTitle={addModelModalButtonTitle}
          handleRequestSort={handleRequestSort}
          sortedHeaders={sortedHeaders}
          filter={filter}
          setFilter={setFilter}
          filters={filters}
          setCreateAccountModalOpen={props.setCreateAccountModalOpen}
          setCreateBulkUserModalOpen={props.setCreateBulkAccountModalOpen}
          userModalProps={{
            funds: props.funds,
            accounts: props.accounts,
          }}
          fromAdmin={fromAdmin}
        />
      ) : (
        <PaginationTableWithAddNew
          fetchingPageData={fetchingPageData}
          fetchingTotalData={fetchingPageData}
          pageData={pageData?.data}
          totalNumberData={pageData?.count}
          model={model}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleModalOpen={handleModalOpen}
          tableTitle={paginationTableTitle}
          addButtonTitle={addModelModalButtonTitle}
          handleRequestSort={handleRequestSort}
          sortedHeaders={sortedHeaders}
          filter={filter}
          setFilter={setFilter}
          filters={filters}
          fromAdmin={fromAdmin}
        />
      )}
    </div>
  );
};

export default PaginationTableWithAddNewWrapper;
