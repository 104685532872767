import {
  apiGetRequest,
  apiPatchRequest,
  apiPostRequest,
} from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReportCategories } from "./useReports";
import { UserAccountRoleType } from "./useRoles";

import { toast } from "react-toastify";

export interface UserAccountRole {
  id: string;
  name: UserAccountRoleType;
}

export interface ViewableReportType {
  id: string;
  name: ReportCategories;
}

export const MAX_USER_ACCOUNTS_PER_ROLE =
  2 * Object.keys(UserAccountRoleType).length;

export interface UserAccount {
  user_account_id: string;
  user_id: string;
  username: string;
  account_id: string;
  account_name: string;
  roles: UserAccountRole[];
  viewable_report_types: ViewableReportType[];
  archived: boolean;
}

interface useUserAccountByUserAndAccountQueryProps {
  accountId: string | null;
  userId: string;
}

//Get user account for a given user id and account id
export const useUserAccountByUserAndAccountQuery = ({
  accountId,
  userId,
}: useUserAccountByUserAndAccountQueryProps) =>
  useQuery({
    queryKey: ["userAccountByUserAndAccount", accountId, userId],
    queryFn: async () =>
      await apiGetRequest<UserAccount>({
        query: `newUserAccounts?userId=${userId}&accountId=${accountId}`,
      }),
    retry: false,
    enabled: accountId && userId ? true : false,
  });

interface useUserAccountByUserQueryProps {
  userId: string | null;
}

//Get user account for a given user id
export const useUserAccountByUserQuery = ({
  userId,
}: useUserAccountByUserQueryProps) =>
  useQuery({
    queryKey: ["userAccountsByUser", userId],
    queryFn: async () =>
      await apiGetRequest<UserAccount[]>({
        query: `newUserAccounts?userId=${userId}`,
      }),
    retry: false,
    enabled: userId ? true : false,
  });

interface useUpdateUserAccountRolesProps {
  userAccountId: string;
  newRoles: string[];
}

export const useUpdateUserAccountRoles = () =>
  useMutation({
    mutationFn: ({
      userAccountId,
      newRoles,
    }: useUpdateUserAccountRolesProps) => {
      return apiPostRequest<string>({
        query: `newUserAccounts?userAccountId=${userAccountId}&newRoles=${JSON.stringify(
          newRoles
        )}`,
      });
    },
    onSuccess: () => {
      toast.success("User roles updated");
    },
  });

interface useAddUserAccountProps {
  userId: string;
  accountId: string;
  newRoles: string[];
}

export const useAddUserAccount = () =>
  useMutation({
    mutationFn: ({ userId, accountId, newRoles }: useAddUserAccountProps) => {
      return apiPostRequest<string>({
        query: `newUserAccounts?userId=${userId}&accountId=${accountId}&newRoles=${JSON.stringify(
          newRoles
        )}`,
      });
    },
    onSuccess: () => {
      toast.success("New user account created");
    },
  });

export const useArchiveUserAccount = () =>
  useMutation({
    mutationFn: ({ userAccountId }: { userAccountId: string }) => {
      return apiPatchRequest<string>({
        query: `newUserAccounts?userAccountId=${userAccountId}&archive=true`,
      });
    },
    onSuccess: () => {
      toast.success("User archived");
    },
  });

export const useUnarchiveUserAccount = () =>
  useMutation({
    mutationFn: ({ userAccountId }: { userAccountId: string }) => {
      return apiPatchRequest<string>({
        query: `newUserAccounts?userAccountId=${userAccountId}&unarchive=true`,
      });
    },
    onSuccess: () => {
      toast.success("User unarchived");
    },
  });
