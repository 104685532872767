import { Model, RecordType, TableData } from "@/types/GeneralTypes";
import { Paper, Table, TableContainer } from "@mui/material";
import React, { useState } from "react";
import PaginationFooter from "./PaginationFooter/PaginationFooter";
import PaginationHeader from "./PaginationHeader/PaginationHeader";
import PaginationBody from "./PaginationBody";
import Spinner from "@/components/Spinner";
import EditUserAccountModal from "@/components/EditUserAccountModal";
import { Fund } from "@/hooks/useFunds";
import { Account } from "@/hooks/useAccounts";
import { User } from "@/hooks/useUsers";

interface PaginationTableProps<T extends RecordType> {
  tableData: TableData<T>;
  totalNumberEntries: number;
  numberEntriesPerPage: number;
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  model: Model;
  handleRequestSort?: (newSort: HeaderSort) => void;
  sortedHeaders?: HeaderSort[];
  loading: boolean;
  userModalProps?: { funds: Fund[]; accounts: Account[] };
  fromAdmin: boolean;
}

export interface HeaderSort {
  headerName: string;
  sortEnabled: boolean;
  sortDirection: "asc" | "desc";
}

const PaginationTable = <T extends RecordType>({
  tableData,
  totalNumberEntries,
  numberEntriesPerPage,
  currentPage,
  model,
  handleRequestSort,
  sortedHeaders,
  setCurrentPage,
  loading,
  userModalProps,
  fromAdmin,
}: PaginationTableProps<T>) => {
  const sortedData = tableData.body;

  const totalPages = Math.ceil(totalNumberEntries / numberEntriesPerPage) || 1;

  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [editedUser, setEditedUser] = useState<string | null>(null);

  const handleCloseEditUserModal = () => setEditUserModalOpen(false);

  const handleOpenEditUserModal = ({ userId }: { userId: string }) => {
    setEditedUser(userId);
    setEditUserModalOpen(true);
  };

  return (
    <div>
      <Paper>
        <TableContainer sx={{ minWidth: 650, maxHeight: 600, minHeight: 300 }}>
          <Table stickyHeader>
            <PaginationHeader
              sortedHeaders={sortedHeaders}
              handleRequestSort={handleRequestSort}
            />
            <PaginationBody
              headers={tableData.headers}
              numberSkeletonFields={
                sortedHeaders?.filter(
                  (header) => !header.headerName.includes("id")
                )?.length || 5
              }
              model={model}
              tableBodyData={sortedData}
              loading={loading}
              handleOpenEditUserModal={handleOpenEditUserModal}
            />
          </Table>
        </TableContainer>
      </Paper>
      <PaginationFooter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        loading={loading}
      />
      {editedUser && userModalProps && userModalProps.accounts && sortedData ? (
        <EditUserAccountModal
          accounts={userModalProps.accounts}
          user={editedUser}
          open={editUserModalOpen}
          handleClose={handleCloseEditUserModal}
          fromAdmin={fromAdmin}
          selectedUser={
            sortedData.find((data) => data.user_id === editedUser)?.name
          }
        />
      ) : null}
    </div>
  );
};
export default PaginationTable;
