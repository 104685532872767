import { InputTypes } from "../../types/InputTypes";

export const handleFormValueChange = (field: string, type: InputTypes, handleChange: (value: any, field: string) => void, setTouched?: Function) => (event: any) => {
    let value;

    switch (type) {
        case InputTypes.CURRENCY:
        case InputTypes.PERCENTAGE:
            if (event.target.value === '' || event.target.value === '.') value = '0';
            else value = parseFloat(event.target.value).toFixed(2);
            break;

        default:
            value = event.target.value;
            break;
    }
    if (setTouched) {
        setTouched(field);
    }

    handleChange(value, field);
}