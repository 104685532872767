import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutCognito } from "@/hooks/useAuth";
import { useCurrentUser, useCurrentUserActions } from "@/hooks/useCurrentUser";
import { toast } from "react-toastify";

// User logout
export default function Logout() {
  const signOut = useLogoutCognito();
  const user = useCurrentUser();
  const { logout } = useCurrentUserActions();
  const navigate = useNavigate();
  const { isSuccess: loggedOut } = signOut;
  useEffect(() => {
    console.log("logging out..");
    logout();
    signOut.mutate();
  }, []);
  if (loggedOut) {
    let confirm = "Successfully Logged Out!";
    if (user?.given_name) {
      confirm = `Goodbye ${user?.given_name}!`;
    }
    toast.success(confirm);
    navigate("/login");
  }

  return null;
}
