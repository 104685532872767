import { Box, Button, Grid, Modal, Stack } from "@mui/material";
import { Formik, FormikProps, FormikValues } from "formik";
import { PictureAsPdf } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { useState } from "react";
import Spinner from "../../../../components/Spinner";
import PDFViewer from "../../../../components/PdfViewer/PdfViewer";

import {
  ReportCategories,
  ReportType,
  ViewableReport,
  useWholesaleReportUrlQuery,
  useViewableWholesaleReportsByReportTypeAndAccountId,
} from "@/hooks/useReports";
import { useQueryClient } from "@tanstack/react-query";
import OpenInNewTabButton from "@/components/OpenInNewTabButton";
import { useCurrentUser } from "@/hooks/useCurrentUser";

const StyledButton = styled("button")`
  all: unset;
  margin: 25px;
  margin-left: 0px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: #f9fafb;
  cursor: pointer;
  &:hover {
    background-color: #c6c7c7;
  }
  min-width: 150px;
  height: 25px;
`;
//filter by date, dropdown, filer by type: transaction report, portfolio report, exposure report, custom reports
//date filter changes by type
//transaction and portfolio: Take in two daily dates -> output one excel file
//exposure and custom reports: Take in two mm/yy dates -> output all monthly reports in that range pdfs
//annual report: only show yy field -> output all annual pdfs
type Report = {
  period: number;
  name: string;
  s3_key: string;
  // Add other report properties as needed
};

// const downloadReport = (isExcel: boolean) => {
//   const downloadURL = isExcel
//     ? "https://filesamples.com/samples/document/xlsx/sample3.xlsx"
//     : "https://metrics.com.au/wp-content/uploads/2021/09/Metrics-Master-Income-Trust-MXT-FY2021-Annual-Report.pdf";
//   const link = document.createElement("a");
//   link.href = downloadURL;
//   link.download = `MetricsTest.${isExcel ? "xlsx" : "pdf"}`;
//   link.target = "_blank";
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

interface FilterReportsProps {
  viewableReportTypes: ReportType[];
  currentAccount: string;
}

interface FilterParams {
  reports: ViewableReport[];
  year: string;
  showMonthly: boolean;
  startDate?: string;
  endDate?: string;
  reportType?: string;
}

interface FilterState {
  year: string;
  showMonthly: boolean;
  startDate?: string;
  endDate?: string;
  reportType: string;
}

const filterReports = ({
  reports,
  year,
  showMonthly,
  startDate,
  endDate,
}: FilterParams): ViewableReport[] => {
  //console.log(reports, startDate, endDate, showMonthly);
  if (!reports) {
    return [];
  }
  if (showMonthly && endDate && startDate) {
    return reports.filter((report) => {
      console.log("test", report.period);
      const reportDate = new Date(report.period);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const firstMinuteOfReportMonth = new Date(
        reportDate.getFullYear(),
        reportDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      const firstMinuteOfStartDate = new Date(
        startDateObj.getFullYear(),
        startDateObj.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      const firstMinuteOfEndDate = new Date(
        endDateObj.getFullYear(),
        endDateObj.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      return (
        firstMinuteOfStartDate <= firstMinuteOfReportMonth &&
        firstMinuteOfReportMonth <= firstMinuteOfEndDate
      );
    });
  } else {
    // yyyy format filter
    // const test = reports.map((report) => ({
    //   date: new Date(report.period).getFullYear().toString(),
    // }));
    // console.log("test", test, new Date(year).getFullYear().toString());
    return reports.filter(
      (report) =>
        new Date(report.period).getFullYear().toString() ===
        new Date(year).getFullYear().toString()
    );
  }
};

const FilterWholesaleReports = ({
  viewableReportTypes,
  currentAccount,
}: FilterReportsProps) => {
  const formattedViewableReports = viewableReportTypes.map(
    (viewableReportType) => ({
      value: viewableReportType.id,
      label: `${viewableReportType.name} Report`,
    })
  );

  const queryClient = useQueryClient();
  const user = useCurrentUser();

  const userAccountId =
    user?.roles.find((role) => role.account_id === currentAccount)
      ?.user_account_id || null;

  const [currentFileKey, setCurrentFileKey] = useState<string | null>(null);
  const [filterParams, setFilterParams] = useState<FilterState | null>(null);

  const { isFetching: fetchingViewableReports, data: viewableReports } =
    useViewableWholesaleReportsByReportTypeAndAccountId({
      userAccountId,
      reportType: filterParams?.reportType || null,
    });
  const { isFetching: fetchingReport, data: report } =
    useWholesaleReportUrlQuery({
      fileKey: currentFileKey,
      userId: user?.id || null,
    });

  const initialValues = {
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
    year: new Date(),
    reportType: formattedViewableReports && formattedViewableReports[0]?.value,
  };

  const reportsToRender =
    viewableReports &&
    filterParams &&
    filterReports({
      reports: viewableReports,
      showMonthly: filterParams.showMonthly,
      startDate: filterParams.startDate,
      endDate: filterParams.endDate,
      year: filterParams.year,
    }).sort((filteredReport) => filteredReport.period);

  // Function to filter reports based on the specified criteria

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // enableReinitialize
        onSubmit={async (values: any) => {
          const reportType = values.reportType;
          const showMonthly =
            formattedViewableReports
              .find((viewableReport) => viewableReport.value === reportType)
              ?.label.includes(ReportCategories.EXPOSURE) || false;
          const startDate = values.startDate;
          const endDate = values.endDate;
          const year = values.year;
          setCurrentFileKey(null);
          queryClient.invalidateQueries({ queryKey: ["viewableReports"] });
          setFilterParams({
            reportType,
            showMonthly,
            startDate,
            endDate,
            year,
          });
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit, values } = props;
          const { reportType } = values;
          const reportTypeName = viewableReportTypes.find(
            (report) => report.id === reportType
          )?.name;
          const showYearly =
            reportTypeName === ReportCategories.FINANCIAL_YEAR ||
            reportTypeName === ReportCategories.TAX;
          const showMonthly = reportTypeName === ReportCategories.EXPOSURE;
          const isAutomic = reportTypeName === ReportCategories.PERIODIC;
          const sameReportType = reportType === filterParams?.reportType;

          return (
            <>
              <Grid item xs={8}>
                <form onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack spacing={3} style={{ maxWidth: 400 }}>
                      <FormInput
                        id="reportType"
                        label="Report Type"
                        fieldType={InputTypes.SELECTION}
                        layout={{ xs: 6, md: 6, lg: 6 }}
                        values={formattedViewableReports}
                      />
                    </Stack>
                    {isAutomic ? (
                      <OpenInNewTabButton
                        title="View in automic portal"
                        url="https://automicgroup.com.au"
                        onClick={() => setCurrentFileKey(null)}
                      />
                    ) : showYearly ? (
                      <FormInput
                        id="year"
                        label="Year"
                        fieldType={InputTypes.DATE}
                        layout={{ xs: 6, md: 6, lg: 6 }}
                        views={["year"]}
                        inputFormat="yyyy"
                      />
                    ) : (
                      <>
                        <FormInput
                          id="startDate"
                          label="Start date"
                          fieldType={InputTypes.DATE}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          views={showMonthly ? ["month", "year"] : undefined}
                          inputFormat={showMonthly ? "MM/yyyy" : "dd/MM/yyyy"}
                        />
                        <FormInput
                          id="endDate"
                          label="End date"
                          fieldType={InputTypes.DATE}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          views={showMonthly ? ["month", "year"] : undefined}
                          inputFormat={showMonthly ? "MM/yyyy" : "dd/MM/yyyy"}
                        />
                      </>
                    )}

                    {isAutomic ? null : (
                      <Button
                        id="target"
                        disabled={!reportType}
                        size="large"
                        style={{
                          maxWidth: 296,
                          marginLeft: 6,
                          minHeight: 30,
                          marginTop: 30,
                          marginBottom: 10,
                        }}
                        type="submit"
                        variant="contained"
                      >
                        View reports
                      </Button>
                    )}
                  </Stack>
                </form>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {reportsToRender &&
                  !fetchingViewableReports &&
                  sameReportType ? (
                    reportsToRender.length > 0 ? (
                      reportsToRender.map((report) => {
                        const fileKey = report.s3_key;
                        return (
                          <div key={report.id}>
                            <StyledButton
                              onClick={async () => {
                                queryClient.invalidateQueries({
                                  queryKey: ["viewableReport"],
                                });
                                setCurrentFileKey(fileKey);
                              }}
                            >
                              <PictureAsPdf sx={{ color: "#FF0000" }} />
                              <span>{report.name}</span>
                            </StyledButton>
                          </div>
                        );
                      })
                    ) : sameReportType ? (
                      <div style={{ marginLeft: 8, fontWeight: "bold" }}>
                        No reports found
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div>
                  {fetchingViewableReports ? (
                    <Spinner loading={fetchingViewableReports} />
                  ) : null}
                  {fetchingReport ? (
                    <Spinner loading={fetchingReport} />
                  ) : report && sameReportType ? (
                    <PDFViewer file={report} isVisible={true} />
                  ) : null}
                </div>
              </Grid>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FilterWholesaleReports;
