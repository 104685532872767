import { forwardRef } from "react";
import { IMaskInput } from 'react-imask'
// Local Imports
import { FormattedFieldProps, InputTypes } from "../../../types/InputTypes"
import { handleFormValueChange } from "../inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import {
    Grid,
    Theme,
    IconButton,
    InputAdornment,
    FormControl,
    Input,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Tooltip
} from "@mui/material";
// component
import Iconify from "./../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(1),
    width: '100%'
}))

// Text Masking
interface CustomProps {
    onChange: (event: { target: { name: string, value: string } }) => void,
    mask: string,
    name: string,
}

// Masking of Field
const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, mask, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={mask}
                // @ts-ignore
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        )
    }
)

/**
 * Formatted Text Input Field
 * Applies a mask or string format/definition to the string
 */
export default function FormattedTextField(props: FormattedFieldProps) {
    const {
        id,
        label,
        placeholder,
        info = null,
        helperText,
        error,
        disabled = false,
        handleChange,
        handleBlur,
        layout = { xs: 12, md: 12, lg: 12 },
        size,
        variant,
        noUnderline,
        errorInIcon,
        value,
        mask
    } = props;

    let defaultProps = {
        id,
        label,
        placeholder,
        error,
        disabled,
        value,
        onChange: handleFormValueChange(id, InputTypes.FORMATTED, handleChange),
        onBlur: handleBlur,
        size,
        inputComponent: TextMaskCustom as any,
        inputProps: {
            mask: mask
        },
        endAdornment: (!errorInIcon && info) ?
            // Info and Tooltip
            <InputAdornment position="end">
                <Tooltip title={info} placement='right'>
                    <IconButton edge="end" size={size}>
                        <Iconify icon={'eva:question-mark-circle-outline'} />
                    </IconButton>
                </Tooltip>
            </InputAdornment>
            :
            (errorInIcon && error) ?
                <InputAdornment position="end">
                    <Tooltip title={helperText} placement='right'>
                        <IconButton edge="end" sx={{ pl: 0 }} size={size}>
                            <Iconify icon={'eva:alert-circle-outline'} sx={{ color: 'red' }} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
                :
                <></>,
        ...(variant === 'standard' && noUnderline) ? { disableUnderline: true } : {}
    }

    return (
        <GridStyle
            item
            {...layout}
        >
            <FormControl
                sx={{ width: '100%' }}
            >
                <InputLabel error={error} htmlFor={label}>{label}</InputLabel>
                {variant !== 'outlined' ?
                    <Input
                        {...defaultProps}
                    />
                    :
                    <OutlinedInput
                        {...defaultProps}
                    />
                }
                {
                    helperText != null &&
                    <FormHelperText error={error}>{helperText}</FormHelperText>
                }
            </FormControl>
        </GridStyle>
    )
}