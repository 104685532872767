import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik, FormikProps, FormikValues } from "formik";
import { PictureAsPdf } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { useState } from "react";
import Spinner from "../../../../components/Spinner";
import PDFViewer from "../../../../components/PdfViewer/PdfViewer";

import {
  ReportCategories,
  ReportType,
  ViewableReport,
  useWholesaleReportUrlQuery,
  useViewableFundReportsByReportTypeAndAccountId,
  useViewableWholesaleReportsByReportTypeAndAccountId,
  useFundReportUrlQuery,
} from "@/hooks/useReports";
import { useQueryClient } from "@tanstack/react-query";
import OpenInNewTabButton from "@/components/OpenInNewTabButton";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { Fund } from "@/hooks/useFunds";
import { Bar, Line } from "react-chartjs-2";
import SectorGraph from "./SectorGraph";
import StockLineGraph from "./StockLineGraph";

const StyledButton = styled("button")`
  all: unset;
  margin: 25px;
  margin-left: 0px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: #f9fafb;
  cursor: pointer;
  &:hover {
    background-color: #c6c7c7;
  }
  min-width: 150px;
  width: fit-content;
  height: 25px;
`;
//filter by date, dropdown, filer by type: transaction report, portfolio report, exposure report, custom reports
//date filter changes by type
//transaction and portfolio: Take in two daily dates -> output one excel file
//exposure and custom reports: Take in two mm/yy dates -> output all monthly reports in that range pdfs
//annual report: only show yy field -> output all annual pdfs
type Report = {
  period: number;
  name: string;
  s3_key: string;
  // Add other report properties as needed
};

// const downloadReport = (isExcel: boolean) => {
//   const downloadURL = isExcel
//     ? "https://filesamples.com/samples/document/xlsx/sample3.xlsx"
//     : "https://metrics.com.au/wp-content/uploads/2021/09/Metrics-Master-Income-Trust-MXT-FY2021-Annual-Report.pdf";
//   const link = document.createElement("a");
//   link.href = downloadURL;
//   link.download = `MetricsTest.${isExcel ? "xlsx" : "pdf"}`;
//   link.target = "_blank";
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

interface FilterReportsProps {
  funds: Fund[];
  currentAccount: string;
}

interface FilterParams {
  reports: ViewableReport[];
  startDate?: string;
  endDate?: string;
}

interface FilterState {
  startDate?: string;
  endDate?: string;
  fundId: string;
}

const filterReports = ({
  reports,
  startDate,
  endDate,
}: FilterParams): ViewableReport[] => {
  //console.log(reports, startDate, endDate, showMonthly);
  if (!reports) {
    return [];
  }
  if (endDate && startDate) {
    return reports.filter((report) => {
      const reportDate = new Date(report.period);
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const firstMinuteOfReportMonth = new Date(
        reportDate.getFullYear(),
        reportDate.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      const firstMinuteOfStartDate = new Date(
        startDateObj.getFullYear(),
        startDateObj.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      const firstMinuteOfEndDate = new Date(
        endDateObj.getFullYear(),
        endDateObj.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      return (
        firstMinuteOfStartDate <= firstMinuteOfReportMonth &&
        firstMinuteOfReportMonth <= firstMinuteOfEndDate
      );
    });
  } else {
    return [];
  }
};

const FilterFundReports = ({ currentAccount, funds }: FilterReportsProps) => {
  const formattedFunds = funds.map((fund) => ({
    label: `${fund.name} (${fund.ticker})`,
    value: fund.id,
  }));
  const xLabels = [
    "Stock A",
    "Stock B",
    "Stock C",
    "Stock D",
    "Stock E",
    "Stock F",
    "Stock G",
  ];

  const theme = useTheme();

  const YEARLY_INVESTMENT = {
    labels: [2018, 2019, 2020, 2021, 2022, 2023],
    datasets: [
      {
        data: [25_000, 35_400, 41_300, 36_400, 43_300, 52_000],
        fill: true,
        // @ts-ignore
        borderColor: theme.palette.chart.blue[0],
        // @ts-ignore
        backgroundColor: theme.palette.chart.blue[1],
        // fill: {
        //   target: 'origin',
        //   // @ts-ignore
        //   above: theme.palette.chart.blue[0],
        //
        // }
      },
    ],
  };

  const INVESTMENT_PERFORMANCE = {
    labels: xLabels,
    datasets: [
      {
        data: [1, -2.2, 2.3, 2.8, -1.2, 3.2, -0.7, 1.3, 1.7, -0.3, 1.9],
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.light,
          theme.palette.primary.main,
          theme.palette.primary.main,
          theme.palette.secondary.light,
          theme.palette.primary.main,
          theme.palette.secondary.light,
          theme.palette.primary.main,
          theme.palette.primary.main,
          theme.palette.primary.main,
          theme.palette.secondary.light,
        ],
      },
    ],
  };

  const queryClient = useQueryClient();
  const user = useCurrentUser();

  const [currentFileKey, setCurrentFileKey] = useState<string | null>(null);
  const [filterParams, setFilterParams] = useState<FilterState | null>(null);

  const userAccountId =
    user?.roles.find((role) => role.account_id === currentAccount)
      ?.user_account_id || null;

  const { isFetching: fetchingFundReports, data: fundReports } =
    useViewableFundReportsByReportTypeAndAccountId({
      fundId: filterParams?.fundId || null,
      userAccountId,
    });
  const { isFetching: fetchingReport, data: report } = useFundReportUrlQuery({
    fileKey: currentFileKey,
    userId: user?.id || null,
  });

  const initialValues = {
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    endDate: new Date(),
    year: new Date(),
    fund: null,
  };

  const reportsToRender =
    fundReports &&
    filterParams &&
    filterReports({
      reports: fundReports,
      startDate: filterParams.startDate,
      endDate: filterParams.endDate,
    }).sort((filteredReport) => filteredReport.period);

  // Function to filter reports based on the specified criteria

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // enableReinitialize
        onSubmit={async (values: any) => {
          const fundId = values.fund;

          const startDate = values.startDate;
          const endDate = values.endDate;
          setCurrentFileKey(null);
          queryClient.invalidateQueries({ queryKey: ["viewableFundReports"] });
          setFilterParams({
            startDate,
            endDate,
            fundId,
          });
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit, values } = props;
          const { fund } = values;
          const sameReportType = fund === filterParams?.fundId;

          return (
            <>
              <Grid item xs={8}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "85%",
                  }}
                >
                  <form onSubmit={handleSubmit} style={{ width: "30%" }}>
                    <Stack spacing={3}>
                      <Stack spacing={3} style={{ maxWidth: 400 }}>
                        <FormInput
                          id="fund"
                          label="Fund"
                          fieldType={InputTypes.SELECTION}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          values={formattedFunds}
                        />
                      </Stack>

                      <>
                        <FormInput
                          id="startDate"
                          label="Start date"
                          fieldType={InputTypes.DATE}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          views={["month", "year"]}
                          inputFormat={"MM/yyyy"}
                        />
                        <FormInput
                          id="endDate"
                          label="End date"
                          fieldType={InputTypes.DATE}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          views={["month", "year"]}
                          inputFormat={"MM/yyyy"}
                        />
                      </>

                      <Button
                        id="target"
                        disabled={!fund}
                        size="large"
                        style={{
                          maxWidth: 296,
                          marginLeft: 6,
                          minHeight: 30,
                          marginTop: 30,
                          marginBottom: 10,
                        }}
                        type="submit"
                        variant="contained"
                      >
                        View reports
                      </Button>
                    </Stack>
                  </form>
                  {sameReportType ? <SectorGraph /> : null}
                  {sameReportType ? (
                    <StockLineGraph
                      fundName={
                        funds.find((fundToFind) => fundToFind.id === fund)
                          ?.name || ""
                      }
                    />
                  ) : null}
                </div>

                {/* {sameReportType ? (
                  <Grid container spacing={5} mt={5}>
                    <Grid xs={12} lg={6} style={{ textAlign: "center" }}>
                      <div
                        style={{
                          position: "relative",
                          margin: "auto",
                          width: "40vw",
                        }}
                      >
                        <Typography variant="h5" mb={2}>
                          Yearly Total Investment
                        </Typography>
                        <Line
                          data={YEARLY_INVESTMENT}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              y: {
                                ticks: {
                                  callback: (value, index, values) =>
                                    "$" + value.toLocaleString(),
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={12} lg={6} style={{ textAlign: "center" }}>
                      <div
                        style={{
                          position: "relative",
                          margin: "auto",
                          width: "40vw",
                        }}
                      >
                        <Typography variant="h5" mb={2}>
                          Individual Stock Performance
                        </Typography>
                        <Bar
                          data={INVESTMENT_PERFORMANCE}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              y: {
                                ticks: {
                                  callback: (value, index, values) =>
                                    value + "%",
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : null} */}
                <div
                  style={{ display: "flex", flexWrap: "wrap", marginTop: 30 }}
                >
                  {reportsToRender && !fetchingFundReports && sameReportType ? (
                    reportsToRender.length > 0 ? (
                      reportsToRender.map((report) => {
                        const fileKey = report.s3_key;
                        return (
                          <div key={report.id}>
                            <StyledButton
                              onClick={async () => {
                                queryClient.invalidateQueries({
                                  queryKey: ["viewableReport"],
                                });
                                setCurrentFileKey(fileKey);
                              }}
                            >
                              <PictureAsPdf sx={{ color: "#FF0000" }} />
                              <span>{report.s3_key}</span>
                            </StyledButton>
                          </div>
                        );
                      })
                    ) : sameReportType ? (
                      <div style={{ marginLeft: 8, fontWeight: "bold" }}>
                        No reports found
                      </div>
                    ) : null
                  ) : null}
                </div>
                <div>
                  {fetchingFundReports ? (
                    <Spinner loading={fetchingFundReports} />
                  ) : null}
                  {fetchingReport ? (
                    <Spinner loading={fetchingReport} />
                  ) : report && sameReportType ? (
                    <PDFViewer file={report} isVisible={true} />
                  ) : null}
                </div>
              </Grid>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default FilterFundReports;
