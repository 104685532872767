import { useState } from 'react';

export interface Checkbox {
  id: string;
  label: string;
  checked: boolean;
}

interface useCheckboxesProps {
  initialState: Checkbox[]
}

const useCheckboxes = ({ initialState }: useCheckboxesProps) => {
  const [checkboxes, setCheckboxes] = useState(initialState);

  const handleCheckboxChange = (index: number) => {
    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = [...prevCheckboxes];
      newCheckboxes[index] = { ...newCheckboxes[index], checked: !newCheckboxes[index].checked };
      return newCheckboxes;
    });
  };

  const resetCheckboxes = () => {
    setCheckboxes(initialState);
  };

  return { checkboxes, handleCheckboxChange, resetCheckboxes, setCheckboxes };
};

export default useCheckboxes