import * as Yup from "yup";
import { Formik, FormikProps } from "formik";

// material
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
// component
import { InputTypes } from "../../../types/InputTypes";
import FormInput from "../../../components/FormInput";
import { LoadingButton } from "@mui/lab";
import { userRoleTypes } from "../types";

import { toast } from "react-toastify";
import {
  useCurrentUserActions,
  User,
  UserRoleType,
} from "@/hooks/useCurrentUser";
import { useCheckUserExistsInDb, useLoginCognito } from "@/hooks/useAuth";

// ----------------------------------------------------------------------
export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useCurrentUserActions();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const loginCognito = useLoginCognito();

  const {
    error,
    isIdle,
    isPending: loggingCognito,
    isSuccess: cognitoUserFound,
    data: userTemp,
  } = loginCognito;

  console.log("temp", userTemp);

  const sub = userTemp?.attributes?.sub;

  const { data: userExists, isFetching: checkingUserInDb } =
    useCheckUserExistsInDb({
      sub,
      enabled: cognitoUserFound,
    });

  //console.log("test for mutate", error, isIdle, cognitoUserFound, userTemp);
  // console.log("test for fetch", userExists);

  if (userExists && userExists.id) {
    const newUser = {
      id: userExists.id,
      username: userTemp.username,
      ...userTemp.attributes,
      roles: userExists.roles,
    };
    // Extract Role attribute
    if (newUser["custom:role"]) {
      newUser.role = newUser["custom:role"];
    } else {
      newUser.role = userRoleTypes.USER;
    }

    // UI Notification
    let confirm = "Successfully Logged In!";
    if (newUser.given_name) {
      confirm = `Welcome ${newUser.given_name}!`;
    }
    const { id, email, given_name, family_name, birthdate, role, roles } =
      newUser;
    // const currentAccount = roles && roles[0] && roles[0].account_id;
    const formattedUser: User = {
      id,
      email,
      given_name,
      family_name,
      birthdate,
      role,
      roles,
    };
    login(formattedUser);
    toast.success(confirm);
    navigate(role === UserRoleType.ADMIN ? "/portal/admin" : `/portal/landing`);
  }

  // const loginuser = useMutation({
  //   mutationFn: ({ email, password }: { email: string; password: string }) => {
  //     try {
  //       const userTemp = await Auth.signIn(cred.email, cred.password);
  //     } catch (error) {}
  //   },
  // });

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values: any) => {
          loginCognito.mutate({
            email: values.email,
            password: values.password,
          });
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit } = props;
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <FormInput
                    id="email"
                    label="Email"
                    fieldType={InputTypes.STRING}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                  />
                  <FormInput
                    id="password"
                    label="Password"
                    fieldType={InputTypes.PASSWORD}
                    layout={{ xs: 12, md: 12, lg: 12 }}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loggingCognito || checkingUserInDb}
                    disabled={loggingCognito || checkingUserInDb}
                  >
                    Login
                  </LoadingButton>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
}
