import {
  useUserAccountByUserAndAccountQuery,
  useUserAccountByUserQuery,
} from "@/hooks/useUserAccounts";
import { Typography, Box, Modal, Button, Stack } from "@mui/material";
import { useParams } from "react-router";
import Spinner from "../Spinner";
import EditUserTable from "./EditUserTable";
import { useRolesQuery } from "@/hooks/useRoles";
import { Account } from "@/hooks/useAccounts";
import { useState } from "react";
import AddUserAccountModal from "./EditUserTable/AddUserAccountModal";
import { User } from "@/hooks/useUsers";
import { ensureArray } from "@/utils/generalUtils";
import { useFundsWithMemorandumsByUserIdQuery } from "@/hooks/useMemorandums";
import { useFundsQuery } from "@/hooks/useFunds";
import UserMemonrandumCheckboxes from "./UserMemonrandumCheckboxes";

const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "85%",
  overflowY: "scroll",
};

interface EditUserAccountModalProps {
  handleClose: any;
  open: boolean;
  user: string;
  fromAdmin: boolean;
  accounts?: Account[];
  selectedUser: string;
}

const EditUserAccountModal = ({
  user,
  handleClose,
  open,
  fromAdmin = false,
  accounts,
  selectedUser,
}: EditUserAccountModalProps) => {
  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || null;

  const [newUserAccountModalOpen, setNewUserAccountModalOpen] = useState(false);

  const handleCloseNewUserAccountModal = () =>
    setNewUserAccountModalOpen(false);

  const { isFetching: fetchingUserAccounts, data: userAccounts } = fromAdmin
    ? useUserAccountByUserQuery({ userId: user })
    : useUserAccountByUserAndAccountQuery({
        userId: user,
        accountId: currentAccount,
      });

  const { isFetching: fetchingFunds, data: funds } = useFundsQuery({
    enabled: fromAdmin,
  });

  const { isFetching: fetchingUserMemorandums, data: userMemorandums } =
    useFundsWithMemorandumsByUserIdQuery({ userId: user, enabled: fromAdmin });
  const userHasUserMemorandums = userMemorandums && userMemorandums.length > 0;
  const formattedUserAccounts = ensureArray(userAccounts); //convert UserAccount single query into UserAccount[] with one entry
  const formattedUserMemorandums = userMemorandums?.map((memo) => memo.fund_id);

  // Call the useCheckboxes hook and pass the initial state

  const { isFetching: fetchingRoles, data: roles } = useRolesQuery({
    accountId: currentAccount,
  });
  const fetchingUserAccountsandRoles = fetchingUserAccounts || fetchingRoles;
  const fetchingUserMemorandumInfo = fetchingUserMemorandums || fetchingFunds;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={ModalStyle}>
        <Stack
          direction="row"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
            Edit user {selectedUser}
          </Typography>
          {fromAdmin ? (
            <Button
              onClick={() => setNewUserAccountModalOpen(true)}
              style={{ marginBottom: 25 }}
              variant="contained"
            >
              + Add new user account
            </Button>
          ) : null}
        </Stack>
        {formattedUserAccounts && roles && !fetchingUserAccountsandRoles ? (
          <EditUserTable
            userAccounts={formattedUserAccounts}
            roles={roles}
            fromAdmin={fromAdmin}
          />
        ) : (
          <Spinner loading={fetchingUserAccountsandRoles} />
        )}
        {funds && formattedUserMemorandums && !fetchingUserMemorandumInfo ? (
          <div>
            <Typography variant="h6" my={3}>
              User viewable memorandums
            </Typography>
            <UserMemonrandumCheckboxes
              userId={user}
              userMemorandums={formattedUserMemorandums}
              funds={funds}
            />
          </div>
        ) : (
          <div style={{ marginTop: 10 }}>
            <Spinner loading={fetchingUserMemorandumInfo} />
          </div>
        )}
        {formattedUserAccounts && roles ? (
          <AddUserAccountModal
            open={newUserAccountModalOpen}
            handleClose={handleCloseNewUserAccountModal}
            loading={fetchingUserAccounts}
            userAccounts={formattedUserAccounts}
            roles={roles}
            accounts={accounts}
            user={user}
            selectedUser={selectedUser}
          />
        ) : null}
      </Box>
    </Modal>
  );
};

export default EditUserAccountModal;
