import { Draft } from "immer";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface User {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  role: UserRoleType;
  roles: LoggedInUserAccount[];
  //currentAccount: string;
}

export enum UserRoleType {
  ADMIN = "admin",
  USER = "user",
}

interface ReportType {
  id: string;
  name: string;
}

export interface LoggedInUserAccount {
  user_id: string;
  account_id: string;
  user_account_id: string;
  account: string;
  roles: string[];
  viewable_report_types: ReportType[];
  archived: boolean;
}

interface CurrentUserState {
  user: User | null;
  actions: UserActions;
}

interface UserActions {
  login: (newUser: User) => void;
  reauth: (newUser: User) => void;
  logout: () => void;
  //changeAccount: (newAccountId: string) => void;
}

const useCurrentUserStore = create<CurrentUserState>()(
  immer((set) => ({
    user: null,
    actions: {
      login: (newUser: User) =>
        set((state: Draft<CurrentUserState>) => {
          state.user = newUser;
        }),
      reauth: (newUser: User) =>
        set((state: Draft<CurrentUserState>) => {
          state.user = newUser;
        }),
      logout: () =>
        set((state: Draft<CurrentUserState>) => {
          state.user = null;
        }),
      // changeAccount: (newAccountId: string) =>
      //   set((state: Draft<CurrentUserState>) => {
      //     if (state.user) {
      //       state.user.currentAccount = newAccountId;
      //     }
      //   }),
    },
  }))
);

export const useCurrentUser = () => useCurrentUserStore((state) => state.user);

export const useCurrentUserActions = () =>
  useCurrentUserStore((state) => state.actions);
