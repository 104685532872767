import FormInput from "@/components/FormInput";
import Spinner from "@/components/Spinner";
import { Account } from "@/hooks/useAccounts";
import { Role } from "@/hooks/useRoles";
import { UserAccount, useAddUserAccount } from "@/hooks/useUserAccounts";
import { User } from "@/hooks/useUsers";
import { InputTypes } from "@/types/InputTypes";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Modal, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Formik, FormikProps } from "formik";
import { useEffect } from "react";

const ModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AddUserAccountModalProps {
  handleClose: any;
  open: boolean;
  loading: boolean;
  userAccounts: UserAccount[];
  roles: Role[];
  accounts?: Account[];
  user: string;
  selectedUser: string;
}

const AddUserAccountModal = ({
  open,
  handleClose,
  loading,
  userAccounts,
  roles,
  accounts = [],
  user,
  selectedUser,
}: AddUserAccountModalProps) => {
  const initialValues = {
    account_id: "",
    roles: [],
  };
  const queryClient = useQueryClient();
  const userAccountIds = userAccounts.map(
    (userAccount) => userAccount.account_id
  );

  const unselectedAccounts = accounts
    .filter((account) => !userAccountIds.includes(account.id))
    .map((unselectedAccount) => ({
      value: unselectedAccount.id,
      label: unselectedAccount.name,
    }));

  const addUserAccount = useAddUserAccount();

  const { isPending: addingUserAccount, isSuccess: userAccountAdded } =
    addUserAccount;

  useEffect(() => {
    if (userAccountAdded) {
      queryClient.invalidateQueries({
        queryKey: ["userAccountsByUser"],
      });
      handleClose();
    }
  }, [userAccountAdded]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={ModalStyle}>
        <Stack direction="column">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={2}
            ml={1.5}
          >
            Add user account {selectedUser}
          </Typography>
          {loading ? <Spinner loading={loading} /> : null}
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any) => {
              const newAccountId = values.account_id;
              const newRoles: Record<string, boolean> = values.newRoles || {};
              const formattedNewRoles: string[] = Object.keys(newRoles).filter(
                (key) => newRoles[key]
              );
              addUserAccount.mutate({
                accountId: newAccountId,
                userId: user,
                newRoles: formattedNewRoles,
              });
            }}
          >
            {(props: FormikProps<any>) => {
              const { handleSubmit, values } = props;
              return (
                <>
                  <Grid container xs={8}>
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={3}>
                        <FormInput
                          id="account_id"
                          label="Select account"
                          fieldType={InputTypes.SELECTION}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          values={unselectedAccounts}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {roles.map((role) => (
                            <div style={{ width: 85 }}>
                              <FormInput
                                id={`newRoles[${role.id}]`}
                                label={`${role.name}`}
                                fieldType={InputTypes.CHECKBOX}
                                layout={{ xs: 4, md: 4, lg: 4 }}
                                size="small"
                              />
                            </div>
                          ))}
                        </Box>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{ my: 4 }}
                          loading={addingUserAccount}
                        >
                          Add user account
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddUserAccountModal;
