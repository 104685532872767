export interface User {
    id: string,
    name: string,
    username: string,
    email: string,
    given_name: string,
    family_name: string,
    birthdate: string,
    role: userRoleTypes,
    status: boolean
}


export interface UserAccount {
    account: string,
    roles: string[]
}

export interface UpdateUser {
    givenName: string,
    familyName: string,
    email: string,
    birthdate: Date | string,
    role: userRoleTypes,
    user_accounts?: UserAccount[] | string
}

export enum userRoleTypes {
    ADMIN = 'admin',
    USER = 'user'
}

export enum userAccountRoleTypes {
    OWNER = 'owner',
    MANAGER = 'manager',
    ADVISOR = 'advisor',
    TAX = 'tax'
}
