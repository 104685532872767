import { Filter, Model, ModelFilter } from "@/types/GeneralTypes";
import React from "react";
import { Fund } from "@/hooks/useFunds";
import { Account } from "@/hooks/useAccounts";
import { ReportType } from "@/hooks/useReports";
import { Formik, FormikProps } from "formik";
import { Button, Grid, Stack } from "@mui/material";
import FormInput from "@/components/FormInput";
import { InputTypes } from "@/types/InputTypes";
import { filter } from "lodash";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { determinePrintableFilterName } from "@/utils/modelUtils";
import { formatHeaderName } from "@/utils/generalUtils";

interface FiltersToDisplay {
  filterKey: ModelFilter | Filter;
  options: {
    value: string;
    label: string;
  }[];
}

interface FiltersToDisplayProps {
  filtersToDisplay: FiltersToDisplay[];
  setFilter: (newFilter: string) => void;
  model: Model;
}

const PaginationFilters = ({
  filtersToDisplay,
  setFilter,
  model,
}: FiltersToDisplayProps) => {
  const initialValues = filtersToDisplay.map((filter) => ({
    [filter.filterKey]:
      filter.filterKey === Filter.START_PERIOD ||
      filter.filterKey === Filter.END_PERIOD ||
      filter.filterKey === Filter.START_UPLOAD_DATE ||
      filter.filterKey === Filter.END_UPLOAD_DATE
        ? null
        : "",
  }));
  const formattedInitialValues = Object.assign({}, ...initialValues);

  interface determineFilterStringProps {
    fund: string;
    report_type: string;
    account: string;
    model: Model;
    start_period: string;
    end_period: string;
    start_upload_date: string;
    end_upload_date: string;
  }

  const determineFilterString = ({
    fund,
    report_type,
    account,
    model,
    start_period,
    end_period,
    start_upload_date,
    end_upload_date,
  }: determineFilterStringProps) => {
    const filters = [];
    if (fund) {
      filters.push(`${model === Model.FUNDS ? "id" : "fund_id"}=${fund}`);
    }
    if (report_type) {
      filters.push(`report_type=${report_type}`);
    }
    if (account) {
      filters.push(
        `${model === Model.ACCOUNTS ? "id" : "account_id"}=${account}`
      );
    }
    if (start_period) {
      const startPeriodDate = new Date(start_period);
      const firstMinuteOfMonth = new Date(
        startPeriodDate.getFullYear(),
        startPeriodDate.getMonth(),
        1,
        0,
        0,
        0
      );
      const time = format(new Date(firstMinuteOfMonth), "yyyy-MM-dd");
      //console.log("time", time);
      filters.push(`start_period=${time}`);
    }
    if (end_period) {
      const time = format(new Date(end_period), "yyyy-MM-dd");
      filters.push(`end_period=${time}`);
    }
    if (start_upload_date) {
      const startUploadDate = new Date(start_upload_date);
      const firstMinuteOfMonth = new Date(
        startUploadDate.getFullYear(),
        startUploadDate.getMonth(),
        1,
        0,
        0,
        0
      );
      const time = format(new Date(firstMinuteOfMonth), "yyyy-MM-dd");
      filters.push(`start_upload_date=${time}`);
    }
    if (end_upload_date) {
      const time = format(new Date(end_upload_date), "yyyy-MM-dd");
      filters.push(`end_upload_date=${time}`);
    }
    //console.log("new filters are, ", filters.join(","));
    return filters.join(",");
  };

  return (
    <div>
      <Formik
        initialValues={formattedInitialValues}
        onSubmit={(values: any) => {
          const {
            fund,
            report_type,
            account,
            start_period,
            end_period,
            start_upload_date,
            end_upload_date,
          } = values;
          if (start_period && end_period && start_period > end_period) {
            toast.error("Start timeframe must be before end timeframe");
          } else if (
            start_upload_date &&
            end_upload_date &&
            start_upload_date > end_upload_date
          ) {
            toast.error("Start upload date must be before end upload date");
          } else {
            setFilter(
              determineFilterString({
                fund,
                report_type,
                account,
                start_period,
                end_period,
                start_upload_date,
                end_upload_date,
                model,
              })
            );
          }
        }}
      >
        {(props: FormikProps<any>) => {
          const { handleSubmit, values, resetForm, errors } = props;
          //console.log(errors);
          return (
            <>
              <Grid container xs={12}>
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      marginLeft: -8,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 20,
                    }}
                  >
                    {filtersToDisplay.map((filter, index) => {
                      const filterName = filter.filterKey;
                      const useDateSelect =
                        filterName.includes("period") ||
                        filterName.includes("date");
                      return useDateSelect ? (
                        <FormInput
                          key={filterName}
                          id={filterName}
                          label={`${formatHeaderName({
                            headerName: determinePrintableFilterName(
                              filter.filterKey
                            ),
                          })}`}
                          fieldType={InputTypes.DATE}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          views={["month", "year"]}
                          inputFormat={"MM/yyyy"}
                          nullable={true}
                        />
                      ) : (
                        <FormInput
                          key={filterName}
                          id={filterName}
                          label={`${formatHeaderName({
                            headerName:
                              filterName === "report_type"
                                ? "report type"
                                : filterName,
                          })}`}
                          fieldType={InputTypes.SELECTION}
                          layout={{ xs: 6, md: 6, lg: 6 }}
                          values={filtersToDisplay[index].options}
                        />
                      );
                    })}

                    <Button
                      size="large"
                      style={{ maxWidth: 296, minHeight: 30 }}
                      type="submit"
                      variant="contained"
                    >
                      Filter
                    </Button>
                    <Button
                      onClick={() => {
                        setFilter("");
                        resetForm();
                      }}
                      size="large"
                      style={{ maxWidth: 296, minHeight: 30, minWidth: 140 }}
                      type="button"
                      variant="outlined"
                    >
                      Reset filter{filtersToDisplay.length > 1 ? "s" : ""}
                    </Button>
                  </div>
                </form>
              </Grid>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaginationFilters;
