import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from "../../../components/Page";
import { useState } from "react";
import NewAccountModal from "../../user_account/components/NewAccountModal";
import { useAccountsQuery } from "@/hooks/useAccounts";
import { useFundsQuery } from "@/hooks/useFunds";

import { useReportTypesQuery } from "@/hooks/useReports";
import BulkAddUserModal from "../components/BulkAddUserModal";
import { Model } from "@/types/GeneralTypes";
import PaginationTableWithAddNewWrapper from "@/components/PaginationTableWithAddNewWrapper";

const Panel = styled("div")`
  padding: 15px;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: rgba(254, 205, 211, 0.1) 0px 4px 16px,
    rgba(254, 205, 211, 0.1) 0px 8px 24px,
    rgba(254, 205, 211, 0.1) 0px 16px 56px;
`;

const Admin = () => {
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [createBulkAccountModalOpen, setCreateBulkUserModalOpen] =
    useState(false);

  const { isFetching: fetchingAccounts, data: accounts } = useAccountsQuery();
  const { isFetching: fetchingFunds, data: funds } = useFundsQuery({});
  const { isFetching: fetchingReportTypes, data: reportTypes } =
    useReportTypesQuery();

  return (
    <Page title="Admin">
      <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
        Admin
      </Typography>
      <Stack spacing={4} mb={20}>
        {accounts && funds ? (
          <PaginationTableWithAddNewWrapper
            accounts={accounts}
            funds={funds}
            createAccountModalOpen={createAccountModalOpen}
            setCreateAccountModalOpen={setCreateAccountModalOpen}
            createBulkAccountModalOpen={createBulkAccountModalOpen}
            setCreateBulkAccountModalOpen={setCreateBulkUserModalOpen}
            model={Model.USERS}
            paginationTableTitle="Users"
            addModelModalButtonTitle=""
            fromAdmin={true}
          />
        ) : null}
        {funds ? (
          <PaginationTableWithAddNewWrapper
            funds={funds}
            model={Model.MEMORANDUMS}
            paginationTableTitle="Investor Memorandums"
            addModelModalButtonTitle="Upload Investor Memorandum"
          />
        ) : null}
        {funds ? (
          <PaginationTableWithAddNewWrapper
            funds={funds}
            model={Model.FUND_REPORTS}
            paginationTableTitle="Fund Reports"
            addModelModalButtonTitle="Upload fund report"
          />
        ) : null}
        {accounts && reportTypes ? (
          <PaginationTableWithAddNewWrapper
            accounts={accounts}
            reportTypes={reportTypes}
            model={Model.REPORTS}
            paginationTableTitle="Wholesale Reports"
            addModelModalButtonTitle="Upload wholesale report"
          />
        ) : null}

        {accounts ? (
          <PaginationTableWithAddNewWrapper
            accounts={accounts}
            model={Model.ACCOUNTS}
            paginationTableTitle="Accounts"
            addModelModalButtonTitle="Add account"
          />
        ) : null}

        {/* {funds ? (
          <FundReportsTable funds={funds} model={Model.FUND_REPORTS} />
        ) : null} */}
        {funds ? (
          <PaginationTableWithAddNewWrapper
            funds={funds}
            model={Model.FUNDS}
            paginationTableTitle="Fund"
            addModelModalButtonTitle="Add fund"
          />
        ) : null}
        <NewAccountModal
          fromAdmin={true}
          open={createAccountModalOpen}
          handleClose={() => setCreateAccountModalOpen(false)}
        />
        <BulkAddUserModal
          open={createBulkAccountModalOpen}
          handleClose={() => setCreateBulkUserModalOpen(false)}
          funds={funds}
        />
      </Stack>
    </Page>
  );
};

export default Admin;
