import { useEffect, useState } from "react";
// Local Imports
import { DateFieldProps } from "../../../types/InputTypes";
import { checkDateBefore, checkValidDate } from "../../../utils/DateUtils";
// MUI
import { styled } from "@mui/material/styles";
import { Grid, Theme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// component

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

/**
 * Date Input Field
 */
export default function DateField(props: DateFieldProps) {
  const {
    id,
    label,
    error,
    touched,
    disabled = false,
    handleChange,
    setTouched,
    setError,
    layout = { xs: 12, md: 12, lg: 12 },
    value,
    minDate = "1900-01-01",
    maxDate = "9001-01-02",
    nullable = false,
    views,
  } = props;

  const handleValueChange = (date: Date | string | null) => {
    if (!touched) setTouched(id);
    if (date !== "Invalid Date.") {
      handleChange(date, id);
    }
  };

  const [newError, setNewError] = useState(false);
  const [errorInfo, setErrorInfo] = useState("");
  useEffect(() => {
    // checks for validation of Date input
    if (touched) {
      if (value) {
        const date = new Date(value);

        if (!checkValidDate(date) && !nullable) {
          setNewError(true);
          setErrorInfo("Invalid Date.");
        } else {
          // Checks against minimum date
          if (checkDateBefore(date as unknown as string, minDate)) {
            setNewError(true);
            setErrorInfo("Date exceeds minimum date.");
            // Check against maximum date
          } else if (checkDateBefore(maxDate, value as unknown as string)) {
            setNewError(true);
            setErrorInfo("Date exceeds maximum date.");
          } else {
            setNewError(false);
            setErrorInfo("");
          }
        }
        if (error !== newError) {
          setError(id, errorInfo);
        }
      }
    }
  }, [
    touched,
    value,
    nullable,
    minDate,
    maxDate,
    error,
    newError,
    setError,
    id,
    errorInfo,
  ]);

  // const handleBlur = () => {
  //     if (!error && newError) {
  //         setError(id, errorInfo)
  //     }
  // }

  return (
    <GridStyle item {...layout}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          value={value}
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
          onChange={(newValue: any) => {
            handleValueChange(newValue);
          }}
          disabled={disabled}
          views={views}
          // slots={{
          //     input: {
          //       component: (props) => (
          //         <TextField
          //           {...props}
          //           sx={{ width: '100%' }}
          //           helperText={(!errorInIcon) ? helperText : ''}
          //           error={error}
          //           onBlur={handleBlur}
          //           disabled={disabled}
          //           size={size}
          //           variant={variant}
          //           InputProps={{
          //             endAdornment: (
          //               <>
          //                 {props.InputProps?.endAdornment}
          //                 {/* Info and tooltips */}
          //                 {!errorInIcon ? (
          //                   info && (
          //                     <InputAdornment position="end">
          //                       <Tooltip title={info} placement="right">
          //                         <IconButton edge="end" size={size}>
          //                           <Iconify icon={'eva:question-mark-circle-outline'} />
          //                         </IconButton>
          //                       </Tooltip>
          //                     </InputAdornment>
          //                   )
          //                 ) : (
          //                   errorInIcon &&
          //                   error && (
          //                     <InputAdornment position="end">
          //                       <Tooltip title={helperText} placement="right">
          //                         <IconButton edge="end" sx={{ pl: 0 }} size={size}>
          //                           <Iconify icon={'eva:alert-circle-outline'} sx={{ color: 'red' }} />
          //                         </IconButton>
          //                       </Tooltip>
          //                     </InputAdornment>
          //                   )
          //                 )}
          //               </>
          //             ),
          //             ...(variant === 'standard' && noUnderline) ? { disableUnderline: true } : {},
          //           }}
          //         />
          //       ),
          //     },
          //   }}
        />
      </LocalizationProvider>
    </GridStyle>
  );
}
