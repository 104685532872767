import { styled } from "@mui/material";

const StyledPanel = styled("div")`
  padding: 15px;
  background-color: white;
  border: 1px solid lightgray;
  box-shadow: rgba(254, 205, 211, 0.1) 0px 4px 16px,
    rgba(254, 205, 211, 0.1) 0px 8px 24px,
    rgba(254, 205, 211, 0.1) 0px 16px 56px;
`;

const Panel = ({ children }: any) => {
  return <StyledPanel>{children}</StyledPanel>;
};

export default Panel;
