// Local Imports
import { SelectionFieldProps, InputTypes } from "../../../types/InputTypes";
import { handleFormValueChange } from "../inputUtils";
// MUI
import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Theme,
  Tooltip,
} from "@mui/material";
// component
import Iconify from "./../../Iconify";

// Custom styling
const GridStyle = styled(Grid)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
}));

/**
 * Selection Field Option, takes a list of options and allows for choosing between options.
 */
export default function SelectionField(props: SelectionFieldProps) {
  const {
    id,
    label,
    info = null,
    helperText,
    error,
    disabled = false,
    handleChange,
    layout = { xs: 12, md: 12, lg: 12 },
    size,
    variant,
    noUnderline,
    noLabel,
    errorInIcon,

    value,
    values,
  } = props;

  const selectionInfoStyle: SxProps<Theme> = info
    ? {
        ".MuiSvgIcon-root": {
          marginRight: 4,
        },
      }
    : {};

  let selectProps: SelectProps = {};

  // Info and Tooltips
  if (info) {
    selectProps.endAdornment = (
      <InputAdornment position="end">
        <Tooltip title={info} placement="right">
          <IconButton edge="end" size={size}>
            <Iconify icon={"eva:question-mark-circle-outline"} />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }

  // If error display in Icon
  if (errorInIcon && error) {
    selectProps.endAdornment = (
      <InputAdornment position="end">
        <Tooltip title={helperText} placement="right">
          <IconButton edge="end" sx={{ pl: 0 }} size={size}>
            <Iconify icon={"eva:alert-circle-outline"} sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
      </InputAdornment>
    );
  }

  if (variant === "standard" && noUnderline) {
    selectProps.disableUnderline = true;
  }
  // console.log(values)
  return (
    <GridStyle item {...layout}>
      <FormControl sx={{ width: "100%" }} size={size} variant={variant}>
        {!noLabel && (
          <InputLabel error={error} htmlFor={label}>
            {label}
          </InputLabel>
        )}
        <Select
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // Set the maximum height here
              },
            },
          }}
          sx={{
            minWidth: 200,
            ...selectionInfoStyle,
            ...(noLabel
              ? {
                  ".MuiSelect-standard": {
                    paddingBottom: 0,
                    px: 0,
                  },
                }
              : {}),
          }}
          label={label}
          error={error}
          value={value}
          {...selectProps}
          onChange={handleFormValueChange(
            id,
            InputTypes.SELECTION,
            handleChange
          )}
          disabled={disabled}
          defaultValue=""
        >
          <MenuItem value={""} sx={{ zIndex: 10000000 }}>
            <em>None</em>
          </MenuItem>
          {values &&
            values.length > 0 &&
            values.map((m) => {
              return (
                <MenuItem
                  key={m.value}
                  value={m.value}
                  disabled={m.disabled}
                  sx={{ zIndex: 10000000 }}
                >
                  {m.label}
                </MenuItem>
              );
            })}
        </Select>
        {!errorInIcon && helperText != null && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </GridStyle>
  );
}
