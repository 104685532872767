import { Box, Button } from "@mui/material";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
interface PaginationFooterProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (newPage: number) => void;
  loading: boolean;
}

const PaginationFooter = ({
  currentPage,
  totalPages,
  setCurrentPage,
  loading,
}: PaginationFooterProps) => {
  return (
    <Box
      paddingY={4}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
        marginTop: "auto",
      }}
    >
      <Button
        variant="outlined"
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      >
        <KeyboardDoubleArrowLeftIcon />
      </Button>
      <Button
        variant="outlined"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <KeyboardArrowLeftIcon />
      </Button>
      <span>
        Page <b>{currentPage}</b> of {loading ? "..." : totalPages}
      </span>
      <Button
        variant="outlined"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <KeyboardArrowRightIcon />
      </Button>
      <Button
        variant="outlined"
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      >
        <KeyboardDoubleArrowRightIcon />
      </Button>
    </Box>
  );
};

export default PaginationFooter;
