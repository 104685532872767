import { useEffect, useState } from 'react';
// material
import { Grid } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid';
import {format} from "date-fns";

// component
import DataTable from '../DataTable';

interface TableExampleProps {
  tableData: string;
  width? : number;
}

// Dummy data
const columns: GridColDef[] = [
  { field: 'id', headerName: 'Fund ID', width: 120 },
  {
    field: 'fundName',
    headerName: 'Fund Name',
    width: 280,
    editable: false,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 150,
    editable: false,
  },
];

const transactionColumns: GridColDef[] = [
  { field: 'id', headerName: 'Date', width: 180, valueFormatter: (params) => {
      return format(new Date(params.value * 1000), 'dd-MM-yyyy') //convert sorted unix times back into readable dates
    } },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    editable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    editable: false,
    valueFormatter: (params) => {
      const amount = params.value
      const isNegative = amount < 0
      const sign = isNegative ? '-' : '+'
      return `${sign} $${Math.abs(amount)}`
    }
  },
  {
    field: 'balance',
    headerName: 'Balance',
    width: 150,
    editable: false,
  },
];

const getUnixTime = (date: Date) => { //get unix timestamp for easy numerical sort for dates
  return Math.floor(date.getTime() / 1000)
}


const rows = [
  { id: "MXT", fundName: 'Metrics Master Income Trust', value: "$25,000" },
  { id: "MOT", fundName: 'Metrics Income Opportunities Trust', value: "$15,000" },
  { id: "MDI", fundName: 'Metrics Direct Income Fund', value: "$30,000" },
];

const transactionRows = [
  { id: getUnixTime(new Date(2023, 10, 5)), action: 'Inpayment', amount:  +1217, balance: "$70,000"},
  { id: getUnixTime(new Date(2023, 9, 4)), action: 'Outpayment', amount: -1345, balance: "$68,783" },
  { id: getUnixTime(new Date(2023, 8, 4)), action: 'Inpayment', amount: +1028, balance: "$69,100" },
  { id: getUnixTime(new Date(2023, 7, 4)), action: 'Inpayment', amount: +872, balance: "$68,228"},
  { id: getUnixTime(new Date(2023, 6, 4)), action: 'Inpayment', amount: +982, balance: "$67,246"},
  { id: getUnixTime(new Date(2023, 5, 4)), action: 'Outpayment', amount: -1123, balance: "$68,369" },
];

// ---------------------------------------------------------------------
export default function TableExample({tableData, width} : TableExampleProps) {
  const [data, setData] = useState<Array<any>>([]);
  const [selectedRow, setRow] = useState<number | null>(null);
  const [selectedCol, setCol] = useState<number | null>(null);
  const isTransaction = tableData === "transactions"
  useEffect(() => {
    setData(isTransaction ? transactionRows : rows)
  }, [setData, isTransaction])

  return (
      <>
        <Grid container sx={{
          height: 400,
          minWidth: width || 900,
          width: width || "auto"
        }}>
          <DataTable
              title={isTransaction ? "My transactions" : "My investments"}
              columns={isTransaction ? transactionColumns : columns}
              data={data}
              loading={false}
              search
              showFilter
              showColumns
              hideFooter
              selectedRow={selectedRow}
              selectRow={setRow}
              selectedCol={selectedCol}
              selectCol={setCol}
          />
        </Grid>
      </>
  )
}