// MUI
import {
  AdminPanelSettings,
  Article,
  Dashboard as DashboardIcon,
  Paid,
  Summarize,
  Assessment,
  Description,
} from "@mui/icons-material";
// Components
import { Item } from "./NavSection";
import { PERMISSIONS } from "../../types/PermissionTypes";
//import { userRoleTypes } from '../../features/auth/types';
// Store

// ----------------------------------------------------------------------
const {
  ALL_ADMIN_ROLES,
  ALL_USER_ROLES,
  ACCOUNT_ADMIN_ROLES,
  ALL_USER_ACCOUNT_ROLES,
  INTERNAL_ADMIN_ROLES,
  USER_ROLE_ONLY,
} = PERMISSIONS;

// const landingPageConfig: Item[] = [
//   {

//   }
// ]

const defaultNavConfig: Item[] = [
  {
    title: "Dashboard",
    path: "/portal/dashboard",
    icon: <DashboardIcon />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ALL_USER_ACCOUNT_ROLES,
  },
  // {
  //   title: "My Investments",
  //   path: "/portal/investments",
  //   icon: <Paid />,
  //   requiredUserRoles: USER_ROLE_ONLY,
  //   requiredUserAccountRoles: ACCOUNT_ADMIN_ROLES,
  // },

  {
    title: "Fund Reports",
    path: "/portal/fundReports",
    icon: <Description />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ALL_USER_ACCOUNT_ROLES,
  },
  {
    title: "Account Reports",
    path: "/portal/accountReports",
    icon: <Assessment />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ALL_USER_ACCOUNT_ROLES,
  },
  {
    title: "Wholesale Reports",
    path: "/portal/wholesaleReports",
    icon: <Article />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ALL_USER_ACCOUNT_ROLES,
  },
  {
    title: "Investor Memorandums",
    path: "/portal/memorandums",
    icon: <Summarize />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ALL_ADMIN_ROLES,
  },
  {
    title: "Admin",
    path: "/portal/admin",
    icon: <AdminPanelSettings />,
    requiredUserRoles: INTERNAL_ADMIN_ROLES,
    requiredUserAccountRoles: ALL_USER_ACCOUNT_ROLES,
  },
  {
    title: "Account Management",
    path: "/portal/accounts",
    icon: <AdminPanelSettings />,
    requiredUserRoles: USER_ROLE_ONLY,
    requiredUserAccountRoles: ACCOUNT_ADMIN_ROLES,
  },
];

const navConfig: Item[] = defaultNavConfig;

export default navConfig;
