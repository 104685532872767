import { Button } from "@mui/material";

interface OpenInNewTabButtonProps {
  url: string;
  title: string;
  onClick?: any;
}

const OpenInNewTabButton = ({
  url,
  title,
  onClick,
}: OpenInNewTabButtonProps) => {
  return (
    <Button
      size="large"
      onClick={() => {
        onClick && onClick();
        window.open(url);
      }} //update to dynamic url when wholesale fund Automic links available.
      style={{ maxWidth: 296, minHeight: 30 }}
      type="button"
      variant="contained"
    >
      {title}
    </Button>
  );
};

export default OpenInNewTabButton;
