import { generateRandomNumbers } from "@/utils/generalUtils";
import { Grid, Typography, styled, useTheme } from "@mui/material";
import { shuffle } from "lodash";
import React from "react";
import { Doughnut } from "react-chartjs-2";

const ChartWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

const SectorGraph = () => {
  const total = 100;
  const count = 6;
  const minValue = 7;

  const sectorData = generateRandomNumbers(count, total, minValue);
  const theme = useTheme();

  const SECTOR_ALLOCATION = {
    labels: [
      "Consumer Discretionary",
      "IT",
      "Telecommunications",
      "Industries",
      "Healthcare",
      "Energy",
    ],
    datasets: [
      {
        data: shuffle(sectorData),
        backgroundColor: [
          // @ts-ignore
          theme.palette.chart.blue[1],
          // @ts-ignore
          theme.palette.chart.blue[0],
          theme.palette.primary.light,
          theme.palette.secondary.light,
          theme.palette.primary.main,
          theme.palette.secondary.main,
        ],
      },
    ],
  };
  return (
    <Grid xs={8} md={6} lg={4}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" mb={2}>
          Sector Allocation
        </Typography>
        <ChartWrapper>
          <Doughnut data={SECTOR_ALLOCATION} />
        </ChartWrapper>
      </div>
    </Grid>
  );
};

export default SectorGraph;
