// @mui
import { styled } from "@mui/material/styles";
import { Card, Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";

// components
import Page from "../../../components/Page";
import Logo from "../../../components/Logo";
import LoginForm from "../components/LoginForm";
import { UserRoleType, useCurrentUser } from "@/hooks/useCurrentUser";
import { useEffect } from "react";
import { redirect, useNavigate } from "react-router";
import { userRoleTypes } from "@/features/auth/types";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  //const smUp = useResponsive('up', 'sm');
  const user = useCurrentUser();
  const navigate = useNavigate();

  const mdUp = useResponsive("up", "md");
  useEffect(() => {
    if (user?.id) {
      if (user?.role === UserRoleType.ADMIN) {
        navigate("/portal/admin");
      } else {
        navigate("/portal/landing");
      }
    }
  }, [user]);

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo white={false} />

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )} */}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in to the Investor Portal
            </Typography>

            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Enter your details below.
            </Typography>

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
             
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
