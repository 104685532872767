import { HeaderSort } from "@/components/PaginationTable/PaginationTable";
import { formatHeaderName } from "@/utils/generalUtils";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React from "react";

interface PaginationHeaderProps {
  sortedHeaders?: HeaderSort[];
  handleRequestSort?: (header: HeaderSort) => void;
}

const tempBypassHeaders = [
  "file_name",
  "uploader",
  "upload_date",
  "memorandums",
  "fund_reports",
  "wholesale_reports",
  "action",
];

const PaginationHeader = ({
  sortedHeaders,
  handleRequestSort,
}: PaginationHeaderProps) => {
  return (
    <>
      <TableHead>
        <TableRow>
          {sortedHeaders
            ?.filter((header) => !header.headerName.includes("id"))
            .map((header) => (
              <TableCell key={header.headerName} style={{ width: "20%" }}>
                {tempBypassHeaders.includes(header.headerName) ? (
                  <span>
                    {formatHeaderName({ headerName: header.headerName })}
                  </span>
                ) : (
                  <TableSortLabel
                    active={header.sortEnabled}
                    direction={header.sortDirection || "asc"}
                    onClick={() =>
                      handleRequestSort && handleRequestSort(header)
                    }
                  >
                    {formatHeaderName({ headerName: header.headerName })}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    </>
  );
};

export default PaginationHeader;
