import { apiGetRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReportCategories } from "./useReports";

export enum UserAccountRoleType {
  OWNER = "owner",
  MANAGER = "manager",
  ADVISOR = "advisor",
  TAX = "tax",
}


export interface Role {
  id: string;
  name: UserAccountRoleType;
  viewable_reports: ReportCategories[];
}

interface useRolesQueryProps {
  accountId: string | null;
}

export const useRolesQuery = ({ accountId }: useRolesQueryProps) =>
  useQuery({
    queryKey: ["roles", accountId],
    queryFn: async () =>
      await apiGetRequest<Role[]>({
        query: `newRoles?accountId=${accountId}`,
      }),
    retry: false,
  });

