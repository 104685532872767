import OpenInNewTabButton from "@/components/OpenInNewTabButton";
import Page from "@/components/Page";
import { Typography } from "@mui/material";

const AccountReports = () => {
  return (
    <Page title="Account Reports">
      <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
        Account Reports
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <span>To view account reports, please go to automic</span>
        <OpenInNewTabButton
          title="View in automic portal"
          url="https://automicgroup.com.au"
        />
      </div>
    </Page>
  );
};

export default AccountReports;
