import { FormControl, MenuItem, Select } from "@mui/material";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router";

const AccountDropdown = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useCurrentUser();
  let { accountId: currentAccountParam } = useParams();

  const currentAccountId = currentAccountParam || "";
  const accounts = user?.roles;
  const formattedAccounts =
    accounts &&
    accounts
      .filter((acc) => !acc.archived && acc.roles.length > 0)
      .map((account) => ({
        label: account.account,
        value: account.account_id,
        archived: account.archived,
      }));

  //console.log("acc", accounts);

  const handleRedirect = (newAccountId: string) => {
    if (newAccountId) {
      const newPathname = pathname.replace(/\/([^/]+)\/?$/, `/${newAccountId}`);
      navigate(newPathname);
    }
  };

  return (
    <FormControl>
      {currentAccountId ? (
        <Select
          style={{ color: "black", backgroundColor: "white", marginRight: 15 }}
          value={currentAccountId}
          onChange={(e) => {
            handleRedirect(e.target.value);
          }}
          disabled={!currentAccountId}
        >
          {formattedAccounts?.map((account) => (
            <MenuItem key={account.value} value={account.value}>
              {account.label}
            </MenuItem>
          ))}
          {!currentAccountId ? (
            <MenuItem value={""}>No accounts available</MenuItem>
          ) : null}
        </Select>
      ) : null}
    </FormControl>
  );
};

export default AccountDropdown;
