import { format, isBefore, isSameDay, isValid } from "date-fns";

// Checks if string is a valid date
export function checkValidDate(date: string | Date | null): boolean {
    return isValid(date);
}

// Checks if date one is before date two
export function checkDateBefore(date: string | Date, dateAfter: string | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(date, dateAfter);
}

// Checks if date one is same or before date two
export function checkDateSameOrBefore(date: string | Date, dateAfter: string | Date): boolean {
    if (typeof date === 'string') date = new Date(date);
    if (typeof dateAfter === 'string') dateAfter = new Date(dateAfter);
    return isBefore(date, dateAfter) || isSameDay(date, dateAfter);
}

// Formats date to string
export function formatDate(date: Date | string, dateFormat = 'dd-MM-yyyy'): string {
    if (!!date && date !== '') {
        if (typeof date === 'string') {
            return format(new Date(date), dateFormat)
        } else {
            return format(date, dateFormat)
        }
    } else {
        return '';
    }
}

// Add days to date
export function addDays(date: Date | string, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

// Date sort comparator
export function dateSortComparator(a: Date | string, b: Date | string) {
    if (checkDateBefore(a, b)) return -1;
    else if (checkDateBefore(b, a)) return 1;
    else return 0
}

// Find next Day of the Week
export function findDayOfTheWeek(date: string | Date, dayOfWeek: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + (dayOfWeek - date.getDay()));
    return date;
}

// Finds last week day of month
export function findLastBusDayMonth(date: string | Date): Date {
    date = new Date(date);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    while (lastDate.getDay() < 1 || lastDate.getDay() > 5) {
        lastDate.setDate(lastDate.getDate() - 1);
    }
 
    return lastDate;
}

// Coverts DMY date format with "=" to a Date string
export function convertDMYDate(date: string) {
    const dateParts = date.split('-');

    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]).toDateString()
}