import { Grid, Stack } from "@mui/material";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { Formik, FormikProps } from "formik";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "@tanstack/react-query";
import { Account, useAddNewAccount } from "@/hooks/useAccounts";
import * as Yup from "yup";

interface AddAccountProps {
  accounts: Account[];
  setAddAccountModalOpen: (modalOpen: boolean) => void;
}

const AddAccount = ({ accounts, setAddAccountModalOpen }: AddAccountProps) => {
  const initialValues = { newAccountName: "" };

  const addAccount = useAddNewAccount();
  const queryClient = useQueryClient();

  const { isPending: addingAccount, isSuccess: addedAccount } = addAccount;

  const accountNames = accounts.map((acc) => acc.name);

  const AddAccountSchema = Yup.object().shape({
    newAccountName: Yup.string()
      .required("Account Name is required")
      .notOneOf(accountNames, "Account Name already exists"),
  });

  useEffect(() => {
    if (addedAccount) {
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      queryClient.invalidateQueries({ queryKey: ["accountsByPage"] });
      setAddAccountModalOpen(false);
    }
  }, [addedAccount]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddAccountSchema}
      onSubmit={async (values: any, { resetForm }) => {
        const { newAccountName } = values;

        addAccount.mutate({
          newAccountName,
        });
        resetForm();
      }}
    >
      {(props: FormikProps<any>) => {
        const { handleSubmit, values, errors } = props;
        const newAccountName = values.newAccountName;
        console.log("test", errors);
        const disabledButton =
          !newAccountName || addingAccount || !!errors.newAccountName;
        return (
          <>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="newAccountName"
                    label="Account name"
                    fieldType={InputTypes.STRING}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                  />
                </Stack>
                <LoadingButton
                  loading={addingAccount}
                  size="large"
                  style={{
                    maxWidth: 296,
                    marginLeft: 6,
                    minHeight: 30,
                    marginTop: 30,
                  }}
                  type="submit"
                  disabled={disabledButton}
                  variant="contained"
                >
                  Add account
                </LoadingButton>
              </form>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default AddAccount;
