import useCheckboxes, { Checkbox } from "@/hooks/useCheckboxes";
import { Fund } from "@/hooks/useFunds";
import { useUpdatedUserInvestorMemorandums } from "@/hooks/useUsers";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";

interface UserMemorandumCheckboxesProps {
  userMemorandums: string[];
  funds: Fund[];
  userId: string;
}

const compareUserMemorandums = (
  existingMemorandums: string[],
  newMemorandums: string[]
) => {
  const allReports = Array.from(
    new Set([...existingMemorandums, ...newMemorandums])
  );

  const result = allReports.map((report) => {
    const removed =
      existingMemorandums.includes(report) && !newMemorandums.includes(report);
    const added =
      !existingMemorandums.includes(report) && newMemorandums.includes(report);
    return {
      name: report,
      removed,
      added,
    };
  });

  return result;
};

const UserMemorandumCheckboxes = ({
  userMemorandums,
  funds,
  userId,
}: UserMemorandumCheckboxesProps) => {
  const initialCheckboxesState =
    funds && userMemorandums
      ? funds.map((fund) => ({
          id: fund.id,
          label: `${fund.name} (${fund.ticker})`,
          checked: userMemorandums.includes(fund.id) || false,
        }))
      : [];

  const queryClient = useQueryClient();
  const updateUserInvestorMemorandums = useUpdatedUserInvestorMemorandums();
  const {
    isPending: updatingUserInvestorMemorandums,
    isSuccess: userInvestorMemorandumsUpdated,
  } = updateUserInvestorMemorandums;
  const existingMemorandums = initialCheckboxesState
    .filter((investorMemorandum) => investorMemorandum.checked)
    .map((im) => im.id);

  const { checkboxes, handleCheckboxChange } = useCheckboxes({
    initialState: initialCheckboxesState,
  });
  const newMemorandums = checkboxes
    .filter((investorMemorandum) => investorMemorandum.checked)
    .map((im) => im.id);
  const result = compareUserMemorandums(existingMemorandums, newMemorandums);
  const canUpdate = checkboxes && checkboxes.length > 0;

  useEffect(() => {
    if (userInvestorMemorandumsUpdated) {
      queryClient.invalidateQueries({
        queryKey: ["memorandumsByUserId"],
      });
    }
  }, [userInvestorMemorandumsUpdated]);
  return (
    <>
      {checkboxes.map((checkbox, index) => (
        <div
          key={checkbox.id}
          style={{ display: "flex", alignItems: "center", marginTop: 0 }}
        >
          <input
            type="checkbox"
            id={checkbox.id}
            checked={checkbox.checked}
            onChange={() => handleCheckboxChange(index)}
          />
          <label htmlFor={checkbox.id} style={{ marginLeft: "8px" }}>
            {checkbox.label}
          </label>
        </div>
      ))}
      {canUpdate ? (
        <LoadingButton
          onClick={() =>
            updateUserInvestorMemorandums.mutate({
              userId,
              newInvestorMemorandums: newMemorandums,
            })
          }
          loading={updatingUserInvestorMemorandums}
          style={{ marginTop: 12 }}
          variant="contained"
        >
          Update investor memorandums
        </LoadingButton>
      ) : null}
    </>
  );
};

export default UserMemorandumCheckboxes;
