import Panel from "../Panel";
import { Button, Stack, Typography } from "@mui/material";
import { Model } from "@/types/GeneralTypes";
import PaginationWrapper from "../PaginationWrapper";
import { HeaderSort } from "@/components/PaginationTable/PaginationTable";
import { Fund } from "@/hooks/useFunds";
import { Filters } from "@/components/PaginationTableWithAddNewWrapper/PaginationTableWithAddNewWrapper";
import { Account } from "@/hooks/useAccounts";
import { User } from "@/hooks/useUsers";

interface PaginationTableWithAddNewProps {
  handleModalOpen: () => void;
  fetchingPageData: boolean;
  fetchingTotalData: boolean;
  pageData: any;
  totalNumberData: any;
  model: Model;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  currentPage: number;
  setCurrentPage: (newPage: number) => void;
  tableTitle: string;
  addButtonTitle: string;
  handleRequestSort?: (newSort: HeaderSort) => void;
  sortedHeaders?: HeaderSort[];
  filter?: string;
  setFilter?: (newFilter: string) => void;
  filters?: Filters;
  setCreateAccountModalOpen?: (open: boolean) => void;
  setCreateBulkUserModalOpen?: (open: boolean) => void;
  userModalProps?: { funds: Fund[]; accounts: Account[] };
  fromAdmin: boolean;
}

const PaginationTableWithAddNew = ({
  handleModalOpen,
  fetchingPageData,
  fetchingTotalData,
  pageData,
  totalNumberData,
  searchTerm,
  setSearchTerm,
  currentPage,
  setCurrentPage,
  model,
  tableTitle,
  addButtonTitle,
  handleRequestSort,
  sortedHeaders,
  filter,
  setFilter,
  filters,
  setCreateAccountModalOpen,
  setCreateBulkUserModalOpen,
  userModalProps,
  fromAdmin,
}: PaginationTableWithAddNewProps) => {
  const isUser = model === Model.USERS;
  return (
    <Panel>
      <Stack
        direction="row"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
          {tableTitle}
        </Typography>
        {addButtonTitle ? (
          <Button
            onClick={() => handleModalOpen()}
            style={{ marginBottom: 40 }}
            variant="contained"
          >
            + {addButtonTitle}
          </Button>
        ) : null}
        {isUser && setCreateAccountModalOpen ? (
          <div>
            <span>
              <Button
                onClick={() => setCreateAccountModalOpen(true)}
                style={{ marginBottom: 40 }}
                variant="contained"
              >
                + Add new user
              </Button>
            </span>
            {setCreateBulkUserModalOpen ? (
              <span>
                <Button
                  onClick={() => setCreateBulkUserModalOpen(true)}
                  style={{ marginBottom: 40, marginLeft: 10 }}
                  variant="outlined"
                >
                  + Add multiple users
                </Button>
              </span>
            ) : null}
          </div>
        ) : null}
      </Stack>
      <PaginationWrapper
        fromAdmin={fromAdmin}
        fetchingPageData={fetchingPageData}
        fetchingTotalData={fetchingTotalData}
        pageData={pageData}
        totalNumberData={totalNumberData}
        model={model}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleRequestSort={handleRequestSort}
        sortedHeaders={sortedHeaders}
        filter={filter}
        setFilter={setFilter}
        filters={filters}
        userModalProps={userModalProps}
      />
    </Panel>
  );
};

export default PaginationTableWithAddNew;
