import { Grid, Stack } from "@mui/material";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { Formik, FormikProps } from "formik";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "@tanstack/react-query";
import { useAddNewFund, Fund } from "@/hooks/useFunds";
import * as Yup from "yup";

interface AddFundProps {
  funds: Fund[];
  setAddFundModalOpen: (modalOpen: boolean) => void;
}

const AddFund = ({ funds, setAddFundModalOpen }: AddFundProps) => {
  const initialValues = { newFundName: "" };

  const addFund = useAddNewFund();
  const queryClient = useQueryClient();

  const { isPending: addingFund, isSuccess: addedFund } = addFund;

  useEffect(() => {
    if (addedFund) {
      queryClient.invalidateQueries({ queryKey: ["funds"] });
      queryClient.invalidateQueries({ queryKey: ["fundsByPage"] });
      queryClient.invalidateQueries({ queryKey: ["numberFunds"] });
      setAddFundModalOpen(false);
    }
  }, [addedFund]);

  const fundNames = funds.map((fund) => fund.name);
  const fundTickers = funds.map((fund) => fund.ticker);

  const AddFundSchema = Yup.object().shape({
    newFundName: Yup.string()
      .required("Fund Name is required")
      .notOneOf(fundNames, "Fund Name already exists"),
    newFundTicker: Yup.string()
      .required("Fund ticker is required")
      .notOneOf(fundTickers, "Fund ticker already exists"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddFundSchema}
      onSubmit={async (values: any, { resetForm }) => {
        const { newFundName, newFundTicker } = values;

        addFund.mutate({
          newFundName,
          newFundTicker,
        });
        resetForm();
      }}
    >
      {(props: FormikProps<any>) => {
        const { handleSubmit, values, errors } = props;
        const newFundName = values.newFundName;

        const disabledButton =
          !newFundName ||
          addingFund ||
          !!errors.newFundName ||
          !!errors.newFundTicker;
        return (
          <>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="newFundName"
                    label="Fund name"
                    fieldType={InputTypes.STRING}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="newFundTicker"
                    label="Ticker"
                    fieldType={InputTypes.STRING}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                  />
                </Stack>
                <LoadingButton
                  loading={addingFund}
                  size="large"
                  style={{
                    maxWidth: 296,
                    marginLeft: 6,
                    minHeight: 30,
                    marginTop: 30,
                  }}
                  type="submit"
                  disabled={disabledButton}
                  variant="contained"
                >
                  Add fund
                </LoadingButton>
              </form>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default AddFund;
