import { Stack, Typography, styled } from "@mui/material";
import Page from "../../../components/Page";
import { useState } from "react";

import PaginationTableWithAddNewWrapper from "@/components/PaginationTableWithAddNewWrapper";
import { Model } from "@/types/GeneralTypes";

const Panel = styled("div")`
  padding: 15px;
  background-color: white;
  box-shadow: rgba(254, 205, 211, 0.1) 0px 4px 16px,
    rgba(254, 205, 211, 0.1) 0px 8px 24px,
    rgba(254, 205, 211, 0.1) 0px 16px 56px;
`;

const AccountManagement = () => {
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);

  return (
    <Page title="Account Management">
      <Stack
        direction="row"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="h4" sx={{ mb: 5, color: "primary.main" }}>
          Account management
        </Typography>
      </Stack>
      <PaginationTableWithAddNewWrapper
        accounts={[]}
        funds={[]}
        createAccountModalOpen={createAccountModalOpen}
        setCreateAccountModalOpen={setCreateAccountModalOpen}
        model={Model.USERS}
        paginationTableTitle="Users"
        addModelModalButtonTitle=""
      />
    </Page>
  );
};

export default AccountManagement;
