import { ViewableReport } from "@/hooks/useReports";
import { apiGetRequest, apiGetRequestTest, apiPostRequest } from "@/utils/apiUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export interface Account {
  id: string;
  name: string;
}

export const useAccountsQuery = () =>
  useQuery({
    queryKey: ["accounts"],
    queryFn: async () =>
      await apiGetRequest<Account[]>({
        query: `newAccounts?getAll="true"`,
      }),
    retry: false,
  });

export interface Account {
  id: string;
  name: string;
}

export const useAccountsQueryForAccountId = ({ accountId }: { accountId: string | null }) =>
  useQuery({
    queryKey: ["accounts", accountId],
    queryFn: async () =>
      await apiGetRequest<Account>({
        query: `newAccounts?accountId=${accountId}`,
      }),
    retry: false,
    enabled: accountId ? true : false
  });


interface useAccountsByPageQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
}

interface usePaginationAccountsQueryProps {
  pageNumber: number;
  itemsPerPage: number;
  searchString: string;
  filter: string;
  sort: string;
}

interface PaginationAccountsReturn {
  count: number;
  data: Account[]
}

export const usePaginationAccountsQuery = ({
  pageNumber,
  itemsPerPage,
  searchString,
  filter,
  sort
}: usePaginationAccountsQueryProps) =>
  useQuery({
    queryKey: ["accountsByPage", pageNumber, itemsPerPage, searchString, filter, sort],
    queryFn: async () =>
      await apiGetRequestTest<PaginationAccountsReturn>({
        query: `accounts?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}&filter=${filter}&sort=${sort}`,
      }),
    retry: false,
  });



interface useAddNewAccountProps {
  newAccountName: string;
}

export const useAddNewAccount = () =>
  useMutation({
    mutationFn: ({ newAccountName }: useAddNewAccountProps) => {
      return apiPostRequest<string>({
        query: `newAccounts?newAccountName=${newAccountName}`,
      });
    },
    onSuccess: () => {
      toast.success("New account added");
    },
  });

