import {
  UserAccount,
  UserAccountRole,
  ViewableReportType,
  useArchiveUserAccount,
  useUnarchiveUserAccount,
  useUpdateUserAccountRoles,
} from "@/hooks/useUserAccounts";
import {
  Container,
  Typography,
  Stack,
  Box,
  Button,
  Modal,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RestoreIcon from "@mui/icons-material/Restore";

import Tag from "@/components/Tag";
import ClickableTag from "@/components/ClickableTag";
import { useEffect, useState } from "react";
import { ReportCategories, ReportType } from "@/hooks/useReports";
import { Role, UserAccountRoleType } from "@/hooks/useRoles";
import { LoggedInUserAccount } from "@/hooks/useCurrentUser";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "@tanstack/react-query";

interface EditUserTableProps {
  userAccounts: UserAccount[];
  roles: Role[];
  fromAdmin: boolean;
}

interface NewUserAccountRole {
  id: string;
  name: string;
  selected: boolean;
}

const stableSort = (
  array: UserAccount[],
  comparator: (a: UserAccount, b: UserAccount) => number
) => {
  const stabilizedThis = array.map((el, index) => ({ el, index }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((el) => el.el);
};

//This determines whether a report has "gained" or "lost" visibility to the currently in progress edited user account
const compareReports = (existingReports: string[], newReports: string[]) => {
  const allReports = Array.from(new Set([...existingReports, ...newReports]));

  const result = allReports.map((report) => {
    const removed =
      existingReports.includes(report) && !newReports.includes(report);
    const added =
      !existingReports.includes(report) && newReports.includes(report);
    return {
      name: report,
      removed,
      added,
    };
  });

  return result;
};

interface generateUpdatedReportVisibilityTagsProps {
  newRoles: NewUserAccountRole[];
  existingViewableReports: ViewableReportType[];
  roles: Role[];
}

const generateUpdatedReportVisibilityTags = ({
  newRoles,
  existingViewableReports,
  roles,
}: generateUpdatedReportVisibilityTagsProps) => {
  const newViewableReports = new Set<string>();
  newRoles.forEach((newRole) => {
    const foundRole = roles.find((role) => role.id === newRole.id);
    if (foundRole && newRole.selected) {
      foundRole.viewable_reports.forEach((report) => {
        newViewableReports.add(report);
      });
    }
  });
  const formattedNewViewableReports = Array.from(newViewableReports);
  const newReports = compareReports(
    existingViewableReports.map(
      (existingViewableReport) => existingViewableReport.name
    ),
    formattedNewViewableReports
  );
  return newReports;
};

const renderNonEditedRoleTags = ({
  userAccountRoles,
}: {
  userAccountRoles: UserAccountRole[];
}) => {
  return (
    <>
      {userAccountRoles.map((userAccountRole) => (
        <Tag key={userAccountRole.id}>{userAccountRole.name}</Tag>
      ))}
    </>
  );
};

const renderEditedRoleTags = ({
  newUserAccountRoles,
  handleRoleClick,
}: {
  newUserAccountRoles: NewUserAccountRole[];
  handleRoleClick: (roleId: string) => void;
}) => {
  return (
    <>
      {newUserAccountRoles.map((role) => (
        <ClickableTag
          key={role.id}
          onClick={() => handleRoleClick(role.id)}
          selected={role.selected}
        >
          {role.name}
        </ClickableTag>
      ))}
    </>
  );
};

const renderNonEditedViewableReportTypeTags = ({
  viewable_report_types,
}: {
  viewable_report_types: ViewableReportType[];
}) => {
  return (
    <>
      {viewable_report_types.map((viewable_report_type) => (
        <Tag key={viewable_report_type.id}>{viewable_report_type.name}</Tag>
      ))}
    </>
  );
};

const renderEditedViewableReportTypeTags = ({
  viewable_report_types,
  newUserAccountRoles,
  roles,
}: {
  viewable_report_types: ViewableReportType[];
  newUserAccountRoles: NewUserAccountRole[];
  roles: Role[];
}) => {
  const updatedTags = generateUpdatedReportVisibilityTags({
    existingViewableReports: viewable_report_types,
    newRoles: newUserAccountRoles,
    roles: roles,
  });
  return (
    <>
      {updatedTags.map((updatedTag) => (
        <Tag
          key={updatedTag.name}
          newlyRemoved={updatedTag.removed}
          newlyAdded={updatedTag.added}
        >
          {updatedTag.name}
        </Tag>
      ))}
    </>
  );
};

const EditUserTable = ({
  userAccounts,
  roles,
  fromAdmin,
}: EditUserTableProps) => {
  const [orderBy, setOrderBy] = useState<string>(""); // Field to be sorted
  const [order, setOrder] = useState<"asc" | "desc">("asc"); // Sorting order ('asc' or 'desc'

  const queryClient = useQueryClient();

  const [editingUserAccount, setEditingUserAccount] = useState<string | null>(
    null
  ); //sets which user account is been edited

  const updateUserAccountRoles = useUpdateUserAccountRoles();

  const refetchUserAccounts = () => {
    setEditingUserAccount(null);
    queryClient.invalidateQueries({
      queryKey: fromAdmin
        ? ["userAccountsByUser"]
        : ["userAccountByUserAndAccount"],
    });
  };

  const {
    isPending: updatingUserAccountRoles,
    isSuccess: userAccountRolesUpdated,
  } = updateUserAccountRoles;

  const archiveUserAccount = useArchiveUserAccount();

  const { isPending: archivingUser, isSuccess: userArchived } =
    archiveUserAccount;

  const unarchiveUserAccount = useUnarchiveUserAccount();
  const { isPending: unarchivingUser, isSuccess: userUnarchived } =
    unarchiveUserAccount;

  const userAccountUpdateInProgress =
    updatingUserAccountRoles || archivingUser || unarchivingUser;

  useEffect(() => {
    if (userAccountRolesUpdated || userArchived || userUnarchived) {
      refetchUserAccounts();
    }
  }, [userAccountRolesUpdated, userArchived, userUnarchived]);

  const [newUserAccountRoles, setNewUserAccountRoles] = useState<
    NewUserAccountRole[]
  >([]);

  const handleRoleClick = (roleId: string) => {
    const roleName = roles.find((role) => role.id === roleId)?.name;
    if (roleName) {
      let updatedRoles: NewUserAccountRole[];

      if (
        roleName === UserAccountRoleType.MANAGER ||
        roleName === UserAccountRoleType.OWNER
      ) {
        // For Manager or Owner role, set all other roles to unselected
        updatedRoles = newUserAccountRoles.map((role) => ({
          ...role,
          selected: role.id === roleId && !role.selected,
        }));
      } else {
        // For other roles, toggle the selected state of the clicked role
        updatedRoles = newUserAccountRoles.map((role) => ({
          ...role,
          selected:
            role.id === roleId
              ? !role.selected
              : role.name === UserAccountRoleType.MANAGER ||
                role.name === UserAccountRoleType.OWNER
              ? false
              : role.selected,
        }));
      }

      setNewUserAccountRoles(updatedRoles);
    }
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedUserAccounts = stableSort(
    userAccounts,
    getComparator(order, orderBy)
  );

  const noUserAccounts = sortedUserAccounts.length === 0;
  const handleEditUserAccount = ({ userAccount }: { userAccount: string }) => {
    if (editingUserAccount === userAccount) {
      //finish editing has been clicked
      const newRoleIds = newUserAccountRoles
        .filter((newAccountRole) => newAccountRole.selected)
        .map((role) => role.id);
      updateUserAccountRoles.mutate({
        userAccountId: userAccount,
        newRoles: newRoleIds,
      });
    } else {
      //start editing user account
      setEditingUserAccount(userAccount);
      const editedUserAccount = userAccounts.find(
        (account) => account.user_account_id === userAccount
      );
      if (editedUserAccount) {
        const existingRoles = roles.map((role) => {
          const selected = editedUserAccount.roles.some(
            (userRole) => userRole.id === role.id
          );

          return {
            id: role.id,
            name: role.name,
            selected,
          };
        });
        setNewUserAccountRoles(existingRoles);
      }
    }
  };

  function getComparator(order: "asc" | "desc", orderBy: string) {
    return order === "desc"
      ? (a: UserAccount, b: UserAccount) => descendingComparator(a, b, orderBy)
      : (a: UserAccount, b: UserAccount) =>
          -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(
    a: UserAccount,
    b: UserAccount,
    orderBy: string
  ) {
    if (b[orderBy as keyof UserAccount]! < a[orderBy as keyof UserAccount]!) {
      return -1;
    }
    if (b[orderBy as keyof UserAccount]! > a[orderBy as keyof UserAccount]!) {
      return 1;
    }
    return 0;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "account_name"}
                direction={orderBy === "account_name" ? order : "asc"}
                onClick={() => handleRequestSort("account_name")}
              >
                Account Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Viewable reports</TableCell>
            <TableCell>Archived</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {noUserAccounts ? (
            <div style={{ marginLeft: 8, fontWeight: "bold" }}>
              No user accounts available
            </div>
          ) : null}
          {sortedUserAccounts.map((userAccount) => {
            const accountBeenEdited =
              userAccount.user_account_id === editingUserAccount;
            return (
              <TableRow
                key={userAccount.account_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {`${userAccount.account_name}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {userAccount.roles
                    ? accountBeenEdited
                      ? renderEditedRoleTags({
                          newUserAccountRoles,
                          handleRoleClick,
                        })
                      : renderNonEditedRoleTags({
                          userAccountRoles: userAccount.roles,
                        })
                    : null}
                </TableCell>
                <TableCell component="th" scope="row">
                  {userAccount.viewable_report_types
                    ? accountBeenEdited
                      ? renderEditedViewableReportTypeTags({
                          newUserAccountRoles,
                          roles,
                          viewable_report_types:
                            userAccount.viewable_report_types,
                        })
                      : renderNonEditedViewableReportTypeTags({
                          viewable_report_types:
                            userAccount.viewable_report_types,
                        })
                    : null}
                </TableCell>
                <TableCell>
                  {userAccount.archived ? <CheckCircleIcon /> : null}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", gap: 12, flexWrap: "wrap" }}>
                    <LoadingButton
                      variant="outlined"
                      disabled={
                        (!fromAdmin &&
                          userAccount.roles
                            .map((role) => role.name)
                            .includes(UserAccountRoleType.MANAGER)) ||
                        (editingUserAccount && !accountBeenEdited)
                          ? true
                          : false
                      }
                      onClick={() =>
                        handleEditUserAccount({
                          userAccount: userAccount.user_account_id,
                        })
                      }
                      loading={
                        accountBeenEdited && updatingUserAccountRoles
                          ? true
                          : false
                      }
                    >
                      {accountBeenEdited ? (
                        <>
                          <SaveAsIcon />{" "}
                          <span style={{ marginLeft: 6 }}>Finish Editing</span>
                        </>
                      ) : (
                        <>
                          <BorderColorIcon />{" "}
                          <span style={{ marginLeft: 6 }}>Edit User</span>
                        </>
                      )}
                    </LoadingButton>
                    {accountBeenEdited ? (
                      <Button
                        variant="outlined"
                        disabled={userAccountUpdateInProgress}
                        onClick={() => setEditingUserAccount(null)}
                      >
                        <CancelIcon />
                        <span style={{ marginLeft: 6 }}>Cancel edit</span>
                      </Button>
                    ) : null}
                    {accountBeenEdited ? (
                      <LoadingButton
                        variant="outlined"
                        disabled={userAccountUpdateInProgress}
                        loading={
                          archivingUser || unarchivingUser ? true : false
                        }
                        onClick={() => {
                          if (userAccount.archived) {
                            if (
                              window.confirm(
                                `Are you sure you wish to unarchive user ${userAccount.username} for account ${userAccount.account_name}?`
                              )
                            ) {
                              unarchiveUserAccount.mutate({
                                userAccountId: editingUserAccount,
                              });
                            }
                          } else {
                            if (
                              window.confirm(
                                `Are you sure you wish to archive user ${userAccount.username} for account ${userAccount.account_name}?`
                              )
                            ) {
                              archiveUserAccount.mutate({
                                userAccountId: editingUserAccount,
                              });
                            }
                          }
                        }}
                      >
                        {userAccount.archived ? (
                          <RestoreIcon />
                        ) : (
                          <DeleteIcon />
                        )}
                        <span style={{ marginLeft: 6 }}>
                          {userAccount.archived ? "Restore" : "Archive"} user
                        </span>
                      </LoadingButton>
                    ) : null}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditUserTable;
