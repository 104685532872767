import { Box, Modal, Typography } from "@mui/material";
import SignupForm from "../../auth/components/SignupForm";
import {
  useAccountsQuery,
  useAccountsQueryForAccountId,
} from "@/hooks/useAccounts";
import { useRolesQuery } from "@/hooks/useRoles";
import Spinner from "@/components/Spinner";
import { useParams } from "react-router";
import { Fund, useFundsQuery } from "@/hooks/useFunds";
import { ensureArray } from "@/utils/generalUtils";

// interface SignupFormProps {
//     loading: boolean,
//     accounts: Account[],
//     roles: Role[],
//     updateValues?: any
// }

interface NewAccountModalProps {
  open: boolean;
  handleClose: any;
  fromAdmin?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  overflowY: "scroll",
};

const NewAccountModal = ({
  open,
  handleClose,
  fromAdmin = false,
}: NewAccountModalProps) => {
  let { accountId: currentAccountParam } = useParams();
  const currentAccount = currentAccountParam || null;

  const { isFetching: fetchingAccounts, data: accounts } = fromAdmin
    ? useAccountsQuery()
    : useAccountsQueryForAccountId({ accountId: currentAccount });

  const formattedAccounts = ensureArray(accounts);
  const { isFetching: fetchingRoles, data: roles } = useRolesQuery({
    accountId: currentAccount,
  });

  const { isFetching: fetchingFunds, data: funds } = useFundsQuery({
    enabled: fromAdmin,
  });

  const fetchingSignupFormData =
    fetchingRoles || fetchingAccounts || fetchingFunds;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
          Create new account
        </Typography>
        {fetchingSignupFormData ? (
          <Spinner loading={fetchingSignupFormData} />
        ) : roles && accounts && formattedAccounts ? (
          <SignupForm
            accounts={
              fromAdmin
                ? formattedAccounts
                : formattedAccounts.filter(
                    (account) => account.id === currentAccount
                  )
            }
            handleClose={handleClose}
            initialSelected={fromAdmin ? null : currentAccount}
            roles={roles}
            funds={funds}
            fromAdmin={fromAdmin}
          />
        ) : null}

        {/* {
            roles && accountId ? 
            <SignupForm loading={loading} accounts={[]} roles={roles}/>
            : null
        } */}
      </Box>
    </Modal>
  );
};

export default NewAccountModal;
