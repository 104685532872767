import { Button, Grid, Stack } from "@mui/material";
import FormInput from "../../../../components/FormInput";
import { InputTypes } from "../../../../types/InputTypes";
import { Formik, FormikProps } from "formik";
import Spinner from "../../../../components/Spinner";
import { formatDate } from "../../../../utils/DateUtils";
import { Account } from "@/hooks/useAccounts";
import {
  ReportCategories,
  ReportType,
  useUploadWholesaleReport,
} from "@/hooks/useReports";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Model } from "@/types/GeneralTypes";
import ExistingFileUploadWarningModal from "../ExistingFileUploadWarningModal";
import { LoadingButton } from "@mui/lab";

interface UploadWholesaleReportProps {
  accounts: Account[];
  reportTypes: ReportType[];
  setAddReportModalOpen: (modalOpen: boolean) => void;
  model: Model;
}

const UploadWholesaleReport = ({
  accounts,
  reportTypes,
  model,
  setAddReportModalOpen,
}: UploadWholesaleReportProps) => {
  const initialValues = { file: [], report: "", account: "", period: null };
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  const uploadReport = useUploadWholesaleReport();

  const { isPending: uploadingReport, isSuccess: reportUploaded } =
    uploadReport;

  const [existingFileWarningModalOpen, setExistingFileWarningModalOpen] =
    useState(false);

  useEffect(() => {
    if (reportUploaded) {
      queryClient.invalidateQueries({ queryKey: ["wholesaleReportsByPage"] });
      queryClient.invalidateQueries({ queryKey: ["numberWholesaleReports"] });
      setAddReportModalOpen(false);
    }
  }, [reportUploaded]);

  const formattedReportTypes = reportTypes.map((reportType) => ({
    value: reportType.id,
    label: reportType.name,
  }));

  const formattedAccounts = accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }));

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: any, { resetForm }) => {
        const file = values.file[0];
        const reportType = values.report;
        const account = values.account;
        const period = values.period;
        const reportTypeName = formattedReportTypes.find(
          (report) => report.value === reportType
        )?.label;
        // const showYearly =
        //   reportTypeName === ReportCategories.FINANCIAL_YEAR ||
        //   reportTypeName === ReportCategories.TAX;

        const showMonthly = reportTypeName === ReportCategories.EXPOSURE;
        const accountName = formattedAccounts.find(
          (formattedAccount) => formattedAccount.value === account
        )?.label;
        const reportName = formattedReportTypes.find(
          (formattedReport) => formattedReport.value === reportType
        )?.label;

        const name = `${accountName}-${reportName}-${formatDate(
          period,
          showMonthly ? "MM/yyyy" : "yyyy"
        )}.pdf`;
        const fileData = new FormData();
        const formattedName = name.replaceAll("/", "-");
        fileData.append("file", file);

        const firstMinuteOfReportMonth = period
          ? new Date(
              period.getFullYear(),
              showMonthly ? period.getMonth() : 0,
              1,
              0,
              0,
              0,
              0
            )
          : null;
        console.log("period", period, firstMinuteOfReportMonth);

        //queryClient.invalidateQueries({ queryKey: ["reportUploadUrl"] });
        if (firstMinuteOfReportMonth) {
          uploadReport.mutate({
            accountId: account,
            fileData,
            fileKey: formattedName,
            name: formattedName,
            period: firstMinuteOfReportMonth.toString(),
            reportId: reportType,
            userId: user?.id || null,
          });
          resetForm();
        }
      }}
    >
      {(props: FormikProps<any>) => {
        const { handleSubmit, values } = props;
        const file = values.file;
        const reportId = values.report;
        const reportTypeName = formattedReportTypes.find(
          (report) => report.value === reportId
        )?.label;
        // const showYearly =
        //   reportTypeName === ReportCategories.FINANCIAL_YEAR ||
        //   reportTypeName === ReportCategories.TAX;

        const showMonthly = reportTypeName === ReportCategories.EXPOSURE;
        const period = values.period;

        const accountId = values.account;
        const disabledButton =
          !file ||
          file.length !== 1 ||
          !accountId ||
          !reportId ||
          !period ||
          uploadingReport;
        return (
          <>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="account"
                    label="Select account"
                    fieldType={InputTypes.SELECTION}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    values={formattedAccounts}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="report"
                    label="Select report"
                    fieldType={InputTypes.SELECTION}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    values={formattedReportTypes}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="period"
                    label="Period"
                    fieldType={InputTypes.DATE}
                    layout={{ xs: 6, md: 6, lg: 6 }}
                    views={showMonthly ? ["month", "year"] : ["year"]}
                    inputFormat={showMonthly ? "MM/yyyy" : "yyyy"}
                  />
                </Stack>
                <Stack spacing={3} style={{ maxWidth: 400 }}>
                  <FormInput
                    id="file"
                    label="Upload investor report"
                    fieldType={InputTypes.FILE}
                    multiple={false}
                    info={`Upload report of type: ${formattedReportTypes[reportId]?.label}`}
                    fileType={[".pdf"]}
                  />
                </Stack>
                <LoadingButton
                  size="large"
                  style={{
                    maxWidth: 296,
                    marginLeft: 6,
                    minHeight: 30,
                    marginTop: 30,
                  }}
                  loading={uploadingReport}
                  type="submit"
                  disabled={disabledButton}
                  variant="contained"
                >
                  Upload file
                </LoadingButton>
                {/* <Button
                  variant="contained"
                  onClick={() => setExistingFileWarningModalOpen(true)}
                >
                  Hello
                </Button>
                <ExistingFileUploadWarningModal
                  model={model}
                  open={existingFileWarningModalOpen}
                  setModalOpen={setExistingFileWarningModalOpen}
                /> */}
              </form>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default UploadWholesaleReport;
