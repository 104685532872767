export type alignment = "left" | "center" | "right";

export type Order = "asc" | "desc";

export interface Dropdown {
  label: string;
  value: number;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface TableData<T extends RecordType> {
  headers: string[];
  body: T[];
}

export type RecordType = Record<string, any>;

export enum Model {
  ACCOUNTS = "accounts",
  USERS = "users",
  FUNDS = "funds",
  USER_ACCOUNTS = "userAccounts",
  ROLES = "roles",
  MEMORANDUMS = "memorandums",
  REPORTS = "reports",
  FUND_REPORTS = "fund reports",
}

export enum ModelFilter {
  ACCOUNT = "account",
  USER = "user",
  FUND = "fund",
  USER_ACCOUNT = "user_account",
  ROLES = "role",
  MEMORANDUM = "memorandum",
  REPORT = "report",
  FUND_REPORT = "fund_report",
  REPORT_TYPE = "report_type"
}

export enum Filter {
  START_PERIOD = "start_period",
  END_PERIOD = "end_period",
  START_UPLOAD_DATE = "start_upload_date",
  END_UPLOAD_DATE = "end_upload_date"
}

