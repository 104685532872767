import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

interface TableRowsLoaderProps {
  numberRows: number;
  numberHeaders: number;
}

const TableRowsLoader = ({
  numberRows,
  numberHeaders,
}: TableRowsLoaderProps) => {
  return (
    <>
      {[...Array(numberRows)].map((_, index) => (
        <TableRow key={index}>
          {[...Array(numberHeaders)].map((_, index) => (
            <TableCell key={index} component="th" scope="row">
              <Skeleton
                style={{ width: "50%" }}
                animation="wave"
                variant="text"
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableRowsLoader;
