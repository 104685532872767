import { Account } from "@/hooks/useAccounts";
import { useBulkCreateAccounts } from "@/hooks/useAuth";
import { UserRoleType } from "@/hooks/useCurrentUser";
import { Role } from "@/hooks/useRoles";
import { formatHeaderName } from "@/utils/generalUtils";

import { LoadingButton } from "@mui/lab";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { isValid, parseISO } from "date-fns";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { useEffect, useState } from "react";
import { useCSVReader, formatFileSize } from "react-papaparse";
import AddUserStatusModal from "./AddUserStatusModal";
import { Fund } from "@/hooks/useFunds";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Wrapper = styled("div")`
  .info-container {
    position: relative;
    max-width: 180px;
    margin: 20px 0px;
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 10px;
    color: #808080;
  }

  div > span {
    font-weight: 900;
  }

  .info__remove {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)(({ error }: { error: number }) => ({
  fontWeight: error ? "bold" : "normal",
  color: error ? "red" : "inherit",
}));

interface CSVUserToAdd {
  email: string;
  first_name: string;
  last_name: string;
  birthdate: string;
  [ticker: string]: string;
}

interface FormattedUserToAdd {
  first_name: {
    value: string;
    error: string;
  };
  last_name: {
    value: string;
    error: string;
  };

  birthdate: {
    value: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  [ticker: string]: {
    value: string;
    error: string;
  };
}

interface BulkUserCSVReaderProps {
  funds: Fund[];
}

const validateDateFormat = (date: string) => {
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export interface CreatedAccountStatus {
  error: boolean;
  message: string;
  user: string;
}

export default function BulkUserCSVReader({ funds }: BulkUserCSVReaderProps) {
  const { CSVReader } = useCSVReader();
  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const [headers, setHeaders] = useState<string[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<FormattedUserToAdd[]>([]);
  const hasError = usersToAdd.some((user) =>
    Object.values(user).some((prop) => prop.error)
  );
  const [createdAccountsStatus, setCreatedAccountsStatus] = useState<
    CreatedAccountStatus[]
  >([]);
  const [completedCount, setCompletedCount] = useState(0);
  const queryClient = useQueryClient();
  const knownKeys = ["first_name", "last_name", "email", "birthdate"];

  const handleCloseStatusModal = (_: Event, reason: string) => {
    if (reason !== "backdropClick") {
      //prevent user from closing the status modal before the bulk operation has completed.
      setStatusModalOpen(false);
      setCompletedCount(0);
      setCreatedAccountsStatus([]);
    }
  };

  const usersToAddPayload = !hasError
    ? usersToAdd.map((user) => {
        const tickerKeys = Object.keys(user).filter(
          (key) => !knownKeys.includes(key) && user[key].value === "true"
        );
        console.log(tickerKeys);
        const tickerIds = tickerKeys.map(
          (tickerKey) =>
            funds.find((fund) => fund.ticker === tickerKey)?.id || ""
        );
        return {
          given_name: user.first_name.value,
          family_name: user.last_name.value,
          birthdate: format(new Date(user.birthdate.value), "yyyy-MM-dd"),
          email: user.email.value,
          role: UserRoleType.USER,
          newUserMemorandums: tickerIds,
          newUserAccounts: [],
        };
      })
    : [];
  const bulkSignup = useBulkCreateAccounts();
  const {
    data: createdUsers,
    isPending: bulkSigningUp,
    isSuccess: bulkSignupComplete,
  } = bulkSignup;
  console.log(usersToAddPayload);
  const handleBulkSignup = () => {
    bulkSignup.mutate({
      newUsers: usersToAddPayload,
      setCompletedCount,
    });
  };

  useEffect(() => {
    if (bulkSignupComplete) {
      queryClient.invalidateQueries({
        queryKey: ["userAccountsByUser"],
      });
      const createdAccountsStatus: CreatedAccountStatus[] = createdUsers.map(
        (createdUser, index) => {
          const { status } = createdUser;
          const user = usersToAddPayload[index].email;
          if (status === "fulfilled") {
            return { error: false, user, message: "" };
          } else {
            return {
              error: true,
              user,
              message: `${createdUser?.reason?.message}`,
            };
          }
        }
      );
      setCreatedAccountsStatus(createdAccountsStatus);
    }
  }, [bulkSignupComplete]);

  useEffect(() => {
    if (bulkSigningUp) {
      setStatusModalOpen(true);
    }
  }, [bulkSigningUp]);

  return (
    <Wrapper>
      <CSVReader
        onUploadAccepted={(results: any) => {
          const values: string[][] = results.data;
          const headers = values[0];
          // Remove headers from values
          const data = values.slice(1);
          // Map each row to an object with headers as keys
          const csvUsersToAdd: CSVUserToAdd[] = data
            .filter((datum) => datum.length > 3)
            .map((row) =>
              row.reduce((obj: CSVUserToAdd, value, index) => {
                obj[headers[index] as keyof CSVUserToAdd] = value;
                return obj;
              }, {} as CSVUserToAdd)
            );
          console.log(csvUsersToAdd);
          const formattedUsersToAdd = csvUsersToAdd.map((csvUser) => {
            const tickerKeys = Object.keys(csvUser).filter(
              (key) => !knownKeys.includes(key)
            );
            // Check if any roles are invalid and construct the error message
            const tickers = tickerKeys.flatMap((key) => [
              {
                [key]: {
                  value:
                    csvUser[key] === "TRUE"
                      ? "true"
                      : csvUser[key] === "FALSE" || csvUser[key] === ""
                      ? "false"
                      : "",
                  error: funds.map((fund) => fund.ticker).includes(key)
                    ? csvUser[key] === "TRUE" ||
                      csvUser[key] === "FALSE" ||
                      csvUser[key] === ""
                      ? ""
                      : `Value ${csvUser[key]} is not valid. Please ensure true/false provided`
                    : `Fund ticker: ${key} does not exist`,
                },
              },
            ]);
            return {
              first_name: {
                value: csvUser?.first_name,
                error: csvUser?.first_name ? "" : "No first name",
              },
              last_name: {
                value: csvUser?.last_name,
                error: csvUser?.last_name ? "" : "No last name",
              },
              email: {
                value: csvUser?.email,
                error: csvUser?.email
                  ? csvUser.email.match(emailRegex)
                    ? ""
                    : `email: "${csvUser.email}" is not valid`
                  : "No email",
              },

              birthdate: {
                value: csvUser?.birthdate,
                error: csvUser?.birthdate
                  ? validateDateFormat(csvUser.birthdate)
                    ? ""
                    : `date: "${csvUser.birthdate}" is not valid`
                  : "No birthdate",
              },
              ...Object.assign({}, ...tickers),
            };
          });
          setHeaders(headers);
          setUsersToAdd(formattedUsersToAdd);
        }}
        config={{ worker: true }}
        noDrag
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }: any) => (
          <>
            <div {...getRootProps()}>
              {acceptedFile ? (
                <>
                  <div className="info-container">
                    <div>
                      <p>{acceptedFile.name}</p>
                      <span>{formatFileSize(acceptedFile.size)}</span>
                    </div>
                    <div className="info__progress">
                      <ProgressBar />
                    </div>
                    <div {...getRemoveFileProps()} className="info__remove">
                      <Remove color={"red"} />
                    </div>
                  </div>
                </>
              ) : (
                <Button style={{ margin: "25px 0px" }} variant="contained">
                  Upload CSV
                </Button>
              )}
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ minWidth: 650, maxHeight: 600 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <TableCell key={header}>
                          {formatHeaderName({ headerName: header })}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersToAdd.map((user, index) => (
                      <TableRow key={index}>
                        <StyledTableCell error={+!!user.first_name.error}>
                          {user.first_name.error || user.first_name.value}
                        </StyledTableCell>
                        <StyledTableCell error={+!!user.last_name.error}>
                          {user.last_name.error || user.last_name.value}
                        </StyledTableCell>
                        <StyledTableCell error={+!!user.email.error}>
                          {user.email.error || user.email.value}
                        </StyledTableCell>
                        <StyledTableCell error={+!!user.birthdate.error}>
                          {user.birthdate.error || user.birthdate.value}
                        </StyledTableCell>
                        {Object.entries(user)
                          .filter(([key]) => !knownKeys.includes(key))
                          .map(([key, value]) => (
                            <StyledTableCell key={key} error={+!!value.error}>
                              {value.error ? (
                                value.error
                              ) : value.value === "true" ? (
                                <CheckCircleIcon />
                              ) : null}
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </CSVReader>
      {usersToAdd && usersToAdd.length > 0 ? (
        hasError ? (
          <Tooltip title="Please fix above errors in CSV and reupload">
            <span>
              <Button
                style={{ marginTop: 25 }}
                disabled={hasError}
                variant="contained"
              >
                Bulk create users
              </Button>
            </span>
          </Tooltip>
        ) : (
          <LoadingButton
            style={{ marginTop: 25 }}
            disabled={hasError}
            variant="contained"
            loading={bulkSigningUp}
            onClick={() => handleBulkSignup()}
          >
            Bulk create users
          </LoadingButton>
        )
      ) : null}
      <AddUserStatusModal
        open={statusModalOpen}
        handleClose={handleCloseStatusModal}
        completedCount={completedCount}
        totalAccountsToCreate={usersToAddPayload.length}
        createdAccountsStatus={createdAccountsStatus}
      />
    </Wrapper>
  );
}
